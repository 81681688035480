import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import $ from "jquery";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import Constants from "../../constants/constants";

const AnalysisSatisList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const today = Fn.getCurrentDate();
    const [year, month] = today.split("-");

    $('#s_date1').val(`${year}-${month}-01`);
    $('#s_date2').val(today);
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/analysis/satis/list/ready?s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_gubun=${$('#s_gubun option:selected').val() || ''}&s_satis_value=${$('#s_satis_value option:selected').val() || ''}&s_gubun_detail=${$('#s_gubun_detail option:selected').val() || ''}&s_low=${$('#s_low option:selected').val() || ''}&my_sort=${$('#my_sort option:selected').val() || ''}&s_key=${$('#s_key').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">전체지점</option>';
            result?.ll?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function nl2br(str) {
    return str.split("\n").map((item, key) => {
      return (
        <React.Fragment key={key}>
          {item}
          <br/>
        </React.Fragment>
      );
    });
  }

  function change_gubun(seq, before_value) {
    if ($("#auth_code").val() == "") {
      alert("인증코드를 입력하세요");
      $("#gubun_" + seq).val(before_value);
      return;
    }

    $("#gubun_" + seq).css("background-color", "#ffd5ff");
    const new_value = $("#gubun_" + seq + " option:selected").val();

    $.ajax({
      type: "post",
      url: "/analysis/change/satis/value",
      data: "seq=" + seq + "&new_value=" + new_value,
      success: function (data) {
        setRenew(Fn.getRenewTime());
      }
    });
  }

  function Excel_download() {
    var auth_code = $("#auth_code").val();
    if (!auth_code) {
      alert("인증코드를 입력해주세요");
      return;
    }

    var down_reason = $("#down_reason").val();
    if (!down_reason) {
      alert("사유를 입력해주세요");
      return;
    }

    const url = `/xls/analysis/satis/list?s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_gubun=${$('#s_gubun option:selected').val() || ''}&s_satis_value=${$('#s_satis_value option:selected').val() || ''}&s_gubun_detail=${$('#s_gubun_detail option:selected').val() || ''}&s_low=${$('#s_low option:selected').val() || ''}&my_sort=${$('#my_sort option:selected').val() || ''}&s_key=${$('#s_key').val()}&auth_code=${auth_code}&down_reason=${down_reason}`;
    Fn.download(Constants.API_URL + url);
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="cc" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="cc" onClick={() => history.push("/analysis/satis/summary")}>지점별 평균별점</label>

          <input id="dd" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="dd" onClick={() => history.push("/analysis/satis/list")}>회원별 평균별점</label>
        </div>
      </div>

      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2 fn_search_box">
          <div className="pr-1">
            <select className="form-select" id="s_branch_code">
            </select>
          </div>
          <div className="input-group pr-1" style={{width: 'auto'}}>
            <JDatePicker className="form-control" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" id="s_date2"/>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_gubun">
              <option value="">분류</option>
              <option value="서비스">서비스</option>
              <option value="시설">시설</option>
              <option value="청결">청결</option>
              <option value="이용">이용</option>
              <option value="사람많음">사람많음</option>
              <option value="기타">기타</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_low">
              <option value="">전체</option>
              <option value="A">평균보다 별점높음</option>
              <option value="B">평균보다 별점낮음</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="my_sort">
              <option value="A">최신등록순</option>
              <option value="B">별점높은순</option>
              <option value="C">별점낮은순</option>
              <option value="D">평균별점높은순</option>
              <option value="E">평균별점낮은순</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_satis_value">
              <option value="">별점</option>
              <option value="1">1개</option>
              <option value="2">2개</option>
              <option value="3">3개</option>
              <option value="4">4개</option>
              <option value="5">5개</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_gubun_detail">
              <option value="">내용전체</option>
              <option value="Y">내용있음</option>
              <option value="N">내용없음</option>
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" id="s_key"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 table_list table_wide_sh2">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="80px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="70px"/>
              <col width="70px"/>
              <col width="120px"/>
              <col width="25%"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>일자</th>
              <th>지점명</th>
              <th>카드번호</th>
              <th>성명</th>
              <th>연락처</th>
              <th>별점</th>
              <th>평균별점</th>
              <th>구분</th>
              <th>내용</th>
            </tr>

            {
              rets?.map((row, index) => {
                const count = index + 1;

                const seq = row?.seq;
                const branch_name = row?.branch_name;
                const up_date = row?.up_date;

                let mb_name, mb_id;
                if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) <= 7 || localStorage.getItem("USER.systemManageYN") === "Y") {
                  mb_name = "***";
                  mb_id = "***";
                } else {
                  mb_name = row?.mb_name;
                  mb_id = row?.mb_id;
                }

                const mb_hp = row?.mb_hp;
                const satis_value = row?.satis_value;
                const satis_avg = row?.satis_avg;
                const gubun = row?.gubun;
                const gubun_detail = row?.gubun_detail;
                const satis_date = row?.satis_date;
                const reg_date = row?.reg_date;

                // const mb_hp_show = hp_show_temp(mb_hp, total_record);
                const mb_hp_show = Fn.hpShow(mb_hp);
                const base_hp = btoa(mb_hp_show);
                // tot_base_hp += `${count},${base_hp}|`;

                return (
                  <tr key={index}>
                    <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - index}</td>
                    <td>{satis_date}</td>
                    <td>{branch_name}</td>
                    <td>{mb_id}</td>
                    <td>{mb_name}</td>
                    <td>
                      <div id={`default_show${count}`}>***-****-****</div>
                    </td>
                    <td>{satis_value}</td>
                    <td>{satis_avg}</td>
                    {/*<td>{gubun}</td>*/}
                    <td>
                      <select name="gubun" id={`gubun_${row?.seq}`} className="form-select" style={{width: "80px", background: row?.up_date ? "#ffd5ff" : undefined}} onChange={() => change_gubun(row?.seq, row?.gubun)}>
                        <option value="">미선택</option>
                        <option value=" 서비스" selected={row?.gubun === '서비스'}>서비스</option>
                        <option value=" 시설" selected={row?.gubun === '시설'}>시설</option>
                        <option value=" 청결" selected={row?.gubun === '청결'}>청결</option>
                        <option value=" 이용" selected={row?.gubun === '이용'}>이용</option>
                        <option value=" 사람많음" selected={row?.gubun === '사람많음'}>사람많음</option>
                        <option value=" 기타" selected={row?.gubun === '기타'}>기타</option>
                      </select>
                    </td>
                    <td>
                      <div align=" left">{nl2br(gubun_detail)}</div>
                    </td>
                  </tr>
                );
              })
            }
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={10}>※ 검색된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className=" p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div className=" d-flex">
            <div className=" d-flex mx-1">
              <ChkAuthNum type={"locker_info_change"}/>
            </div>
            <div className=" d-flex">
              <input
                type=" text"
                className=" form-control"
                aria-describedby=" button-addon2"
                placeholder=" 다운로드 사유를 입력해 주세요."
                style={{width: "280px"}}
                id="down_reason"
              />
            </div>
            {(Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 8 && localStorage.getItem("USER.systemManageYN") !== "Y") &&
              <button type="button" className="btn btn-sm btn-outline-dark px-2 mx-1" onClick={() => Excel_download()}>엑셀저장</button>}
            {pageInfo?.totalArticles > 0 && (
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y")) && (
                <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('satis_list')}>연락처 확인</button>
              )
            )}
          </div>
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>
    </CmnCard>
  );
};

export default AnalysisSatisList;
