import React, {useContext, useEffect, useReducer, useState} from "react";
import "../sass/main.scss";
import {Link, useHistory} from "react-router-dom";
import kd_logo from "../images/kd_logo.svg";
import select_down from "../images/select_down.svg";
import m_LNB from "../images/m_LNB.svg";
import icon_help from "../images/icon_help.svg";
import icon_book from "../images/icon_book.svg";
import Fn from "../libraries/Fn";
import $ from 'jquery';
import axios2 from "../helpers/APIHelper";
import Modal from "react-modal";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import ChkAuthNum from "./common/ChkAuthNum";
import Paging from "./common/Paging";
import Constants from "../constants/constants";
import NewWindow from 'react-new-window';
import {EntranceWin} from "./EntranceWin";
import AuthContext from "./auth-context";

const CmnCard = (props) => {

  const ctx = useContext(AuthContext);
  const path = window.location.pathname;
  const history = useHistory();

  const [brands, setBrands] = useState([]);
  const [systemBranchName, setSystemBranchName] = useState((localStorage.getItem("USER.systemBranchCode") == "ALL" || (localStorage.getItem("USER.systemBranchCode") || '') === '') ? "전체" : localStorage.getItem("USER.systemBranchName"));
  const [menuAuthData, setMenuAuthData] = useState();
  const [banner, setBanner] = useState();
  const [branches, setBranches] = useState([]);
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);
  const [modal4Open, setModal4Open] = useState(false);
  const [modal5Open, setModal5Open] = useState(false);
  const [icRow, setIcRow] = useState({});
  const [qnaContent, setQnaContent] = useState("");

  const [modal4Result, setModal4Result] = useState({});
  const [modal4PageInfo, setModal4PageInfo] = useState({});
  const [modal4CurrPage, setModal4CurrPage] = useState(1);
  const [modal4Renew, setModal4Renew] = useState(0);
  const [modal4SrchRenew, setModal4SrchRenew] = useState(0);

  const [prevMainContentsWidth, setPrevMainContentsWidth] = useState(0);
  const [prevMainWidth, setPrevMainWidth] = useState(0);
  const [prevLeftWidth, setPrevLeftWidth] = useState(0);

  useEffect(() => {
    axios2
      .post(`/common/update/check?build=${Constants.BUILD}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if (message === 'update') {
            window.location.reload();
          }
        }
      })
      .catch((error) => {
      });
  }, [window.location.pathname]);

  useEffect(() => {
    $("input[type=text]").each(function () {
      $(this).prop("autocomplete", "one-time-code");
    });
    $("input[type=password]").each(function () {
      $(this).prop("autocomplete", "one-time-code");
    });
    if (window.location.pathname.includes('/member/view/detail')) {
      $('#s_mb_name').prop("autocomplete", "on");
      $('#s_mb_hp').prop("autocomplete", "on");
      $('#edit_memo').prop("autocomplete", "on");
    }
  }, []);

  useEffect(() => {
    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) <= 0) {
      history.push("/login");
      return;
    }

    $.fn.onEnter = function (closure) {
      $(this).keypress(
        function (event) {
          var code = event.keyCode ? event.keyCode : event.which;

          if (code == 13) {
            closure();
            return false;
          }
        });
    }

    $.ajaxSetup({
      beforeSend: function (xhr, options) {
        if (options.url.startsWith(":3000"))
          options.url = options.url.replace(":3000", ":8994");
        else if (options.url.indexOf("http") === -1)
          options.url = Constants.API_URL + options.url;
      },
      headers: {
        'Authorization': ('Bearer ' + localStorage.getItem("USER.token"))
      },
      cache: false,
      error: function (jqXHR, textStatus, errorThrown) {
        Fn.parseAjaxError(jqXHR, textStatus, errorThrown, history);
      }
    });
  }, []);

  useEffect(() => {
    //$("button:contains('엑셀저장')").addClass("cls-hide");
  }, []);

  useEffect(() => {
    loadBrandsAndBraches();
  }, []);

  function Top_select_brand() {
    const newBrand = $('#S_top_brand option:selected').val();

    //지점리스트 초기화
    $("#top_S_branch_list").empty();

    //해당지점 리스트만 표시
    $.ajax({
      type: "post",
      url: "/inc/branch/selected",
      data: "brand=" + newBrand,
      success: function (data) {
        data = data?.result?.data;
        $('#top_S_branch_list').html(data);
      }
    });
  }

  useEffect(() => {
    if (!modal4Open) return;
    $('[id^=cchk]').prop("checked", false);
    loadModal4();
  }, [modal4CurrPage, modal4Renew]);

  useEffect(() => {
    if (!(modal4Open && modal4SrchRenew > 0)) return;
    $('[id^=cchk]').prop("checked", false);
    loadModal4(1);
  }, [modal4SrchRenew]);

  useEffect(() => {
    // 연락처 마스킹(하이픈) 처리
    $(document).on("keyup", ".cls-type-phone", function () {
      $(this).val($(this).val().replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-"));
    });
    $(document).on("blur", ".cls-type-phone", function () {
      $(this).val($(this).val().replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-"));
    });

    // 숫자만 입력 처리
    $(document).on("keyup", ".cls-type-number", function () {
      $(this).val($(this).val().replace(/[^-?0-9]/g, ""));
    });
    $(document).on("blur", ".cls-type-number", function () {
      $(this).val($(this).val().replace(/[^-?0-9]/g, ""));
    });

    // 숫자 + ","만 입력 처리
    $(document).on("keyup", ".cls-type-fmtnum", function () {
      $(this).val($(this).val().replace(/[^-?0-9,]/g, ""));
    });
    $(document).on("blur", ".cls-type-fmtnum", function () {
      $(this).val($(this).val().replace(/[^-?0-9,]/g, ""));
    });

    // 숫자 + ","만 입력 처리
    $(document).on("keyup", ".cls-type-fmtnum-ex", function () {
      $(this).val($(this).val().replace(/[^-?0-9,]/g, ""));
      Fn.vComma(this);
    });
    $(document).on("blur", ".cls-type-fmtnum-ex", function () {
      $(this).val($(this).val().replace(/[^-?0-9,]/g, ""));
      Fn.vComma(this);
    });

    // 영문과 숫자만 입력 처리
    $(document).on("keyup", ".cls-type-nokr", function () {
      $(this).val($(this).val().replace(/[^a-zA-Z0-9]/g, ""));
    });
    $(document).on("blur", ".cls-type-nokr", function () {
      $(this).val($(this).val().replace(/[^a-zA-Z0-9]/g, ""));
    });

    // 영문과 숫자와 "."만 입력 처리
    $(document).on("keyup", ".cls-type-nokr2", function () {
      $(this).val($(this).val().replace(/[^a-zA-Z0-9\\.]/g, ""));
    });
    $(document).on("blur", ".cls-type-nokr2", function () {
      $(this).val($(this).val().replace(/[^a-zA-Z0-9\\.]/g, ""));
    });

    // 이메일만 입력 처리
    $(document).on("keyup", ".cls-type-email", function () {
      $(this).val($(this).val().replace(/[^a-zA-Z0-9@\\.]/g, ""));
    });
    $(document).on("blur", ".cls-type-email", function () {
      $(this).val($(this).val().replace(/[^a-zA-Z0-9@\\.]/g, ""));
    });

    // 한글과 영문만 입력 처리
    $(document).on("keyup", ".cls-type-char", function () {
      $(this).val($(this).val().replace(/[^가-힣a-zA-Z]/g, ""));
    });
    $(document).on("blur", ".cls-type-char", function () {
      $(this).val($(this).val().replace(/[^가-힣a-zA-Z]/g, ""));
    });
  }, []);

  const loadBrandsAndBraches = () => {
    let menuName = "";

    if (path.startsWith("/member/view")) menuName = "A1";
    else if (path.startsWith("/member/list")) menuName = "A2";
    else if (path.startsWith("/member/history")) menuName = "A3";
    else if (path.startsWith("/member/service")) menuName = "A4";
    else if (path.startsWith("/member/refund/list")) menuName = "A5";
    else if (path.startsWith("/member/input/list" || path.startsWith("/member/input/stat"))) menuName = "A6";
    else if (path.startsWith("/member/input/stat")) menuName = "A6";
    else if (path.startsWith("/member/online/list")) menuName = "A13";
    else if (path.endsWith("/member/delay/list")) menuName = "A14";
    else if (path.startsWith("/member/delay/list/all")) menuName = "A16";
    else if (path.startsWith("/member/toreceive")) menuName = "A7";
    else if (path.startsWith("/member/branch/change")) menuName = "A8";
    else if (path.startsWith("/member/center/change")) menuName = "A12";
    else if (path.startsWith("/member/start/date/change")) menuName = "A9";
    else if (path.startsWith("/member/gift/regi")) menuName = "A10";
    else if (path.startsWith("/member/blacklist")) menuName = "A11";
    else if (path.startsWith("/member/complain/")) menuName = "C22";
    else if (path.startsWith("/member/take/over/member")) menuName = "A15";
    else if (path.startsWith("/member/ocare/coupon")) menuName = "A17";
    else if (path.endsWith("/member/ocare/use/list")) menuName = "A18";
    else if (path.startsWith("/member/ocare/use/list/branch")) menuName = "A21";
    else if (path.startsWith("/member/ocare/reg")) menuName = "A19";
    else if (path.startsWith("/member/ocare/member")) menuName = "A20";
    else if (path.endsWith("/member/cu/use/list")) menuName = "A101";
    else if (path.startsWith("/member/cu/reg")) menuName = "A102";

    else if (path.startsWith("/sales/reg")) menuName = "J8";
    else if (path.startsWith("/sales/list")) menuName = "J9";
    else if ((path.startsWith("/sales/order/list") || path.startsWith("/sales/order/reg"))) menuName = "J20";
    else if (path.startsWith("/sales/daily/state")) menuName = "J12";
    else if (path.startsWith("/sales/weekly/state")) menuName = "J13";
    else if (path.startsWith("/sales/monthly/state")) menuName = "J14";
    else if (path.startsWith("/sales/branch/state")) menuName = "J18";
    else if (path.endsWith("/sales/prod/state")) menuName = "J15";
    else if (path.startsWith("/sales/branch/price/state")) menuName = "J16";
    else if (path.endsWith("/sales/stock/state")) menuName = "J10";
    else if (path.startsWith("/sales/stock/state/detail")) menuName = "J11";
    else if (path.startsWith("/sales/product/part")) menuName = "J1";
    else if (path.startsWith("/sales/product/position")) menuName = "J7";
    else if ((path.startsWith("/sales/product/list") || path.startsWith("/sales/product/reg"))) menuName = "J2";
    else if (path.startsWith("/sales/product/discount")) menuName = "J3";
    else if (path.startsWith("/sales/inout")) menuName = "J4";
    else if ((path.startsWith("/sales/control") && !path.startsWith("/sales/control/part"))) menuName = "J5";
    else if (path.startsWith("/sales/control/part")) menuName = "J6";
    else if (path.startsWith("/sales/supply/")) menuName = "J19";

    else if (path.startsWith("/locker/state")) menuName = "B1";
    else if (path.startsWith("/locker/list")) menuName = "B2";
    else if (path.startsWith("/locker/empty/reserve")) menuName = "B3";
    else if (path.startsWith("/locker/reserve")) menuName = "B4";
    else if (path.startsWith("/locker/history")) menuName = "B5";
    else if (path.startsWith("/locker/number")) menuName = "B6";
    else if (path.startsWith("/locker/config")) menuName = "B7";

    else if (path.startsWith("/branchpt/schedule")) menuName = "C7";
    else if (path.startsWith("/branchpt/trainer")) menuName = "C8";
    else if (path.startsWith("/branchpt/open")) menuName = "C9";
    else if (path.startsWith("/branchpt/pt/reserve")) menuName = "C25";
    else if (path.startsWith("/branchpt/pt/list")) menuName = "C10";
    else if (path.startsWith("/branchpt/pt/take/list")) menuName = "C11";
    else if (path.startsWith("/branchpt/ot/list")) menuName = "C13";
    else if (path.startsWith("/branchpt/free/ot/list")) menuName = "F11";
    else if (path.startsWith("/branchpt/ot/take/list")) menuName = "C14";
    else if (path.startsWith("/branchpt/pt/take/month")) menuName = "C26";
    else if (path.startsWith("/branchpt/ot/take/month")) menuName = "C27";
    else if (path.startsWith("/branchpt/pt/route")) menuName = "C12";

    else if (path.startsWith("/branchop/notice/")) menuName = "C16";
    else if (path.startsWith("/branchop/sales/target")) menuName = "C4";
    else if (path.startsWith("/branchop/daily/work/list")) menuName = "E7";
    else if (path.startsWith("/branchop/permit/list")) menuName = "C5";
    else if (path.startsWith("/branchop/gx/basic")) menuName = "C3";
    else if (path.startsWith("/branchop/gx/reserve")) menuName = "C1";
    else if (path.startsWith("/branchop/gx/member")) menuName = "C2";
    else if (path.endsWith("/branchop/inout/cal")) menuName = "C6";
    else if (path.startsWith("/branchop/inout/cal/new")) menuName = "C6";
    else if (path.startsWith("/branchop/board/")) menuName = ""; // part == "G" 일 경우만 "C15"
    else if ((path.startsWith("/branchop/strange/list") || path.startsWith("/branchop/strange/write"))) menuName = "C29";
    else if (path.startsWith("/branchop/app/install/list")) menuName = "D18";
    else if (path.startsWith("/branchop/step/app/install/list")) menuName = "D21";
    else if (path.startsWith("/branchop/step/attend")) menuName = "D20";
    else if (path.startsWith("/branchop/not/oper/list")) menuName = "D23";

    else if (path.endsWith("/finance/account")) menuName = "D2";
    else if (path.startsWith("/finance/report")) menuName = "D3";
    else if (path.startsWith("/finance/monthly")) menuName = "D4";
    else if (path.startsWith("/finance/prepaid")) menuName = "D10";
    else if (path.startsWith("/finance/bankbook")) menuName = "D13";
    else if (path.endsWith("/finance/cash")) menuName = "D6";
    else if (path.startsWith("/finance/card")) menuName = "D5";
    else if (path.startsWith("/finance/cash/new")) menuName = "D6";
    else if (path.startsWith("/finance/misu")) menuName = "D11";
    else if (path.startsWith("/finance/account/manage")) menuName = "D12";
    else if (path.startsWith("/finance/spending")) menuName = "D9";
    else if (path.startsWith("/finance/fee/list")) menuName = "D1";
    else if (path.startsWith("/finance/fee/public")) menuName = "D1";
    else if (path.startsWith("/finance/fee/pt")) menuName = "D1";
    else if (path.startsWith("/finance/fee/add")) menuName = "D1";
    else if ((path === "/finance/fee/incen" || path.startsWith("/finance/fee/incen?"))) menuName = "D1";
    else if ((path.startsWith("/finance/fee/incen/pt") || path.startsWith("/finance/fee/incen/pt?"))) menuName = "D1";
    else if (path.startsWith("/finance/fee/gx")) menuName = "D1";
    else if (path.startsWith("/finance/fee/consult")) menuName = "D1";
    else if (path.startsWith("/finance/fee/step")) menuName = "D1";
    else if (path.startsWith("/finance/fee/overtime/list")) menuName = "D1";
    else if (path.startsWith("/finance/fee/etc")) menuName = "D1";
    else if (path.startsWith("/finance/fee/branch/overprice")) menuName = "D1";
    else if (path.startsWith("/finance/fran/info")) menuName = "D22";
    else if (path.endsWith("/finance/app/web/pay")) menuName = "D19";
    else if (path.startsWith("/finance/app/web/pay/nice")) menuName = "D19";

    else if (path.startsWith("/analysis/state/new/old")) menuName = "E10";
    else if (path.startsWith("/analysis/state/period")) menuName = "E10";
    else if (path.startsWith("/analysis/state/visit")) menuName = "E10";
    else if (path.startsWith("/analysis/state/week")) menuName = "E10";
    else if (path.startsWith("/analysis/state/month")) menuName = "E10";
    else if (path.startsWith("/analysis/weekly/report")) menuName = "E8";
    else if (path.startsWith("/analysis/monthly/member")) menuName = "E9";
    else if (path.startsWith("/analysis/monthly/finance/new")) menuName = "E9";
    else if (path.startsWith("/analysis/monthly/coupon")) menuName = "E9";
    else if (path.startsWith("/analysis/monthly/tm")) menuName = "E9";
    else if (path.startsWith("/analysis/monthly/region")) menuName = "E9";
    else if (path.startsWith("/analysis/monthly/public")) menuName = "E9";
    else if (path.startsWith("/analysis/monthly/pt")) menuName = "E9";
    else if (path.startsWith("/analysis/monthly/analysis/pt")) menuName = "E9";
    else if (path.startsWith("/analysis/long/period")) menuName = "E3";
    else if (path.endsWith("/analysis/branch/ranking")) menuName = "E4";
    else if (path.startsWith("/analysis/branch/ranking/real")) menuName = "E5";
    else if (path.startsWith("/analysis/branch/ranking/pt")) menuName = "E6";
    else if (path.startsWith("/analysis/use/state")) menuName = "E2";
    else if (path.startsWith("/analysis/kpi")) menuName = "E1";
    else if (path.startsWith("/analysis/manager/report")) menuName = "D7";
    else if (path.startsWith("/analysis/trainer/report")) menuName = "D8";
    else if (path.startsWith("/analysis/branch/real/price")) menuName = "D16";
    else if (path.startsWith("/analysis/finance/monthly/report")) menuName = "F10";
    else if (path.endsWith("/analysis/branch/price")) menuName = "E11";
    else if (path.startsWith("/analysis/branch/price/pt")) menuName = "E12";
    else if (path.startsWith("/analysis/satis/")) menuName = "E13";
    else if (path.startsWith("/analysis/point/history")) menuName = ""; // 소스에 없음

    else if (path.startsWith("/config/ip")) menuName = "F1";
    else if (path.startsWith("/config/set/sound")) menuName = "F2";
    else if (path.startsWith("/config/auth/")) menuName = "F3";
    else if (path.startsWith("/config/join/route")) menuName = "F4";
    else if (path.startsWith("/config/option")) menuName = "F5";
    else if (path.startsWith("/config/weekly/set")) menuName = "F6";
    else if (path.startsWith("/config/user/list") || path.startsWith("/config/user/reg")) menuName = "F7";
    else if (path.startsWith("/config/research/")) menuName = "F8";
    else if (path.startsWith("/config/sub/price/setting")) menuName = "D15";
    else if (path.startsWith("/config/branch/")) menuName = "C17";
    else if (path.startsWith("/config/item/")) menuName = "C18";
    else if (path.startsWith("/config/brand/list")) menuName = "C19";
    else if (path.endsWith("/config/gift/list")) menuName = "C20";
    else if (path.startsWith("/config/gift/list/b2b")) menuName = "C20";
    else if (path.startsWith("/config/coupon/list")) menuName = "C21";
    else if (path.startsWith("/config/applicant")) menuName = "F12";
    else if (path.startsWith("/config/smile/setting")) menuName = "C23";
    else if (path.startsWith("/config/banner")) menuName = ""; // 소스에 없음

    else if (path.startsWith("/message/sms/send/list")) menuName = "G1";
    else if (path.startsWith("/message/sms/state")) menuName = "G5";
    else if (path.startsWith("/message/sms/reserve/list")) menuName = "G7";
    else if (path.startsWith("/message/tm/reg")) menuName = "G2";
    else if (path.startsWith("/message/sms/list")) menuName = "G3";
    else if (path.startsWith("/message/push/list")) menuName = "G4";
    else if (path.startsWith("/message/push/send/list")) menuName = "G6";

    else if ((path.startsWith("/employee/list") || path.startsWith("/employee/reg"))) menuName = "I1";
    else if (path.startsWith("/employee/branch")) menuName = "I2";
    else if (path.startsWith("/employee/new/position")) menuName = "I3";
    else if (path.startsWith("/employee/config")) menuName = "I4";
    else if (path.startsWith("/employee/position/fee")) menuName = "I5";
    else if (path.startsWith("/employee/groupware")) menuName = "I6";
    else if (path.startsWith("/employee/blacklist")) menuName = "I7";
    else if (path.startsWith("/employee/ace/work")) menuName = "I8";
    else if (path.startsWith("/employee/ace/report")) menuName = "I8";
    else if ((path.startsWith("/employee/book/list") || path.startsWith("/employee/book/reg"))) menuName = "I9";
    else if (path.startsWith("/employee/book/end/list")) menuName = "I9";
    else if (path.startsWith("/employee/book/meet/list")) menuName = "I9";
    else if (path.startsWith("/employee/pt/trainer/")) menuName = "I10";
    else if ((path.startsWith("/employee/inv/list") || path.startsWith("/employee/inv/reg"))) menuName = "H1";
    else if (path.startsWith("/employee/inv/group/new")) menuName = "H2";
    else if (path.startsWith("/employee/inv/mail/send/list/new")) menuName = "H3";

    else if (path.startsWith("/secure/menu/auth/")) menuName = ""; // 세션으로 바로 체크
    else if (path.startsWith("/secure/list/")) menuName = ""; // 세션으로 바로 체크
    else if (path.startsWith("/secure/excel/down/history")) menuName = ""; // 세션으로 바로 체크
    else if (path.startsWith("/secure/member/show/history")) menuName = ""; // 세션으로 바로 체크

    axios2
      .post(`/inc/header?menuName=${menuName || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if (result?.hasAuth !== 'Y' || !auth(menuName, result?.menuAuthData)) {
            alert('메뉴 접근 권한이 없습니다.');
            history.goBack();
            return;
          }

          setMenuAuthData(result?.menuAuthData);
          setBrands(result?.brands);
          setBranches(result?.branches);
          setBanner(result?.banner);

          setTimeout(() => {
            if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 8 || localStorage.getItem("USER.systemManageYN") === "Y" || localStorage.getItem("USER.systemAccountYN") === "Y")
              Top_select_brand();
          }, 350);
        }
      })
      .catch((error) => {
        console.log(error)
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function auth(menuName, _menuAuthData = menuAuthData) {
    if (menuName) {
      if (['C29', 'C99'].indexOf(menuName) != -1) {
        if (localStorage.getItem("USER.systemFranYN") === "Y") return true;
      }

      if (['E3', 'E4', 'E5', 'E6', 'D16'].indexOf(menuName) != -1) {
        if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7 && localStorage.getItem("USER.systemFranYN") === "Y") return false;
      }

      if (['F100'].indexOf(menuName) != -1) {
        if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 9 || localStorage.getItem("USER.systemAccountYN") === "Y") return true;
        else return false;
      }

      if (['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A13', 'A14', 'A7', 'A8', 'A12', 'A9', 'A10', 'A11', 'A15', 'A21', 'A19', 'A20', 'A102'].indexOf(menuName) != -1 && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7) return true;
      if (['B1', 'B2', 'B3', 'B4', 'B5', 'B6'].indexOf(menuName) != -1 && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7) return true;
      if (['B1', 'B2', 'B3', 'B4', 'B5', 'B6'].indexOf(menuName) != -1 && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7) return true;
      if (['C7', 'C8', 'C9', 'C25', 'C10', 'C11', 'C13', 'F11', 'C14', 'C26', 'C27'].indexOf(menuName) != -1 && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7) return true;
      if (['J8', 'J9', 'J20', 'J21', 'J12', 'J13', 'J14', 'J18', 'J15', 'J16', 'J10', 'J11', 'J4'].indexOf(menuName) != -1 && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7) return true;
      // if (['C16', 'C4', 'E7', 'C5', 'C3', 'C1', 'C2', 'C6', 'C15', 'C29', 'D18', 'D21', 'D20'].indexOf(menuName) != -1 && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7) return true;
      if (['C16', 'C4', 'E7', 'C5', 'C3', 'C1', 'C2', 'C6', 'C15', 'D18', 'D21', 'D20'].indexOf(menuName) != -1 && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7) return true;
      if (['D3', 'D4', 'D10', 'D13', 'D14', 'D5', 'D6', 'D11', 'D9', 'D1', 'D22', 'D19'].indexOf(menuName) != -1 && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7) return true;
      if (['E10', 'E8', 'E9', 'E3', 'E4', 'E5', 'E6', 'E2', 'E1', 'D7', 'D8', 'D16', 'F10', 'E11', 'E12', 'E13', 'E14'].indexOf(menuName) != -1 && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7) return true;
      if (['G1', 'G5', 'G7', 'G2', 'G3'].indexOf(menuName) != -1 && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7) return true;
      if (['I1', 'I8', 'I10'].indexOf(menuName) != -1 && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7) return true;
      if (['F8', 'C17', 'C24'].indexOf(menuName) != -1 && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7) return true;

      return (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 9 || _menuAuthData?.[menuName] === 'Y');
    } else {
      return true;
    }
  }

  useEffect(() => {
    window.currentToken = localStorage.getItem("USER.token")
    window.sessionCheckTimeout = setInterval(() => {
      if (localStorage.getItem("USER.token") == null || localStorage.getItem("USER.token") == undefined || localStorage.getItem("USER.token") == '') {
        clearInterval(window.sessionCheckTimeout);
        history.replace("/login");
        return;
      }
      if (window.currentToken != '' && window.currentToken !== localStorage.getItem("USER.token")) {
        window.currentToken = localStorage.getItem("USER.token");
        clearInterval(window.sessionCheckTimeout);
        window.location.reload();
      }
    }, 3500);
    return () => {
      clearInterval(window.sessionCheckTimeout);
    }
  }, []);

  function changeBranch() {
    const newBranchCode = $("#top_S_branch_list option:selected").val();

    axios2.post(`/inc/session/change?branch_code=${newBranchCode}`)
      .then(function (response) {
        const {code, message, result, pageInfo} = response?.data;
        localStorage.setItem("USER.systemBranchCode", result?.systemBranchCode);
        localStorage.setItem("USER.systemBranchName", result?.systemBranchName);

        if ((localStorage.getItem("USER.systemBranchCode") == "ALL" || (localStorage.getItem("USER.systemBranchCode") || '') === ''))
          setSystemBranchName('전체');
        else setSystemBranchName(result?.systemBranchName);

        if (result?.token == localStorage.getItem("USER.token")) {
          window.location.reload();
          return;
        }
        window.currentToken = result?.token;
        localStorage.setItem("USER.token", result?.token);

        // 2023.11.05
        if (false) {
          window.parent.location.reload();
          return;
        }

        // if (window.location.pathname.includes('/locker/state') || window.location.pathname.includes('/locker/number') || window.location.pathname.includes('/finance/account')) {
        if (window.location.pathname.includes('/locker/state') || window.location.pathname.includes('/locker/number') || window.location.pathname.includes('/branchpt/pt/list')) {
          window.parent.location.reload();
        } else if (window.location.pathname.includes('/finance/account')) {
          try {
            window._load_finance(1);
          } catch (err) {
          }
        } else if (window.location.pathname.includes('/finance/account')) {
          try {
            window._load_finance(1);
          } catch (err) {
          }
        } else if (window.location.pathname.includes('/branchop/inout/cal')) {
          try {
            window._load_cal();
          } catch (err) {
          }
        } else if (window.location.pathname.includes('/finance/monthly/report')) {
          localStorage.setItem("FMR.s_year", $('#s_year option:selected').val());
          localStorage.setItem("FMR.s_month", $('#s_month option:selected').val());
          localStorage.setItem("FMR.e_year", $('#e_year option:selected').val());
          localStorage.setItem("FMR.e_month", $('#e_month option:selected').val());
          try {
            window._load(1);
          } catch (err) {
          }
          setTimeout(() => {
            localStorage.removeItem("FMR.s_year");
            localStorage.removeItem("FMR.s_month");
            localStorage.removeItem("FMR.e_year");
            localStorage.removeItem("FMR.e_month");
          }, 13 * 1000)
        } else if (window.location.pathname.includes('/member/view/detail')) {
          try {
            window._load_mv();
          } catch (err) {
          }
        } else if (window.location.pathname.includes('/branchpt/pt/list')) {
          try {
            window._load_pt_list(1);
          } catch (err) {
          }
        } else {
          try {
            window._load(1);
          } catch (err) {
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const onLogout = () => {
    try {
      ctx.closeEntWinHandler();
    } catch (err) {
    }

    localStorage.removeItem("USER.systemID");
    localStorage.removeItem("USER.systemName");
    localStorage.removeItem("USER.systemBranchName");
    localStorage.removeItem("USER.systemManageYN");
    localStorage.removeItem("USER.systemUserAuth");
    localStorage.removeItem("USER.systemAccountYN");
    localStorage.removeItem("USER.systemBranchCode");
    localStorage.removeItem("USER.systemBrand");
    localStorage.removeItem("USER.token");
    localStorage.removeItem("login_ck_date")

    // props.onLogout();
    history.push("/login");
  }

  useEffect(() => {
    $(window).bind('resize', function () {
      // Fn.resizeScreen(1.26);
      Fn.resizeScreen(1.5);
    })
    return () => {
      $(window).unbind('resize');
    };
  }, []);

  const LOGOUT_MIN = '90';
  const LOGOUT_SEC = '00';

  useEffect(() => {
    Fn.resizeScreen(1.5);

    const token = localStorage.getItem("USER.token") || '';
    if (!token) {
      onLogout();
      window.location.href = '/login';
      alert('접근 권한이 없습니다.');
      return;
    }

    if (parseInt(localStorage.getItem("logout_min"), 10) <= 0 && parseInt(localStorage.getItem("logout_sec"), 10) <= 0) {
      alert('이용해 주셔서 감사합니다.');
      onLogout();
      return;
    }

    if (Fn.getRenewTime() - parseInt(localStorage.getItem("login_ck_date"), 10) > 120 * 1000) {
      onLogout();
      window.location.href = '/login';
      return;
    }

    localStorage.setItem("logout_min", LOGOUT_MIN);
    localStorage.setItem("logout_sec", LOGOUT_SEC);
    localStorage.setItem("login_ck_date", Fn.getRenewTime().toString());

    const logoutInterval = window.setIntervalEx(() => {
      $('#logout_min').text(localStorage.getItem("logout_min"));
      $('#logout_sec').text(localStorage.getItem("logout_sec"));
      localStorage.setItem("login_ck_date", Fn.getRenewTime().toString());

      let min = $('#logout_min').text();
      let sec = $('#logout_sec').text();

      if (parseInt(min, 10) <= 0 && parseInt(sec, 10) <= 0) {
        window.clearIntervalEx(logoutInterval);
        alert('이용해 주셔서 감사합니다.');
        onLogout();
        return;
      }

      if (sec === '00') { //초가 00인 경우
        if (min !== '00') { //분이 00이 아닌 경우 1분 줄어들고 59초로 변경
          let iMin = parseInt(min, 10);
          iMin--;
          min = Fn.lpad(iMin.toString(), 2, "0");
          sec = '59';
          localStorage.setItem("logout_min", min);
          localStorage.setItem("logout_sec", sec);
          $('#logout_min').text(min);
          $('#logout_sec').text(sec);
        } else { //분초 모두 00인 경우 로그아웃
          window.clearIntervalEx(logoutInterval);
          alert('이용해 주셔서 감사합니다.');
          onLogout();
        }
      } else { //초가 00이 아닌 경우 1초 줄어듬
        let iSec = parseInt(sec, 10);
        iSec--;
        sec = Fn.lpad(iSec.toString(), 2, "0");
        localStorage.setItem("logout_min", min);
        localStorage.setItem("logout_sec", sec);
        $('#logout_min').text(min);
        $('#logout_sec').text(sec);
      }
    }, 1000);

    return () => {
      window.clearIntervalEx(logoutInterval);
    };
  }, [window.location.pathname]);

  // useEffect(() => {
  //   Fn.resizeScreen(1.5);
  //
  //   localStorage.setItem("logout_min", LOGOUT_MIN);
  //   localStorage.setItem("logout_sec", LOGOUT_SEC);
  //   localStorage.setItem("login_ck_date", Fn.getRenewTime().toString());
  //
  //   const logoutInterval = setInterval(() => {
  //     let min = $('#logout_min').text();
  //     let sec = $('#logout_sec').text();
  //     localStorage.setItem("login_ck_date", Fn.getRenewTime().toString());
  //
  //     if (sec === '00') { //초가 00인 경우
  //       if (min !== '00') { //분이 00이 아닌 경우 1분 줄어들고 59초로 변경
  //         let iMin = parseInt(min, 10);
  //         iMin--;
  //         min = Fn.lpad(iMin.toString(), 2, "0");
  //         sec = '59';
  //         localStorage.setItem("logout_min", min);
  //         localStorage.setItem("logout_sec", sec);
  //         $('#logout_min').text(min);
  //         $('#logout_sec').text(sec);
  //       } else { //분초 모두 00인 경우 로그아웃
  //         clearInterval(logoutInterval);
  //         alert('이용해 주셔서 감사합니다.');
  //         history.push("/login")
  //       }
  //     } else { //초가 00이 아닌 경우 1초 줄어듬
  //       let iSec = parseInt(sec, 10);
  //       iSec--;
  //       sec = Fn.lpad(iSec.toString(), 2, "0");
  //       localStorage.setItem("logout_min", min);
  //       localStorage.setItem("logout_sec", sec);
  //       $('#logout_min').text(min);
  //       $('#logout_sec').text(sec);
  //     }
  //   }, 1000);
  //
  //   return () => {
  //     clearInterval(logoutInterval);
  //   };
  // }, [window.location.pathname]);

  window.extendLogout = () => {
    localStorage.setItem("logout_min", LOGOUT_MIN);
    localStorage.setItem("logout_sec", LOGOUT_SEC);
    localStorage.setItem("login_ck_date", Fn.getRenewTime().toString());

    $('#logout_min').text(LOGOUT_MIN);
    $('#logout_sec').text(LOGOUT_SEC);
  }

  const loadModal1 = () => {
    if (modal1Open) {
      axios2
        .post(`/inc/info/change/ready`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            setIcRow(result?.row);
            $('#user_id').html(result?.row?.user_id);
            $('#user_name').val(result?.row?.user_name);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  const loadModal2 = () => {
    if (modal2Open) {
      axios2
        .post(`/member/change/auth/ready`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            setIcRow(result?.row);
            $('#user_id').html(result?.row?.user_id);
            $('#user_name').val(result?.row?.user_name);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  function input_insert() {
    if (!$('#old_pwd').val()) {
      alert("기존 비밀번호를 입력해주세요");
      $('#old_pwd').focus();
      return;
    }

    const password = $('#user_pwd').val();
    if (!/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/.test(password)) {
      alert("비밀번호를 영문,숫자,특수문자를 포함한 8~15자까지 입력해주세요")
      return;
    }

    if ($('#user_pwd').val() != "" && $('#re_pwd').val() == "") {
      alert("비밀번호 확인을 입력해주세요");
      $('#re_pwd').focus();
      return;
    }

    if (($('#user_pwd').val() != "" && $('#re_pwd').val() != "") && ($('#user_pwd').val() != $('#re_pwd').val())) {
      alert("비밀번호 확인이 일치하지 않습니다.");
      $('#re_pwd').val().focus();
      return;
    }

    axios2
      .postEx('/inc/info/change', {
        user_pwd: $('#user_pwd').val(),
        old_pwd: $('#old_pwd').val(),
        user_name: $('#user_name').val(),
        user_id: $('#user_id').html(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "same_pwd") {
          alert('현재 사용중인 비밀번호로 변경하실수 없습니다');
          setModal1Open(false);
        } else if (data == "same_old_pwd") {
          alert('바로 이전 사용하신 비밀번호로 변경하실수 없습니다');
          setModal1Open(false);
        } else {
          alert("수정되었습니다. 다시 로그인해주세요");
          setModal1Open(false);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function auth_go_submit(insert_type) {
    if ($('#at_auth_code').val() == "") {
      alert('인증코드를 입력해주세요');
      $('#at_auth_code').focus();
      return;
    }

    if ($('#at_new_auth_code').val() == "") {
      alert('변경 인증코드를 입력해주세요');
      $('#at_new_auth_code').focus();
      return;
    }

    if ($('#at_new_auth_code_re').val().length < 4) {
      alert('인증코드는 최소 4자리로 입력해주세요');
      $('#at_new_auth_code').focus();
      return;
    }

    if ($('#at_new_auth_code_re').val() == "") {
      alert('인증코드 확인을 입력해주세요');
      $('#at_new_auth_code_re').focus();
      return;
    }

    if ($('#at_chk_YN').val() != "Y") {
      alert('기존 인증코드 입력이 올바르지 않습니다.');
      return;
    }

    axios2
      .postEx('/member/change/auth/code/new', {
        base_new_seq: $('#at_base_new_seq').val(),
        auth_code: $('#at_auth_code').val(),
        new_auth_code: $('#at_new_auth_code').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "same_authcode") {
          alert('기존 인증코드를 동일하게 사용하실수 없습니다.');
          // setModal1Open(false);
        } else if (data == "dupp_authcode") {
          alert('동일한 인증코드가 존재합니다');
          // setModal1Open(false);
        } else {
          alert("수정되었습니다.");
          setModal2Open(false);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function chk_auth() {
    if ($('#at_new_auth_code').val() !== $('#at_new_auth_code_re').val()) {
      alert('인증코드 확인이 일치하지 않습니다');
      $('#at_new_auth_code_re').val("");
    }
  }

  function chk_auth_code() {
    if ($('#at_auth_code').val() == "") {
      alert('인증코드를 입력해주세요');
      $('#at_auth_code').focus();
      return;
    }

    axios2
      .postEx('/member/change/auth/chk', {
        base_new_seq: $('#at_base_new_seq').val(),
        auth_code: $('#at_auth_code').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (!data) {
          alert('인증코드가 올바르지 않습니다');
          $('#at_chk_YN').val("N");
          setModal1Open(false);
        } else {
          $('#at_manager_name').html(data);
          $('#at_chk_YN').val("Y");
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal4 = (_page) => {
    if (modal4Open) {
      axios2
        .post(`/guide/qna/ready?search_auth_code=&auth_code=&keyname=${$('#modal4_keyname option:selected').val() || ''}&key=${$('#modal4_key').val() || ''}&page=${_page || modal4CurrPage}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            setModal4Result(result);
            setModal4PageInfo(pageInfo);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  const loadModal5 = () => {
    if (modal5Open) {
      axios2
        .post(`/guide/qna/write/ready?base_auth_code=${btoa($('#modal5_qna_auth_code').val())}&base_seq=${$('#modal5_base_seq').val()}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            if (result?.err) {
              alert(result?.err);
              setModal5Open(false);
              setModal4Open(true);
            }
            console.log(result);
            $('#modal5_title').val(result?.row?.title);
            if (result?.row?.content)
              setQnaContent(result?.row?.content);
            else
              setQnaContent("");
            // setModal5Result(result);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  window.go_rep = (seq) => {
    if (!$('#qna_auth_code').val()) {
      alert("인증번호를 입력해주세요");
      $('#qna_search_auth_code').focus();
      return;
    }

    if (!$("#rep_comment" + seq).val()) {
      alert("내용을 입력해주세요");
      return;
    }

    const auth_code = $('#qna_auth_code').val();
    const content = $("#rep_comment" + seq).val()

    axios2
      .postEx('/guide/rep/input', {
        base_auth_code: btoa(auth_code),
        content: content,
        qna_seq: seq,
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "auth_code_err") {
          alert('잘못된 인증번호 입니다');
        } else if (data == "ok") {
          alert("등록되었습니다");
          $("#rep_comment" + seq).val('');
          setModal4Renew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function qna_go_submit() {
    if (!$('#modal5_title').val()) {
      alert('제목을 입력해주세요');
      $('#modal5_title').focus();
      return;
    }

    if (!qnaContent || qnaContent == "<p>&nbsp;</p>") {
      alert('내용을 입력해주세요');
      return;
    }

    axios2
      .postEx('/guide/qna/write', {
        base_seq: $('#modal5_base_seq').val(),
        base_auth_code: btoa($('#modal5_qna_auth_code').val()),
        content: qnaContent,
        title: $('#modal5_title').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "auth_err") {
          alert('잘못된 인증입니다');
        } else if (data == "insert_ok") {
          alert("등록되었습니다");
          setModal5Open(false);
          setModal4Open(true);
        } else if (data == "update_ok") {
          alert("수정되었습니다");
          setModal5Open(false);
          setModal4Open(true);
        } else {
          alert(data);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function qna_all_del() {
    const formData = new FormData();

    let values = $('[id^=cchk]:checked').map(function () {
      return $(this).val();
    }).get().join(",");

    formData.append("chk[]", values);

    if (values == '') {
      window.alert("체크된 리스트가 없습니다.");
      return;
    }

    if (!$('#qna_auth_code').val()) {
      alert("인증번호를 입력해주세요");
      $('#search_auth_code').focus();
      return;
    }

    if (window.confirm('체크한 게시물을 삭제하시겠습니까?')) {
      formData.append("base_auth_code", btoa($('#qna_auth_code').val()));

      axios2.postFormEx('/guide/qna/all/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data == "all_del") {
            alert("체크한 모든 게시물이 삭제되었습니다");
            setModal4Renew(Fn.getRenewTime());
          } else if (data == "i_del") {
            alert("체크한 게시물중 글쓴이가 등록한 게시물만 삭제되었습니다");
            setModal4Renew(Fn.getRenewTime());
          } else {
            alert("글쓴이가 등록한 게시물이 없습니다");
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  window.go_edit_rep = (seq, rep_seq) => {
    var auth_code = $("#qna_auth_code").val();

    if (!auth_code) {
      $("#qna_search_auth_code").focus();
      alert("인증코드를 입력해주세요");
      return;
    }

    if (!$("#rep_comment" + seq).val()) {
      alert("내용을 입력해주세요");
      return;
    }

    var content = $("#rep_comment" + seq).val()

    $.ajax({
      type: "post",
      url: "/guide/rep/edit",
      data: "base_auth_code=" + btoa(auth_code) + "&content=" + content + "&qna_seq=" + seq + "&rep_seq=" + rep_seq,
      success: function (data) {
        data = data?.message?.trim();

        if (data == "auth_code_err") {
          alert('잘못된 인증번호 입니다');
        } else if (data == "wrong_auth") {
          alert('등록한 본인만 수정가능합니다');
        } else if (data == "ok") {
          alert("수정되었습니다");
          $("#rep_comment" + seq).val('');
          $("#rep_input" + seq).html(`<button type="button" class="btn btn-point pt-3 pb-3" onClick="window.go_rep(${seq})">댓글입력</button>`);
          setModal4Renew(Fn.getRenewTime());
        }
      }
    });
  }

  function edit_rep(seq, rep_seq) {
    var auth_code = $("#qna_auth_code").val();

    if (!auth_code) {
      $("#qna_search_auth_code").focus();
      alert("인증코드를 입력해주세요");
      return;
    }

    $.ajax({
      type: "post",
      url: "/guide/chk/qna/user",
      data: "base_auth_code=" + btoa(auth_code) + "&seq=" + seq + "&rep_seq=" + rep_seq,
      success: function (data) {
        console.log(data);
        data = data?.message?.trim();

        if (data == "auth_code_err") {
          alert('잘못된 인증번호 입니다');
        } else if (data == "manager_err") {
          alert('등록한 본인만 수정가능합니다');
        } else if (data == "ok") {
          var won_data = $("#rep_content" + rep_seq).text();
          won_data = won_data.trim();
          $("#rep_comment" + seq).val(won_data);
          $("#rep_input" + seq).html(`<button type="button" class="btn btn-point pt-3 pb-3" onclick="window.go_edit_rep(${seq}, ${rep_seq})">댓글수정</button>`);
        }
      }
    });
  }

  function rep_del(seq, rep_seq) {
    var auth_code = $("#qna_auth_code").val();

    if (!auth_code) {
      $("#qna_search_auth_code").focus();
      alert("인증코드를 입력해주세요");
      return;
    }

    if (window.confirm("삭제하시겠습니까?")) {
      $.ajax({
        type: "post",
        url: "/guide/rep/del",
        data: "base_auth_code=" + btoa(auth_code) + "&qna_seq=" + seq + "&seq=" + rep_seq,
        success: function (data) {
          data = data?.message?.trim();

          if (data == "auth_code_err") {
            alert('잘못된 인증번호 입니다');
          } else if (data == "wrong_auth") {
            alert('등록한 본인만 삭제가능합니다');
          } else if (data == "ok") {
            alert("삭제되었습니다");
            $("#rep_comment" + seq).val('');
            $("#rep_input" + seq).html(`<button type="button" class="btn btn-point pt-3 pb-3" onClick="window.go_rep(${seq})">댓글입력</button>`);
            setModal4Renew(Fn.getRenewTime());
          }
        }
      });
    }
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);

            const token = (localStorage.getItem("USER.token") || null);
            let headers = token ? {Authorization: "Bearer " + token, CurrentUri: window.location.pathname} : {CurrentUri: window.location.pathname};

            fetch(Constants.API_URL.replace("/api/", "") + `/common/editor/upload`, {
              method: "post",
              body: body,
              headers
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: Constants.API_URL.replace("/api/", "") + `${res.url}`
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      }
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  function gallery() {
    $.ajax({
      type: "post",
      url: "/inc/gallery/connect",
      success: function (data) {
        window.open("http://gallery.spoany.co.kr/gallery/member/connect?connect_key=" + data, "_blank", "fullscreen");
      }
    });
  }

  function maintenance() {
    $.ajax({
      type: "post",
      url: "/inc/maintenance/connect",
      success: function (data) {
        window.open("http://maintenance.spoany.co.kr/maintenance/member/connect?connect_key=" + data, "_blank", "fullscreen");
      }
    });
  }

  return (
    <div id="PAGE_WRAP">

      <div id="overlay">
        <div className="cv-spinner">
          <span className="spinner"></span>
        </div>
      </div>

      <nav id="top_wrap" className="navbar sticky-top flex-md-nowrap p-0">
        <p className="text-left">
          <a className="mr-0 px-3 py-2" onClick={() => history.push("/main/summary")} style={{cursor: "pointer"}}>
            <img src={kd_logo} style={{width: "120px"}}/>
          </a>
        </p>
        <div className="GNB">
          <li className={`${(path.startsWith("/member/") === true && !path.includes("/ocare") && !path.includes("/cu/")) ? "ON" : ""} menu__menu`}>
            <Link to="/member/view/detail">회원관리</Link>

            <div id="subwrapper">
              <nav id="subnav" style={{marginLeft: "255px"}}>
                <ul id="submenu__list" className="d-flex flex-wrap">
                  {auth('A1') &&
                    <li id="submenu__menu">
                      <Link to="/member/view/detail">
                        회원관리
                      </Link>
                    </li>
                  }
                  {auth('A2') &&
                    <li id="submenu__menu">
                      <Link to="/member/list">
                        회원검색
                      </Link>
                    </li>
                  }
                  {auth('A3') &&
                    <li id="submenu__menu">
                      <Link to="/member/history">
                        히스토리
                      </Link>
                    </li>
                  }
                  {auth('A4') &&
                    <li id="submenu__menu">
                      <Link to="/member/service">
                        무상서비스내역
                      </Link>
                    </li>
                  }
                  {auth('A5') &&
                    <li id="submenu__menu">
                      <Link to="/member/refund/list">
                        환불내역
                      </Link>
                    </li>
                  }
                  {auth('A6') &&
                    <li id="submenu__menu">
                      <Link to="/member/input/list">
                        입장내역
                      </Link>
                    </li>
                  }
                  {auth('A13') &&
                    <li id="submenu__menu">
                      <Link to="/member/online/list">
                        온라인회원
                      </Link>
                    </li>
                  }
                  {auth('A14') &&
                    <li id="submenu__menu">
                      <Link to="/member/delay/list">
                        앱/웹연기신청
                      </Link>
                    </li>
                  }
                  {auth('A16') &&
                    <li id="submenu__menu">
                      <Link to="/member/delay/list/all">
                        연기신청내역
                      </Link>
                    </li>
                  }
                  {auth('A7') &&
                    <li id="submenu__menu">
                      <Link to="/member/toreceive">
                        양도양수승인
                      </Link>
                    </li>
                  }
                  {auth('A8') &&
                    <li id="submenu__menu">
                      <Link to="/member/branch/change">
                        주센터변경승인
                      </Link>
                    </li>
                  }
                  {auth('A12') &&
                    <li id="submenu__menu">
                      <Link to="/member/center/change">
                        주센터변경
                      </Link>
                    </li>
                  }
                  {auth('A9') &&
                    <li id="submenu__menu">
                      <Link to="/member/start/date/change">
                        시작일변경승인
                      </Link>
                    </li>
                  }
                  {auth('A10') &&
                    <li id="submenu__menu">
                      <Link to="/member/gift/regi">
                        이용권등록
                      </Link>
                    </li>
                  }
                  {auth('A11') &&
                    <li id="submenu__menu">
                      <Link to="/member/blacklist">
                        관심회원
                      </Link>
                    </li>
                  }
                  {auth('C22') &&
                    <li id="submenu__menu">
                      <Link to="/member/complain/list">
                        컴플레인관리
                      </Link>
                    </li>
                  }
                  {auth('A15') &&
                    <li id="submenu__menu">
                      <Link to="/member/take/over/member">
                        인수센터회원등록
                      </Link>
                    </li>
                  }
                </ul>
              </nav>
            </div>
          </li>
          <li className={`${path.startsWith("/sales/") === true ? "ON" : ""} menu__menu`}>
            <Link to="/sales/list">물품판매</Link>

            <div id="subwrapper">
              <nav id="subnav" style={{marginLeft: "255px"}}>
                <ul id="submenu__list" className="d-flex flex-wrap">
                  {auth('J8') &&
                    <li id="submenu__menu">
                      <Link to="/sales/reg">
                        판매등록
                      </Link>
                    </li>
                  }
                  {auth('J9') &&
                    <li id="submenu__menu">
                      <Link to="/sales/list">
                        판매내역
                      </Link>
                    </li>
                  }
                  {auth('J20') &&
                    <li id="submenu__menu">
                      <Link to="/sales/order/list">
                        발주내역
                      </Link>
                    </li>
                  }
                  {auth('J12') &&
                    <li id="submenu__menu">
                      <Link to="/sales/daily/state">
                        기간별판매현황(일별)
                      </Link>
                    </li>
                  }
                  {auth('J13') &&
                    <li id="submenu__menu">
                      <Link to="/sales/weekly/state">
                        기간별판매현황(주별)
                      </Link>
                    </li>
                  }
                  {auth('J14') &&
                    <li id="submenu__menu">
                      <Link to="/sales/monthly/state">
                        기간별판매현황(월별)
                      </Link>
                    </li>
                  }
                  {auth('J18') &&
                    <li id="submenu__menu">
                      <Link to="/sales/branch/state">
                        기간별지점매출-종합
                      </Link>
                    </li>
                  }
                  {auth('J15') &&
                    <li id="submenu__menu">
                      <Link to="/sales/prod/state">
                        상품별판매현황
                      </Link>
                    </li>
                  }
                  {auth('J16') &&
                    <li id="submenu__menu">
                      <Link to="/sales/branch/price/state">
                        지점랭킹-매출
                      </Link>
                    </li>
                  }
                  {auth('J10') &&
                    <li id="submenu__menu">
                      <Link to="/sales/stock/state">
                        재고현황
                      </Link>
                    </li>
                  }
                  {auth('J11') &&
                    <li id="submenu__menu">
                      <Link to="/sales/stock/state/detail">
                        재고현황(상세)
                      </Link>
                    </li>
                  }
                  {auth('J1') &&
                    <li id="submenu__menu">
                      <Link to="/sales/product/part">
                        품목구분관리
                      </Link>
                    </li>
                  }
                  {auth('J7') &&
                    <li id="submenu__menu">
                      <Link to="/sales/product/position">
                        품목분류관리
                      </Link>
                    </li>
                  }
                  {auth('J2') &&
                    <li id="submenu__menu">
                      <Link to="/sales/product/list">
                        판매상품관리
                      </Link>
                    </li>
                  }
                  {auth('J3') &&
                    <li id="submenu__menu">
                      <Link to="/sales/product/discount">
                        할인상품관리
                      </Link>
                    </li>
                  }
                  {auth('J4') &&
                    <li id="submenu__menu">
                      <Link to="/sales/inout">
                        입고관리
                      </Link>
                    </li>
                  }
                  {auth('J5') &&
                    <li id="submenu__menu">
                      <Link to="/sales/control">
                        재고조정
                      </Link>
                    </li>
                  }
                  {auth('J6') &&
                    <li id="submenu__menu">
                      <Link to="/sales/control/part">
                        조정구분관리
                      </Link>
                    </li>
                  }
                  {auth('J19') &&
                    <li id="submenu__menu">
                      <Link to="/sales/supply/list">
                        공급업체관리
                      </Link>
                    </li>
                  }
                </ul>
              </nav>
            </div>
          </li>
          <li className={`${path.startsWith("/locker/") === true ? "ON" : ""} menu__menu`}>
            <Link to="/locker/list">라커관리</Link>

            <div id="subwrapper">
              <nav id="subnav" style={{marginLeft: "255px"}}>
                <ul id="submenu__list" className="d-flex flex-wrap">
                  {auth('B1') &&
                    <li id="submenu__menu">
                      <Link to="/locker/state">
                        라커현황
                      </Link>
                    </li>
                  }
                  {auth('B2') &&
                    <li id="submenu__menu">
                      <Link to="/locker/list">
                        라커목록
                      </Link>
                    </li>
                  }
                  {auth('B3') &&
                    <li id="submenu__menu">
                      <Link to="/locker/empty/reserve">
                        비움라커목록
                      </Link>
                    </li>
                  }
                  {auth('B4') &&
                    <li id="submenu__menu">
                      <Link to="/locker/reserve">
                        라커예약내역
                      </Link>
                    </li>
                  }
                  {auth('B5') &&
                    <li id="submenu__menu">
                      <Link to="/locker/history">
                        라커히스토리
                      </Link>
                    </li>
                  }
                  {auth('B6') &&
                    <li id="submenu__menu">
                      <Link to="/locker/number">
                        라커번호
                      </Link>
                    </li>
                  }
                  {auth('B7') &&
                    <li id="submenu__menu">
                      <Link to="/locker/config">
                        라커설정
                      </Link>
                    </li>
                  }
                </ul>
              </nav>
            </div>
          </li>
          <li className={`${path.startsWith("/branchpt/") === true ? "ON" : ""} menu__menu`}>
            <Link to="/branchpt/schedule">PT관리</Link>

            <div id="subwrapper">
              <nav id="subnav" style={{marginLeft: "255px"}}>
                <ul id="submenu__list" className="d-flex flex-wrap">
                  {auth('C7') &&
                    <li id="submenu__menu">
                      <Link to="/branchpt/schedule">
                        PT/OT스케쥴
                      </Link>
                    </li>
                  }
                  {auth('C8') &&
                    <li id="submenu__menu">
                      <Link to="/branchpt/trainer">
                        PT/OT스케쥴-트레이너
                      </Link>
                    </li>
                  }
                  {auth('C9') &&
                    <li id="submenu__menu">
                      <Link to="/branchpt/open">
                        PT/OT스케줄 오픈
                      </Link>
                    </li>
                  }
                  {auth('C25') &&
                    <li id="submenu__menu">
                      <Link to="/branchpt/pt/reserve/month">
                        월별진행일정
                      </Link>
                    </li>
                  }
                  {auth('C10') &&
                    <li id="submenu__menu">
                      <Link to="/branchpt/pt/list">
                        PT대상목록
                      </Link>
                    </li>
                  }
                  {auth('C11') &&
                    <li id="submenu__menu">
                      <Link to="/branchpt/pt/take/list">
                        PT진행내역
                      </Link>
                    </li>
                  }
                  {auth('C13') &&
                    <li id="submenu__menu">
                      <Link to="/branchpt/ot/list">
                        OT대상목록
                      </Link>
                    </li>
                  }
                  {auth('F11') &&
                    <li id="submenu__menu" style={{zIndex: 99999999999}}>
                      <Link to="/branchpt/free/ot/list">
                        무료OT신청
                      </Link>
                    </li>
                  }
                  {auth('C14') &&
                    <li id="submenu__menu">
                      <Link to="/branchpt/ot/take/list">
                        OT진행내역
                      </Link>
                    </li>
                  }
                  {auth('C26') &&
                    <li id="submenu__menu">
                      <Link to="/branchpt/pt/take/month">
                        기간별 PT진행
                      </Link>
                    </li>
                  }
                  {auth('C27') &&
                    <li id="submenu__menu">
                      <Link to="/branchpt/ot/take/month">
                        기간별 OT진행
                      </Link>
                    </li>
                  }
                  {auth('C12') &&
                    <li id="submenu__menu">
                      <Link to="/branchpt/pt/route">
                        PT가입경로 설정
                      </Link>
                    </li>
                  }
                </ul>
              </nav>
            </div>
          </li>
          <li className={`${path.startsWith("/branchop/") === true ? "ON" : ""} menu__menu`}>
            <Link to="/branchop/notice/list">지점운영</Link>

            <div id="subwrapper">
              <nav id="subnav" style={{marginLeft: "255px"}}>
                <ul id="submenu__list" className="d-flex flex-wrap">
                  {auth('C16') &&
                    <li id="submenu__menu">
                      <Link to="/branchop/notice/list">
                        공지사항
                      </Link>
                    </li>
                  }
                  {auth('C4') &&
                    <li id="submenu__menu">
                      <Link to="/branchop/sales/target">
                        매출목표 설정
                      </Link>
                    </li>
                  }
                  {auth('E7') &&
                    <li id="submenu__menu">
                      <Link to="/branchop/daily/work/list">
                        일일업무현황
                      </Link>
                    </li>
                  }
                  {auth('C5') &&
                    <li id="submenu__menu">
                      <Link to="/branchop/permit/list">
                        승인처리
                      </Link>
                    </li>
                  }
                  {auth('C3') &&
                    <li id="submenu__menu">
                      <Link to="/branchop/gx/basic">
                        GX 기본정보
                      </Link>
                    </li>
                  }
                  {auth('C1') &&
                    <li id="submenu__menu">
                      <Link to="/branchop/gx/reserve">
                        GX 예약
                      </Link>
                    </li>
                  }
                  {auth('C2') &&
                    <li id="submenu__menu">
                      <Link to="/branchop/gx/member">
                        GX 출석
                      </Link>
                    </li>
                  }
                  {auth('C6') &&
                    <li id="submenu__menu">
                      <Link to="/branchop/inout/cal">
                        타지점정산내역
                      </Link>
                    </li>
                  }
                  {auth('C6') &&
                    <li id="submenu__menu">
                      <Link to="/branchop/inout/cal/new">
                        타지점정산내역(new)
                      </Link>
                    </li>
                  }
                  {auth('C99') &&
                    <li id="submenu__menu">
                      <Link to="/branchop/board/list">
                        문의게시판
                      </Link>
                    </li>
                  }
                  {auth('C29') &&
                    <li id="submenu__menu">
                      <Link to="/branchop/strange/list">
                        이상사용자 신고
                      </Link>
                    </li>
                  }
                  {auth('D18') &&
                    <li id="submenu__menu">
                      <Link to="/branchop/app/install/list">
                        앱설치내역
                      </Link>
                    </li>
                  }
                  {auth('D21') &&
                    <li id="submenu__menu">
                      <Link to="/branchop/step/app/install/list">
                        앱설치내역(스텝)
                      </Link>
                    </li>
                  }
                  {auth('D20') &&
                    <li id="submenu__menu">
                      <Link to="/branchop/step/attend">
                        스텝입장내역
                      </Link>
                    </li>
                  }
                  {auth('D23') &&
                    <li id="submenu__menu">
                      <Link to="/branchop/not/oper/list">
                        비정상운영내역
                      </Link>
                    </li>
                  }
                </ul>
              </nav>
            </div>
          </li>
          <li className={`${path.startsWith("/finance/") === true ? "ON" : ""} menu__menu`}>
            <Link to={`/finance/fee/list/true`}>회계관리</Link>

            <div id="subwrapper">
              <nav id="subnav" style={{marginLeft: "255px"}}>
                <ul id="submenu__list" className="d-flex flex-wrap">
                  {auth('D2') &&
                    <li id="submenu__menu">
                      <Link to="/finance/account">
                        간편장부
                      </Link>
                    </li>
                  }
                  {auth('D3') &&
                    <li id="submenu__menu">
                      <Link to={`/finance/report/renew/${Fn.getRenewTime()}`}>
                        자금일보
                      </Link>
                    </li>
                  }
                  {auth('D4') &&
                    <li id="submenu__menu">
                      <Link to={`/finance/monthly/renew/${Fn.getRenewTime()}`}>
                        월계표
                      </Link>
                    </li>
                  }
                  {auth('D10') &&
                    <li id="submenu__menu">
                      <Link to="/finance/prepaid">
                        전도금 관리
                      </Link>
                    </li>
                  }
                  {auth('D13') &&
                    <li id="submenu__menu">
                      <Link to="/finance/bankbook">
                        통장 입금
                      </Link>
                    </li>
                  }
                  {auth('D6') &&
                    <li id="submenu__menu">
                      <Link to="/finance/cash">
                        비용 지출
                      </Link>
                    </li>
                  }
                  {auth('D5') &&
                    <li id="submenu__menu">
                      <Link to="/finance/card">
                        카드매출
                      </Link>
                    </li>
                  }
                  {auth('D6') &&
                    <li id="submenu__menu">
                      <Link to="/finance/cash/new">
                        현금매출
                      </Link>
                    </li>
                  }
                  {auth('D11') &&
                    <li id="submenu__menu">
                      <Link to="/finance/misu">
                        미납금관리
                      </Link>
                    </li>
                  }
                  {auth('D12') &&
                    <li id="submenu__menu">
                      <Link to="/finance/account/manage">
                        계좌 관리
                      </Link>
                    </li>
                  }
                  {auth('D9') &&
                    <li id="submenu__menu">
                      <Link to="/finance/spending">
                        지출내역
                      </Link>
                    </li>
                  }
                  {auth('D1') &&
                    <li id="submenu__menu">
                      <Link to="/finance/fee/list/true">
                        수수료관리
                      </Link>
                    </li>
                  }
                  {auth('D22') &&
                    <li id="submenu__menu">
                      <Link to="/finance/fran/info">
                        가맹점 수수료 내역
                      </Link>
                    </li>
                  }
                  {auth('D19') &&
                    <li id="submenu__menu">
                      <Link to="/finance/app/web/pay">
                        앱/웹결제내역
                      </Link>
                    </li>
                  }
                  {auth('D19') &&
                    <li id="submenu__menu">
                      <Link to="/finance/app/web/pay/nice">
                        앱/웹결제내역(nice)
                      </Link>
                    </li>
                  }
                  {(auth('F100') && false) &&
                    <li id="submenu__menu">
                      <Link to="/finance/service">
                        결산검수
                      </Link>
                    </li>
                  }
                </ul>
              </nav>
            </div>
          </li>
          <li className={`${path.startsWith("/analysis/") === true ? "ON" : ""} menu__menu`}>
            <Link to="/analysis/state/new/old">경영분석</Link>

            <div id="subwrapper">
              <nav id="subnav" style={{marginLeft: "255px"}}>
                <ul id="submenu__list" className="d-flex flex-wrap">
                  {auth('E10') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/state/new/old">
                        통계분석
                      </Link>
                    </li>
                  }
                  {auth('E8') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/weekly/report">
                        주간업무보고
                      </Link>
                    </li>
                  }
                  {auth('E9') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/monthly/member">
                        월간업무보고
                      </Link>
                    </li>
                  }
                  {auth('E3') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/long/period">
                        스텝별 순위
                      </Link>
                    </li>
                  }
                  {auth('E4') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/branch/ranking">
                        지점랭킹-매출
                      </Link>
                    </li>
                  }
                  {auth('E5') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/branch/ranking/real">
                        지점랭킹-순익
                      </Link>
                    </li>
                  }
                  {auth('E6') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/branch/ranking/pt">
                        지점랭킹-PT
                      </Link>
                    </li>
                  }
                  {auth('E2') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/use/state">
                        이용현황
                      </Link>
                    </li>
                  }
                  {auth('E1') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/kpi">
                        KPI지표 순위
                      </Link>
                    </li>
                  }
                  {auth('D7') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/manager/report">
                        매출현황-스텝별
                      </Link>
                    </li>
                  }
                  {auth('D8') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/trainer/report">
                        PT매출현황-트레이너별
                      </Link>
                    </li>
                  }
                  {auth('D16') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/branch/real/price">
                        지점별순익
                      </Link>
                    </li>
                  }
                  {auth('F10') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/finance/monthly/report">
                        월계표비교분석
                      </Link>
                    </li>
                  }
                  {auth('E11') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/branch/price">
                        회계관리-회원권매출
                      </Link>
                    </li>
                  }
                  {auth('E12') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/branch/price/pt">
                        회계관리-PT매출
                      </Link>
                    </li>
                  }
                  {auth('E13') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/satis/summary">
                        만족도 조사
                      </Link>
                    </li>
                  }
                  {auth('E99') &&
                    <li id="submenu__menu">
                      <Link to="/analysis/point/history">
                        포인트 히스토리
                      </Link>
                    </li>
                  }
                </ul>
              </nav>
            </div>
          </li>
          {(auth('F1') || auth('F2') || auth('F3') || auth('F4') || auth('F5') || auth('F6') || auth('F7') || auth('F8') || auth('D15') || auth('C17') || auth('C24') || auth('C18') || auth('C19') || auth('C20') || auth('C21') || auth('F12') || auth('C23')) &&
            <li className={`${path.startsWith("/config/") === true ? "ON" : ""} menu__menu`}>
              <Link to="/config/ip">시스템관리</Link>

              <div id="subwrapper">
                <nav id="subnav" style={{marginLeft: "255px"}}>
                  <ul id="submenu__list" className="d-flex flex-wrap">
                    {auth('F1') &&
                      <li id="submenu__menu">
                        <Link to="/config/ip">
                          IP관리
                        </Link>
                      </li>
                    }
                    {auth('F2') &&
                      <li id="submenu__menu">
                        <Link to="/config/set/sound">
                          출석소리설정
                        </Link>
                      </li>
                    }
                    {auth('F3') &&
                      <li id="submenu__menu">
                        <Link to="/config/auth/list">
                          메뉴권한 설정
                        </Link>
                      </li>
                    }
                    {auth('F4') &&
                      <li id="submenu__menu">
                        <Link to="/config/join/route">
                          방문경로 관리
                        </Link>
                      </li>
                    }
                    {auth('F5') &&
                      <li id="submenu__menu">
                        <Link to="/config/option">
                          옵션관리
                        </Link>
                      </li>
                    }
                    {auth('F6') &&
                      <li id="submenu__menu">
                        <Link to="/config/weekly/set">
                          년단위 주 설정
                        </Link>
                      </li>
                    }
                    {auth('F7') &&
                      <li id="submenu__menu">
                        <Link to="/config/user/list">
                          계정관리
                        </Link>
                      </li>
                    }
                    {auth('F8') &&
                      <li id="submenu__menu">
                        <Link to="/config/research/list">
                          설문조사
                        </Link>
                      </li>
                    }
                    {auth('D15') &&
                      <li id="submenu__menu">
                        <Link to="/config/sub/price/setting">
                          지출세부항목 설정
                        </Link>
                      </li>
                    }
                    {(auth('C17') || auth('C24')) &&
                      <li id="submenu__menu">
                        <Link to="/config/branch/list">
                          지점정보
                        </Link>
                      </li>
                    }
                    {auth('C18') &&
                      <li id="submenu__menu">
                        <Link to="/config/item/list">
                          지점상품
                        </Link>
                      </li>
                    }
                    {auth('C19') &&
                      <li id="submenu__menu">
                        <Link to="/config/brand/list">
                          브랜드
                        </Link>
                      </li>
                    }
                    {auth('C20') &&
                      <li id="submenu__menu">
                        <Link to="/config/gift/list">
                          이용권 생성
                        </Link>
                      </li>
                    }
                    {auth('C20') &&
                      <li id="submenu__menu">
                        <Link to="/config/gift/list/b2b">
                          이용권 생성(B2B)
                        </Link>
                      </li>
                    }
                    {auth('C21') &&
                      <li id="submenu__menu">
                        <Link to="/config/coupon/list">
                          쿠폰 생성
                        </Link>
                      </li>
                    }
                    {auth('F12') &&
                      <li id="submenu__menu">
                        <Link to="/config/applicant">
                          응모권 생성
                        </Link>
                      </li>
                    }
                    {auth('C23') &&
                      <li id="submenu__menu">
                        <Link to="/config/smile/setting">
                          스마일 평가기준 설정
                        </Link>
                      </li>
                    }
                    {auth('C99') &&
                      <li id="submenu__menu">
                        <Link to="/config/banner">
                          회원관리 배너설정
                        </Link>
                      </li>
                    }
                  </ul>
                </nav>
              </div>
            </li>
          }
          {(auth('G1') || auth('G5') || auth('G7') || auth('G2') || auth('G3') || auth('G4') || auth('G6')) &&
            <li className={`${path.startsWith("/message/") === true ? "ON" : ""} menu__menu`}>
              <Link to="/message/sms/send/list">메시지관리</Link>

              <div id="subwrapper">
                <nav id="subnav" style={{marginLeft: "255px"}}>
                  <ul id="submenu__list" className="d-flex flex-wrap">
                    {auth('G1') &&
                      <li id="submenu__menu">
                        <Link to="/message/sms/send/list">
                          SMS/LMS 발송내역
                        </Link>
                      </li>
                    }
                    {auth('G5') &&
                      <li id="submenu__menu">
                        <Link to="/message/sms/state">
                          SMS/LMS 발송현황
                        </Link>
                      </li>
                    }
                    {auth('G7') &&
                      <li id="submenu__menu">
                        <Link to="/message/sms/reserve/list">
                          SMS/LMS 예약내역
                        </Link>
                      </li>
                    }
                    {auth('G2') &&
                      <li id="submenu__menu">
                        <Link to="/message/tm/reg">
                          TM 등록
                        </Link>
                      </li>
                    }
                    {auth('G3') &&
                      <li id="submenu__menu">
                        <Link to="/message/sms/list">
                          SMS 발송
                        </Link>
                      </li>
                    }
                    {auth('G4') &&
                      <li id="submenu__menu">
                        <Link to="/message/push/list">
                          PUSH 발송
                        </Link>
                      </li>
                    }
                    {auth('G6') &&
                      <li id="submenu__menu">
                        <Link to="/message/push/send/list">
                          PUSH 발송내역
                        </Link>
                      </li>
                    }
                  </ul>
                </nav>
              </div>
            </li>
          }
          {(auth('I1') || auth('I2') || auth('I3') || auth('I4') || auth('I5') || auth('I6') || auth('I7') || auth('I8') || auth('I9') || auth('I10') || auth('H1') || auth('H2') || auth('H3')) &&
            <li className={`${path.startsWith("/employee/") === true ? "ON" : ""} menu__menu`}>
              <Link to="/employee/list">인사관리</Link>

              <div id="subwrapper">
                <nav id="subnav" style={{marginLeft: "255px"}}>
                  <ul id="submenu__list" className="d-flex flex-wrap">
                    {auth('I1') &&
                      <li id="submenu__menu">
                        <Link to="/employee/list">
                          스텝관리
                        </Link>
                      </li>
                    }
                    {auth('I2') &&
                      <li id="submenu__menu">
                        <Link to="/employee/branch">
                          이동내역
                        </Link>
                      </li>
                    }
                    {auth('I3') &&
                      <li id="submenu__menu">
                        <Link to="/employee/new/position">
                          진급내역
                        </Link>
                      </li>
                    }
                    {auth('I4') &&
                      <li id="submenu__menu">
                        <Link to="/employee/config">
                          명칭/형태/부서 설정
                        </Link>
                      </li>
                    }
                    {auth('I5') &&
                      <li id="submenu__menu">
                        <Link to="/employee/position/fee">
                          타겟수수료
                        </Link>
                      </li>
                    }
                    {auth('I6') &&
                      <li id="submenu__menu">
                        <Link to="/employee/groupware">
                          그룹웨어 ID
                        </Link>
                      </li>
                    }
                    {auth('I7') &&
                      <li id="submenu__menu">
                        <Link to="/employee/blacklist">
                          관심스포애너
                        </Link>
                      </li>
                    }
                    {auth('I8') &&
                      <li id="submenu__menu">
                        <Link to="/employee/ace/work">
                          ACE 활동
                        </Link>
                      </li>
                    }
                    {auth('I9') &&
                      <li id="submenu__menu">
                        <Link to="/employee/book/list">
                          독서경영
                        </Link>
                      </li>
                    }
                    {auth('I10') &&
                      <li id="submenu__menu">
                        <Link to="/employee/pt/trainer/list">
                          스텝 Web표시
                        </Link>
                      </li>
                    }
                    {auth('H1') &&
                      <li id="submenu__menu">
                        <Link to="/employee/inv/list">
                          투자자리스트
                        </Link>
                      </li>
                    }
                    {auth('H2') &&
                      <li id="submenu__menu">
                        <Link to="/employee/inv/group/new">
                          투자자그룹(신규)
                        </Link>
                      </li>
                    }
                    {auth('H3') &&
                      <li id="submenu__menu">
                        <Link to="/employee/inv/mail/send/list/new">
                          메일발송내역(신규)
                        </Link>
                      </li>
                    }
                  </ul>
                </nav>
              </div>
            </li>
          }
          <li className={`${path.toLowerCase().includes("/ocare") === true ? "ON" : ""} menu__menu`}>
            <Link to="/member/ocare/coupon">BtoB</Link>

            <div id="subwrapper">
              <nav id="subnav" style={{marginLeft: "573px"}}>
                <ul id="submenu__list" className="d-flex flex-wrap">
                  {auth('A17') &&
                    <li id="submenu__menu">
                      <Link to="/member/ocare/coupon">
                        오케어쿠폰발행내역
                      </Link>
                    </li>
                  }
                  {auth('A18') &&
                    <li id="submenu__menu">
                      <Link to="/member/ocare/use/list">
                        오케어쿠폰사용내역
                      </Link>
                    </li>
                  }
                  {auth('A21') &&
                    <li id="submenu__menu">
                      <Link to="/member/ocare/use/list/branch">
                        오케어쿠폰사용내역(현장)
                      </Link>
                    </li>
                  }
                  {auth('A19') &&
                    <li id="submenu__menu">
                      <Link to="/member/ocare/reg">
                        오케어쿠폰등록
                      </Link>
                    </li>
                  }
                  {auth('A20') &&
                    <li id="submenu__menu">
                      <Link to="/member/ocare/member">
                        오케어회원
                      </Link>
                    </li>
                  }
                </ul>
                <ul id="submenu__list" className="d-flex flex-wrap">
                  {auth('A101') &&
                    <li id="submenu__menu">
                      <Link to="/member/cu/use/list">
                        CU쿠폰사용내역
                      </Link>
                    </li>
                  }
                  {auth('A102') &&
                    <li id="submenu__menu">
                      <Link to="/member/cu/reg">
                        CU쿠폰등록
                      </Link>
                    </li>
                  }
                </ul>
              </nav>
            </div>
          </li>
          {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 9 &&
            <li className={`${path.startsWith("/secure/") === true ? "ON" : ""} menu__menu`}>
              <Link to="/secure/menu/auth/history">보안</Link>

              <div id="subwrapper">
                <nav id="subnav" style={{marginLeft: "725px"}}>
                  <ul id="submenu__list" className="d-flex flex-wrap">
                    <li id="submenu__menu">
                      <Link to="/secure/menu/auth/history">
                        권한수정이력
                      </Link>
                    </li>
                    <li id="submenu__menu">
                      <Link to="/secure/list/history">
                        계정변경이력
                      </Link>
                    </li>
                    <li id="submenu__menu">
                      <Link to="/secure/excel/down/history">
                        엑셀다운이력
                      </Link>
                    </li>
                    <li id="submenu__menu">
                      <Link to="/secure/member/show/history">
                        회원정보열람이력
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </li>
          }
          <li>
            <div className="d-flex">
              <button type="button" className="btn btn-ssm btn-dark cls-btn-entrance" onClick={ctx.openEntWinHandler}>입장내역</button>
              <div style={{
                textAlign: "left",
                minWidth: (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 8 || localStorage.getItem("USER.systemManageYN") === "Y" || localStorage.getItem("USER.systemAccountYN") === "Y") ? "300px" : "180px",
                paddingLeft: "20px"
              }}>
                {(Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 8 || localStorage.getItem("USER.systemManageYN") === "Y" || localStorage.getItem("USER.systemAccountYN") === "Y") &&
                  <select className="form-select mx-1" aria-label="Default select example" style={{minWidth: "70px", fontSize: "13px"}} name="S_top_brand" id="S_top_brand" onChange={() => Top_select_brand()}>
                    <option value="">브랜드</option>
                    {brands && brands.map((ret, i) => <option value={ret?.brand}>{ret.brand_name}</option>)}
                  </select>
                }
                <select className="form-select mx-1" aria-label="Default select example" style={{minWidth: "130px", fontSize: "13px"}} id='top_S_branch_list' onChange={() => changeBranch()}>
                  {branches && branches.map((ret, i) => <option value={ret?.branch_code} selected={localStorage.getItem("USER.systemBranchCode") == ret.branch_code}>{(ret.ho || '') + "." + ret.branch_name}</option>)}
                  {(localStorage.getItem("USER.systemAccountYN") == "Y" || localStorage.getItem("USER.systemID") == "mamark" || localStorage.getItem("USER.systemID") == "futureinfo" || localStorage.getItem("USER.systemID") == "spoany" || localStorage.getItem("USER.systemID") == "tpwls8905") &&
                    <option value='ALL'>전체지점</option>
                  }
                </select>
              </div>
            </div>
          </li>
        </div>


        <div className="px-2 d-flex">
          <div className="top_user_thum"></div>
          <div className="top-user-info mt-1" style={{cursor: "pointer"}} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setTimeout(() => {
              $('#my_info').toggle();
            }, 10);
          }}>
            <a href="#"><span className="h7 bold700">{localStorage.getItem("USER.systemName")}</span>님</a>
            <button type="button" className="btn btn-ssm p-1 mb-1"><img src={select_down} style={{width: "12px"}}/></button>
          </div>

          <div className="p_absolute" style={{top: "55px", right: "35px", display: "none"}} id="my_info">
            <div className="lay_myinfo">
              <a style={{cursor: "pointer"}} onClick={() => onLogout()}>
                <div className="p-1 bold700 border-bottom">로그아웃</div>
              </a>
              <a style={{cursor: "pointer"}} onClick={() => {
                setTimeout(() => {
                  $('#my_info').toggle();
                  setModal1Open(true);
                }, 10);
              }}>
                <div className="p-1 bold700 border-bottom">비밀번호 변경</div>
              </a>
              <a style={{cursor: "pointer"}} onClick={() => {
                setTimeout(() => {
                  $('#my_info').toggle();
                  setModal2Open(true);
                }, 10);
              }}>
                <div className="p-1 bold700 border-bottom">인증코드 변경</div>
              </a>
            </div>
          </div>
        </div>
      </nav>

      <div id="CONTENT" className="container-fluid d-flex">
        <nav id="LNB" className="py-3">
          <p className="text-left">
            <button type="button" className="btn" onClick={() => {
              if ($('.cls-menu').is(":visible")) {
                setPrevMainContentsWidth(parseInt($('#mainContents').css('max-width'), 10));
                setPrevMainWidth($('main').width());
                setPrevLeftWidth($('#LNB').width());

                $('#LNB').css('min-width', '50px');
                $('#LNB').css('max-width', '50px');
                $('#LNB').css('width', '50px');
                $('main').width($('main').width() + 150);
                $('#mainContents').css('max-width', $('#mainContents').width() + 150);
                $('.cls-banner, .cls-menu').hide();
              } else {
                $('#LNB').css('min-width', '200px');
                $('#LNB').css('max-width', '200px');
                $('#LNB').css('width', '200px');
                $('main').width(prevMainWidth);
                $('#mainContents').css('max-width', prevMainContentsWidth + 'px');
                $('.cls-banner, .cls-menu').show();
              }
            }}><img src={m_LNB} style={{width: "24px"}}/></button>
          </p>

          <div className="Clock cls-menu" style={{zoom: "90%"}}>
            <li className="d-flex"><span className="time" id="logout_min">90</span><span style={{marginLeft: "3px", paddingTop: "5px"}}>분 &nbsp;</span><span className="time" id="logout_sec">00</span><span
              style={{marginLeft: "3px", paddingTop: "5px"}}>초 &nbsp;</span></li>
            <li>
              <button type="button" className="btn btn-sssm btn-point" onClick={() => window.extendLogout()}>연장</button>
            </li>
          </div>
          {banner && banner?.banner_img &&
            <div className="cls-banner">
              <a href={banner?.link_url} target={banner?.new_win === 'Y' ? "_new" : ""}><img src={Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=member_pic&filename=" + encodeURIComponent(banner?.banner_img))}
                                                                                             style={{width: "195px"}}/></a>
            </div>
          }
          {((path.startsWith("/member/") && !path.includes("/ocare") && !path.includes("/cu/")) && (
            <ul className="cls-menu cls-menu-ex">
              {auth('A1') &&
                <li className={`${path.startsWith("/member/view") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/view/detail">
                    회원관리
                  </Link>
                </li>
              }
              {auth('A2') &&
                <li className={`${path.startsWith("/member/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/list">
                    회원검색
                  </Link>
                </li>
              }
              {auth('A3') &&
                <li className={`${path.startsWith("/member/history") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/history">
                    히스토리
                  </Link>
                </li>
              }
              {auth('A4') &&
                <li className={`${path.startsWith("/member/service") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/service">
                    무상서비스내역
                  </Link>
                </li>
              }
              {auth('A5') &&
                <li className={`${path.startsWith("/member/refund/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/refund/list">
                    환불내역
                  </Link>
                </li>
              }
              {auth('A6') &&
                <li className={`${(path.startsWith("/member/input/list") === true || path.startsWith("/member/input/stat") === true) ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/input/list">
                    입장내역
                  </Link>
                </li>
              }
              {auth('A13') &&
                <li className={`${path.startsWith("/member/online/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/online/list">
                    온라인회원
                  </Link>
                </li>
              }
              {auth('A14') &&
                <li className={`${path.endsWith("/member/delay/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/delay/list">
                    앱/웹연기신청
                  </Link>
                </li>
              }
              {auth('A16') &&
                <li className={`${path.startsWith("/member/delay/list/all") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/delay/list/all">
                    연기신청내역
                  </Link>
                </li>
              }
              {auth('A7') &&
                <li className={`${path.startsWith("/member/toreceive") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/toreceive">
                    양도양수승인
                  </Link>
                </li>
              }
              {auth('A8') &&
                <li className={`${path.startsWith("/member/branch/change") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/branch/change">
                    주센터변경승인
                  </Link>
                </li>
              }
              {auth('A12') &&
                <li className={`${path.startsWith("/member/center/change") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/center/change">
                    주센터변경
                  </Link>
                </li>
              }
              {auth('A9') &&
                <li className={`${path.startsWith("/member/start/date/change") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/start/date/change">
                    시작일변경승인
                  </Link>
                </li>
              }
              {auth('A10') &&
                <li className={`${path.startsWith("/member/gift/regi") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/gift/regi">
                    이용권등록
                  </Link>
                </li>
              }
              {auth('A11') &&
                <li className={`${path.startsWith("/member/blacklist") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/blacklist">
                    관심회원
                  </Link>
                </li>
              }
              {auth('C22') &&
                <li className={`${path.startsWith("/member/complain/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/complain/list">
                    컴플레인관리
                  </Link>
                </li>
              }
              {auth('A15') &&
                <li className={`${path.startsWith("/member/take/over/member") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/take/over/member">
                    인수센터회원등록
                  </Link>
                </li>
              }
            </ul>
          ))}

          {(path.startsWith("/sales/") && (
            <ul className="cls-menu cls-menu-ex">
              {auth('J8') &&
                <li className={`${path.startsWith("/sales/reg") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/reg">
                    판매등록
                  </Link>
                </li>
              }
              {auth('J9') &&
                <li className={`${path.startsWith("/sales/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/list">
                    판매내역
                  </Link>
                </li>
              }
              {auth('J20') &&
                <li className={`${path.startsWith("/sales/order/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/order/list">
                    발주내역
                  </Link>
                </li>
              }
              {auth('J12') &&
                <li className={`${path.startsWith("/sales/daily/state") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/daily/state">
                    기간별판매현황(일별)
                  </Link>
                </li>
              }
              {auth('J13') &&
                <li className={`${path.startsWith("/sales/weekly/state") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/weekly/state">
                    기간별판매현황(주별)
                  </Link>
                </li>
              }
              {auth('J14') &&
                <li className={`${path.startsWith("/sales/monthly/state") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/monthly/state">
                    기간별판매현황(월별)
                  </Link>
                </li>
              }
              {auth('J18') &&
                <li className={`${path.startsWith("/sales/branch/state") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/branch/state">
                    기간별지점매출-종합
                  </Link>
                </li>
              }
              {auth('J15') &&
                <li className={`${path.endsWith("/sales/prod/state") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/prod/state">
                    상품별판매현황
                  </Link>
                </li>
              }
              {auth('J16') &&
                <li className={`${path.startsWith("/sales/branch/price/state") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/branch/price/state">
                    지점랭킹-매출
                  </Link>
                </li>
              }
              {auth('J10') &&
                <li className={`${path.endsWith("/sales/stock/state") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/stock/state">
                    재고현황
                  </Link>
                </li>
              }
              {auth('J11') &&
                <li className={`${path.startsWith("/sales/stock/state/detail") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/stock/state/detail">
                    재고현황(상세)
                  </Link>
                </li>
              }
              {auth('J1') &&
                <li className={`${path.startsWith("/sales/product/part") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/product/part">
                    품목구분관리
                  </Link>
                </li>
              }
              {auth('J7') &&
                <li className={`${path.startsWith("/sales/product/position") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/product/position">
                    품목분류관리
                  </Link>
                </li>
              }
              {auth('J2') &&
                <li className={`${path.startsWith("/sales/product/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/product/list">
                    판매상품관리
                  </Link>
                </li>
              }
              {auth('J3') &&
                <li className={`${path.startsWith("/sales/product/discount") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/product/discount">
                    할인상품관리
                  </Link>
                </li>
              }
              {auth('J4') &&
                <li className={`${path.endsWith("/sales/inout") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/inout">
                    입고관리
                  </Link>
                </li>
              }
              {auth('J5') &&
                <li className={`${path.startsWith("/sales/control") === true && path.startsWith("/sales/control/part") === false ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/control">
                    재고조정
                  </Link>
                </li>
              }
              {auth('J6') &&
                <li className={`${path.startsWith("/sales/control/part") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/control/part">
                    조정구분관리
                  </Link>
                </li>
              }
              {auth('J19') &&
                <li className={`${path.startsWith("/sales/supply/") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/sales/supply/list">
                    공급업체관리
                  </Link>
                </li>
              }
            </ul>
          ))}

          {(path.startsWith("/locker/") && (
            <ul className="cls-menu cls-menu-ex">
              {auth('B1') &&
                <li className={`${path.startsWith("/locker/state") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/locker/state">
                    라커현황
                  </Link>
                </li>
              }
              {auth('B2') &&
                <li className={`${path.startsWith("/locker/list") === true ? "ON SRCH_DONE ALMOST_DONE" : "SRCH_DONE ALMOST_DONE"}`}>
                  <Link to="/locker/list">
                    라커목록
                  </Link>
                </li>
              }
              {auth('B3') &&
                <li className={`${path.startsWith("/locker/empty/reserve") === true ? "ON SRCH_DONE ALMOST_DONE" : "SRCH_DONE ALMOST_DONE"}`}>
                  <Link to="/locker/empty/reserve">
                    비움라커목록
                  </Link>
                </li>
              }
              {auth('B4') &&
                <li className={`${path.startsWith("/locker/reserve") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/locker/reserve">
                    라커예약내역
                  </Link>
                </li>
              }
              {auth('B5') &&
                <li className={`${path.startsWith("/locker/history") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/locker/history">
                    라커히스토리
                  </Link>
                </li>
              }
              {auth('B6') &&
                <li className={`${path.startsWith("/locker/number") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/locker/number">
                    라커번호
                  </Link>
                </li>
              }
              {auth('B7') &&
                <li className={`${path.startsWith("/locker/config") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/locker/config">
                    라커설정
                  </Link>
                </li>
              }
            </ul>
          ))}

          {(path.startsWith("/branchpt/") && (
            <ul className="cls-menu cls-menu-ex">
              {auth('C7') &&
                <li className={`${path.startsWith("/branchpt/schedule") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchpt/schedule">
                    PT/OT스케쥴
                  </Link>
                </li>
              }
              {auth('C8') &&
                <li className={`${path.startsWith("/branchpt/trainer") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchpt/trainer">
                    PT/OT스케쥴-트레이너
                  </Link>
                </li>
              }
              {auth('C9') &&
                <li className={`${path.startsWith("/branchpt/open") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchpt/open">
                    PT/OT스케줄 오픈
                  </Link>
                </li>
              }
              {auth('C25') &&
                <li className={`${path.startsWith("/branchpt/pt/reserve/month") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchpt/pt/reserve/month">
                    월별진행일정
                  </Link>
                </li>
              }
              {auth('C10') &&
                <li className={`${path.startsWith("/branchpt/pt/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchpt/pt/list">
                    PT대상목록
                  </Link>
                </li>
              }
              {auth('C11') &&
                <li className={`${path.startsWith("/branchpt/pt/take/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchpt/pt/take/list">
                    PT진행내역
                  </Link>
                </li>
              }
              {auth('C13') &&
                <li className={`${path.startsWith("/branchpt/ot/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchpt/ot/list">
                    OT대상목록
                  </Link>
                </li>
              }
              {auth('F11') &&
                <li className={`${path.startsWith("/branchpt/free/ot/list") === true ? "ON SRCH_DONE ALMOST_DONE" : "SRCH_DONE ALMOST_DONE"}`}>
                  <Link to="/branchpt/free/ot/list">
                    무료OT신청
                  </Link>
                </li>
              }
              {auth('C14') &&
                <li className={`${path.startsWith("/branchpt/ot/take/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchpt/ot/take/list">
                    OT진행내역
                  </Link>
                </li>
              }
              {auth('C26') &&
                <li className={`${path.startsWith("/branchpt/pt/take/month") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchpt/pt/take/month">
                    트레이너 기간별PT진행
                  </Link>
                </li>
              }
              {auth('C27') &&
                <li className={`${path.startsWith("/branchpt/ot/take/month") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchpt/ot/take/month">
                    트레이너 기간별OT진행
                  </Link>
                </li>
              }
              {auth('C12') &&
                <li className={`${path.startsWith("/branchpt/pt/route") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchpt/pt/route">
                    PT가입경로 설정
                  </Link>
                </li>
              }
            </ul>
          ))}

          {(path.startsWith("/branchop/") && (
            <ul className="cls-menu cls-menu-ex">
              {auth('C16') &&
                <li className={`${path.startsWith("/branchop/notice/") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchop/notice/list">
                    공지사항
                  </Link>
                </li>
              }
              {auth('C4') &&
                <li className={`${path.startsWith("/branchop/sales/target") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchop/sales/target">
                    매출목표 설정
                  </Link>
                </li>
              }
              {auth('E7') &&
                <li className={`${path.startsWith("/branchop/daily/work/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchop/daily/work/list">
                    일일업무현황
                  </Link>
                </li>
              }
              {auth('C5') &&
                <li className={`${path.startsWith("/branchop/permit/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchop/permit/list">
                    승인처리
                  </Link>
                </li>
              }
              {auth('C3') &&
                <li className={`${path.startsWith("/branchop/gx/basic") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchop/gx/basic">
                    GX 기본정보
                  </Link>
                </li>
              }
              {auth('C1') &&
                <li className={`${path.startsWith("/branchop/gx/reserve") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchop/gx/reserve">
                    GX 예약
                  </Link>
                </li>
              }
              {auth('C2') &&
                <li className={`${path.startsWith("/branchop/gx/member") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchop/gx/member">
                    GX 출석
                  </Link>
                </li>
              }
              {auth('C6') &&
                <li className={`${path.endsWith("/branchop/inout/cal") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchop/inout/cal">
                    타지점정산내역
                  </Link>
                </li>
              }
              {auth('C6') &&
                <li className={`${path.startsWith("/branchop/inout/cal/new") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchop/inout/cal/new">
                    타지점정산내역(new)
                  </Link>
                </li>
              }
              {auth('C99') &&
                <li className={`${path.startsWith("/branchop/board/") === true ? "ON _NO_DESIGN SRCH_DONE LIKE_DONE" : "_NO_DESIGN SRCH_DONE LIKE_DONE"}`}>
                  <Link to="/branchop/board/list">
                    문의게시판
                  </Link>
                </li>
              }
              {auth('C29') &&
                <li className={`${path.startsWith("/branchop/strange/") === true ? "ON SRCH_DONE ALMOST_DONE" : "SRCH_DONE ALMOST_DONE"}`}>
                  <Link to="/branchop/strange/list">
                    이상사용자 신고
                  </Link>
                </li>
              }
              {auth('D18') &&
                <li className={`${path.startsWith("/branchop/app/install/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchop/app/install/list">
                    앱설치내역
                  </Link>
                </li>
              }
              {auth('D21') &&
                <li className={`${path.startsWith("/branchop/step/app/install/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchop/step/app/install/list">
                    앱설치내역(스텝)
                  </Link>
                </li>
              }
              {auth('D20') &&
                <li className={`${path.startsWith("/branchop/step/attend") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchop/step/attend">
                    스텝입장내역
                  </Link>
                </li>
              }
              {auth('D23') &&
                <li className={`${path.startsWith("/branchop/not/oper/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/branchop/not/oper/list">
                    비정상운영내역
                  </Link>
                </li>
              }
            </ul>
          ))}

          {(path.startsWith("/finance/") && (
            <ul className="cls-menu cls-menu-ex">
              {auth('D2') &&
                <li className={`${path.endsWith("/finance/account") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/finance/account">
                    간편장부
                  </Link>
                </li>
              }
              {auth('D3') &&
                <li className={`${path.startsWith("/finance/report") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to={`/finance/report/renew/${Fn.getRenewTime()}`}>
                    자금일보
                  </Link>
                </li>
              }
              {auth('D4') &&
                <li className={`${path.startsWith("/finance/monthly") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to={`/finance/monthly/renew/${Fn.getRenewTime()}`}>
                    월계표
                  </Link>
                </li>
              }
              {auth('D10') &&
                <li className={`${path.startsWith("/finance/prepaid") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/finance/prepaid">
                    전도금 관리
                  </Link>
                </li>
              }
              {auth('D13') &&
                <li className={`${path.startsWith("/finance/bankbook") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/finance/bankbook">
                    통장 입금
                  </Link>
                </li>
              }
              {auth('D6') &&
                <li className={`${path.endsWith("/finance/cash") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/finance/cash">
                    비용 지출
                  </Link>
                </li>
              }
              {auth('D5') &&
                <li className={`${path.startsWith("/finance/card") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/finance/card">
                    카드매출
                  </Link>
                </li>
              }
              {auth('D6') &&
                <li className={`${path.startsWith("/finance/cash/new") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/finance/cash/new">
                    현금매출
                  </Link>
                </li>
              }
              {auth('D11') &&
                <li className={`${path.startsWith("/finance/misu") === true ? "ON SRCH_DONE ALMOST_DONE" : "SRCH_DONE ALMOST_DONE"}`}>
                  <Link to="/finance/misu">
                    미납금관리
                  </Link>
                </li>
              }
              {auth('D12') &&
                <li className={`${path.startsWith("/finance/account/manage") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/finance/account/manage">
                    계좌 관리
                  </Link>
                </li>
              }
              {auth('D9') &&
                <li className={`${path.startsWith("/finance/spending") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/finance/spending">
                    지출내역
                  </Link>
                </li>
              }
              {auth('D1') &&
                <li className={`${path.startsWith("/finance/fee/") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/finance/fee/list/true">
                    수수료관리
                  </Link>
                </li>
              }
              {auth('D22') &&
                <li className={`${path.startsWith("/finance/fran/info") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/finance/fran/info">
                    가맹점 수수료 내역
                  </Link>
                </li>
              }
              {auth('D19') &&
                <li className={`${path.endsWith("/finance/app/web/pay") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/finance/app/web/pay">
                    앱/웹결제내역
                  </Link>
                </li>
              }
              {auth('D19') &&
                <li className={`${path.startsWith("/finance/app/web/pay/nice") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/finance/app/web/pay/nice">
                    앱/웹결제내역(nice)
                  </Link>
                </li>
              }
              {(auth('F100') && false) &&
                <li className={`${path.startsWith("/finance/service") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/finance/service">
                    결산검수
                  </Link>
                </li>
              }
            </ul>
          ))}

          {(path.startsWith("/analysis/") && (
            <ul className="cls-menu cls-menu-ex">
              {auth('E10') &&
                <li className={`${path.startsWith("/analysis/state/") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/state/new/old">
                    통계분석
                  </Link>
                </li>
              }
              {auth('E8') &&
                <li className={`${path.startsWith("/analysis/weekly/report") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/weekly/report">
                    주간업무보고
                  </Link>
                </li>
              }
              {auth('E9') &&
                <li className={`${path.startsWith("/analysis/monthly/") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/monthly/member">
                    월간업무보고
                  </Link>
                </li>
              }
              {auth('E3') &&
                <li className={`${path.startsWith("/analysis/long/period") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/long/period">
                    스텝별 순위
                  </Link>
                </li>
              }
              {auth('E4') &&
                <li className={`${path.endsWith("/analysis/branch/ranking") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/branch/ranking">
                    지점랭킹-매출
                  </Link>
                </li>
              }
              {auth('E5') &&
                <li className={`${path.startsWith("/analysis/branch/ranking/real") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/branch/ranking/real">
                    지점랭킹-순익
                  </Link>
                </li>
              }
              {auth('E6') &&
                <li className={`${path.startsWith("/analysis/branch/ranking/pt") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/branch/ranking/pt">
                    지점랭킹-PT
                  </Link>
                </li>
              }
              {auth('E2') &&
                <li className={`${path.startsWith("/analysis/use/state") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/use/state">
                    이용현황
                  </Link>
                </li>
              }
              {auth('E1') &&
                <li className={`${path.startsWith("/analysis/kpi") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/kpi">
                    KPI지표 순위
                  </Link>
                </li>
              }
              {auth('D7') &&
                <li className={`${path.startsWith("/analysis/manager/report") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/manager/report">
                    매출현황-스텝별
                  </Link>
                </li>
              }
              {auth('D8') &&
                <li className={`${path.startsWith("/analysis/trainer/report") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/trainer/report">
                    PT매출현황-트레이너별
                  </Link>
                </li>
              }
              {auth('D16') &&
                <li className={`${path.startsWith("/analysis/branch/real/price") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/branch/real/price">
                    지점별순익
                  </Link>
                </li>
              }
              {auth('F10') &&
                <li className={`${path.startsWith("/analysis/finance/monthly/report") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/finance/monthly/report">
                    월계표비교분석
                  </Link>
                </li>
              }
              {auth('E11') &&
                <li className={`${path.endsWith("/analysis/branch/price") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/branch/price">
                    회계관리-회원권매출
                  </Link>
                </li>
              }
              {auth('E12') &&
                <li className={`${path.startsWith("/analysis/branch/price/pt") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/branch/price/pt">
                    회계관리-PT매출
                  </Link>
                </li>
              }
              {auth('E13') &&
                <li className={`${path.startsWith("/analysis/satis/") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/satis/summary">
                    만족도 조사
                  </Link>
                </li>
              }
              {auth('E99') &&
                <li className={`${path.startsWith("/analysis/point/history") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/analysis/point/history">
                    포인트 히스토리
                  </Link>
                </li>
              }
            </ul>
          ))}

          {(path.startsWith("/config/") && (
            <ul className="cls-menu cls-menu-ex">
              {auth('F1') &&
                <li className={`${path.startsWith("/config/ip") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/ip">
                    IP관리
                  </Link>
                </li>
              }
              {auth('F2') &&
                <li className={`${path.startsWith("/config/set/sound") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/set/sound">
                    출석소리설정
                  </Link>
                </li>
              }
              {auth('F3') &&
                <li className={`${path.startsWith("/config/auth/") === true ? "ON SRCH_DONE ALMOST_DONE" : "SRCH_DONE ALMOST_DONE"}`}>
                  <Link to="/config/auth/list">
                    메뉴권한 설정
                  </Link>
                </li>
              }
              {auth('F4') &&
                <li className={`${path.startsWith("/config/join/route") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/join/route">
                    방문경로 관리
                  </Link>
                </li>
              }
              {auth('F5') &&
                <li className={`${path.startsWith("/config/option") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/option">
                    옵션관리
                  </Link>
                </li>
              }
              {auth('F6') &&
                <li className={`${path.startsWith("/config/weekly/set") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/weekly/set">
                    년단위 주 설정
                  </Link>
                </li>
              }
              {auth('F7') &&
                <li className={`${path.startsWith("/config/user/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/user/list">
                    계정관리
                  </Link>
                </li>
              }
              {auth('F8') &&
                <li className={`${path.startsWith("/config/research/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/research/list">
                    설문조사
                  </Link>
                </li>
              }
              {auth('D15') &&
                <li className={`${path.startsWith("/config/sub/price/setting") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/sub/price/setting">
                    지출세부항목 설정
                  </Link>
                </li>
              }
              {auth('C17') &&
                <li className={`${path.startsWith("/config/branch/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/branch/list">
                    지점정보
                  </Link>
                </li>
              }
              {auth('C18') &&
                <li className={`${path.startsWith("/config/item/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/item/list">
                    지점상품
                  </Link>
                </li>
              }
              {auth('C19') &&
                <li className={`${path.startsWith("/config/brand/list") === true ? "ON SRCH_DONE ALMOST_DONE" : "SRCH_DONE ALMOST_DONE"}`}>
                  <Link to="/config/brand/list">
                    브랜드
                  </Link>
                </li>
              }
              {auth('C20') &&
                <li className={`${path.endsWith("/config/gift/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/gift/list">
                    이용권 생성
                  </Link>
                </li>
              }
              {auth('C20') &&
                <li className={`${path.startsWith("/config/gift/list/b2b") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/gift/list/b2b">
                    이용권 생성(B2B)
                  </Link>
                </li>
              }
              {auth('C21') &&
                <li className={`${path.startsWith("/config/coupon/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/coupon/list">
                    쿠폰 생성
                  </Link>
                </li>
              }
              {auth('F12') &&
                <li className={`${path.startsWith("/config/applicant") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/applicant">
                    응모권 생성
                  </Link>
                </li>
              }
              {auth('C23') &&
                <li className={`${path.startsWith("/config/smile/setting") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/smile/setting">
                    스마일 평가기준 설정
                  </Link>
                </li>
              }
              {auth('C99') &&
                <li className={`${path.startsWith("/config/banner") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/config/banner">
                    회원관리 배너설정
                  </Link>
                </li>
              }
            </ul>
          ))}

          {(path.startsWith("/message/") && (
            <ul className="cls-menu cls-menu-ex">
              {auth('G1') &&
                <li className={`${path.startsWith("/message/sms/send/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/message/sms/send/list">
                    SMS/LMS 발송내역
                  </Link>
                </li>
              }
              {auth('G5') &&
                <li className={`${path.startsWith("/message/sms/state") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/message/sms/state">
                    SMS/LMS 발송현황
                  </Link>
                </li>
              }
              {auth('G7') &&
                <li className={`${path.startsWith("/message/sms/reserve/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/message/sms/reserve/list">
                    SMS/LMS 예약내역
                  </Link>
                </li>
              }
              {auth('G2') &&
                <li className={`${path.startsWith("/message/tm/reg") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/message/tm/reg">
                    TM 등록
                  </Link>
                </li>
              }
              {auth('G3') &&
                <li className={`${path.startsWith("/message/sms/list") === true ? "ON ALMOST_DONE" : "ALMOST_DONE"}`}>
                  <Link to="/message/sms/list">
                    SMS 발송
                  </Link>
                </li>
              }
              {auth('G4') &&
                <li className={`${path.startsWith("/message/push/list") === true ? "ON SRCH_DONE LIKE_DONE" : "SRCH_DONE LIKE_DONE"}`}>
                  <Link to="/message/push/list">
                    PUSH 발송
                  </Link>
                </li>
              }
              {auth('G6') &&
                <li className={`${path.startsWith("/message/push/send/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/message/push/send/list">
                    PUSH 발송내역
                  </Link>
                </li>
              }
            </ul>
          ))}

          {(path.startsWith("/employee/") && (
            <ul className="cls-menu cls-menu-ex">
              {auth('I1') &&
                <li className={`${(path.startsWith("/employee/list") === true || path.startsWith("/employee/reg") === true) ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/employee/list">
                    스텝관리
                  </Link>
                </li>
              }
              {auth('I2') &&
                <li className={`${path.startsWith("/employee/branch") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/employee/branch">
                    이동내역
                  </Link>
                </li>
              }
              {auth('I3') &&
                <li className={`${path.startsWith("/employee/new/position") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/employee/new/position">
                    진급내역
                  </Link>
                </li>
              }
              {auth('I4') &&
                <li className={`${path.startsWith("/employee/config") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/employee/config">
                    명칭/형태/부서 설정
                  </Link>
                </li>
              }
              {auth('I5') &&
                <li className={`${path.startsWith("/employee/position/fee") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/employee/position/fee">
                    타겟수수료
                  </Link>
                </li>
              }
              {auth('I6') &&
                <li className={`${path.startsWith("/employee/groupware") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/employee/groupware">
                    그룹웨어 ID
                  </Link>
                </li>
              }
              {auth('I7') &&
                <li className={`${path.startsWith("/employee/blacklist") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/employee/blacklist">
                    관심스포애너
                  </Link>
                </li>
              }
              {auth('I8') &&
                <li className={`${path.startsWith("/employee/ace/") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/employee/ace/work">
                    ACE 활동
                  </Link>
                </li>
              }
              {auth('I9') &&
                <li className={`${(path.startsWith("/employee/book/") === true) ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/employee/book/list">
                    독서경영
                  </Link>
                </li>
              }
              {auth('I10') &&
                <li className={`${path.startsWith("/employee/pt/trainer/") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/employee/pt/trainer/list">
                    스텝 Web표시
                  </Link>
                </li>
              }
              {auth('H1') &&
                <li className={`${(path.startsWith("/employee/inv/list") === true || path.startsWith("/employee/inv/reg") === true) ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/employee/inv/list">
                    투자자리스트
                  </Link>
                </li>
              }
              {auth('H2') &&
                <li className={`${path.startsWith("/employee/inv/group/new") === true ? "ON _NO_DESIGN SRCH_DONE LIKE_DONE" : "_NO_DESIGN SRCH_DONE LIKE_DONE"}`}>
                  <Link to="/employee/inv/group/new">
                    투자자그룹(신규)
                  </Link>
                </li>
              }
              {auth('H3') &&
                <li className={`${path.startsWith("/employee/inv/mail/send/list/new") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/employee/inv/mail/send/list/new">
                    메일발송내역(신규)
                  </Link>
                </li>
              }
            </ul>
          ))}

          {((path.toLowerCase().includes("/ocare") || path.toLowerCase().includes("/cu/")) && (
            <ul className="cls-menu cls-menu-ex">
              {auth('A17') &&
                <li className={`${path.startsWith("/member/ocare/coupon") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/ocare/coupon">
                    오케어쿠폰발행내역
                  </Link>
                </li>
              }
              {auth('A18') &&
                <li className={`${path.endsWith("/member/ocare/use/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/ocare/use/list">
                    오케어쿠폰사용내역
                  </Link>
                </li>
              }
              {auth('A21') &&
                <li className={`${path.startsWith("/member/ocare/use/list/branch") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/ocare/use/list/branch">
                    오케어쿠폰사용내역(현장)
                  </Link>
                </li>
              }
              {auth('A19') &&
                <li className={`${path.startsWith("/member/ocare/reg") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/ocare/reg">
                    오케어쿠폰등록
                  </Link>
                </li>
              }
              {auth('A20') &&
                <li className={`${path.startsWith("/member/ocare/member") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/ocare/member">
                    오케어회원
                  </Link>
                </li>
              }
              {auth('A101') &&
                <li className={`${path.endsWith("/member/cu/use/list") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/cu/use/list">
                    CU쿠폰사용내역
                  </Link>
                </li>
              }
              {auth('A102') &&
                <li className={`${path.startsWith("/member/cu/reg") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                  <Link to="/member/cu/reg">
                    CU쿠폰등록
                  </Link>
                </li>
              }
            </ul>
          ))}

          {(path.startsWith("/secure/") &&
            Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 9 &&
            <ul className="cls-menu cls-menu-ex">
              <li className={`${path.startsWith("/secure/menu/auth/history") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                <Link to="/secure/menu/auth/history">
                  권한수정이력
                </Link>
              </li>
              <li className={`${path.startsWith("/secure/list/history") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                <Link to="/secure/list/history">
                  계정변경이력
                </Link>
              </li>
              <li className={`${path.startsWith("/secure/excel/down/history") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                <Link to="/secure/excel/down/history">
                  엑셀다운이력
                </Link>
              </li>
              <li className={`${path.startsWith("/secure/member/show/history") === true ? "ON SRCH_DONE DONE" : "SRCH_DONE DONE"}`}>
                <Link to="/secure/member/show/history">
                  회원정보열람이력
                </Link>
              </li>
            </ul>
          )}

          <div className="L_button cls-menu">
            <p><img src={icon_help}/><a style={{cursor: "pointer"}} onClick={() => setModal4Open(true)}>질문게시판</a></p>
            <p><img src={icon_book}/><a style={{cursor: "pointer"}} onClick={() => history.push("/guide/step/manual")}>실무 매뉴얼 북</a></p>
            <p><img src={icon_book}/><a style={{cursor: "pointer"}} onClick={() => history.push("/guide/smms/manual")}>전산 매뉴얼 북</a></p>
            <p><img src={icon_book}/><a style={{cursor: "pointer"}} onClick={() => history.push("/guide/clean/manual")}>청결 매뉴얼 북</a></p>
            <p><img src={icon_book}/><a style={{cursor: "pointer"}} onClick={() => history.push("/guide/info/plan")}>개인정보내부관리</a></p>
            <p><img src={icon_book}/><a style={{cursor: "pointer"}} onClick={() => history.push("/guide/info/manual")}>개인정보유출대응</a></p>
            <p><img src={icon_book}/><a style={{cursor: "pointer"}} onClick={() => gallery()}>홍보물샘플게시판</a></p>
            {(localStorage.getItem("USER.systemID") == "futureinfo" || (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 8 && (localStorage.getItem("USER.systemName") == "김현종" || localStorage.getItem("USER.systemName") == "최형섭" || localStorage.getItem("USER.systemName") == "박세진"))) &&
              <p><img src={icon_book}/><a style={{cursor: "pointer"}} onClick={() => maintenance()}>유지보수자동화</a></p>
            }
          </div>
        </nav>

        <main className="content_margin">

          <div id="SUBTITLE" className="d-inline">
            <div className="border-bottom text-left d-flex justify-content-between">
              <div>
                {(path.startsWith("/main/summary") && (<p className="Title py-3 px-4"><strong>HOME</strong></p>))}

                {(path.startsWith("/member/view") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 회원정보</strong></p>))}
                {(path.startsWith("/member/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 회원검색</strong></p>))}
                {(path.startsWith("/member/history") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 히스토리</strong></p>))}
                {(path.startsWith("/member/service") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 무상서비스내역</strong></p>))}
                {(path.startsWith("/member/refund/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 환불내역</strong></p>))}
                {(path.startsWith("/member/input/list" || path.startsWith("/member/input/stat")) && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 입장내역</strong></p>))}
                {(path.startsWith("/member/input/stat") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 입장내역 통계</strong></p>))}
                {(path.startsWith("/member/online/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 온라인회원</strong></p>))}
                {(path.endsWith("/member/delay/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 앱/웹연기신청</strong></p>))}
                {(path.startsWith("/member/delay/list/all") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 연기신청내역</strong></p>))}
                {(path.startsWith("/member/toreceive") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 양도양수승인</strong></p>))}
                {(path.startsWith("/member/branch/change") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 주센터변경승인</strong></p>))}
                {(path.startsWith("/member/center/change") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 주센터변경</strong></p>))}
                {(path.startsWith("/member/start/date/change") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 시작일변경승인</strong></p>))}
                {(path.startsWith("/member/gift/regi") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 이용권등록</strong></p>))}
                {(path.startsWith("/member/blacklist") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 관심회원</strong></p>))}
                {(path.startsWith("/member/complain/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 컴플레인관리</strong></p>))}
                {(path.startsWith("/member/take/over/member") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회원관리 > 인수센터회원등록</strong></p>))}
                {(path.startsWith("/member/ocare/coupon") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > BtoB > 오케어쿠폰발행내역</strong></p>))}
                {(path.endsWith("/member/ocare/use/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > BtoB > 오케어쿠폰사용내역</strong></p>))}
                {(path.startsWith("/member/ocare/use/list/branch") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > BtoB > 오케어쿠폰사용내역(현장)</strong></p>))}
                {(path.startsWith("/member/ocare/reg") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > BtoB > 오케어쿠폰등록</strong></p>))}
                {(path.startsWith("/member/ocare/member") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > BtoB > 오케어회원</strong></p>))}
                {(path.endsWith("/member/cu/use/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > BtoB > CU쿠폰사용내역</strong></p>))}
                {(path.startsWith("/member/cu/reg") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > BtoB > CU쿠폰등록</strong></p>))}

                {(path.startsWith("/sales/reg") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 현장판매</strong></p>))}
                {(path.startsWith("/sales/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 판매내역</strong></p>))}
                {((path.startsWith("/sales/order/list") || path.startsWith("/sales/order/reg")) && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 발주내역</strong></p>))}
                {(path.startsWith("/sales/daily/state") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 기간별판매현황(일별)</strong></p>))}
                {(path.startsWith("/sales/weekly/state") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 기간별판매현황(주별)</strong></p>))}
                {(path.startsWith("/sales/monthly/state") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 기간별판매현황(월별)</strong></p>))}
                {(path.startsWith("/sales/branch/state") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 기간별지점매출-종합</strong></p>))}
                {(path.endsWith("/sales/prod/state") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 상품별판매현황</strong></p>))}
                {(path.startsWith("/sales/branch/price/state") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 지점랭킹-매출</strong></p>))}
                {(path.endsWith("/sales/stock/state") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 재고현황</strong></p>))}
                {(path.startsWith("/sales/stock/state/detail") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 재고현황(상세)</strong></p>))}
                {(path.startsWith("/sales/product/part") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 품목구분관리</strong></p>))}
                {(path.startsWith("/sales/product/position") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 품목분류관리</strong></p>))}
                {((path.startsWith("/sales/product/list") || path.startsWith("/sales/product/reg")) && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 판매상품관리</strong></p>))}
                {(path.startsWith("/sales/product/discount") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 할인상품관리</strong></p>))}
                {(path.startsWith("/sales/inout") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 입고관리</strong></p>))}
                {((path.startsWith("/sales/control") && !path.startsWith("/sales/control/part")) && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 재고조정</strong></p>))}
                {(path.startsWith("/sales/control/part") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 조정구분관리</strong></p>))}
                {(path.startsWith("/sales/supply/") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 물품판매 > 공급업체관리</strong></p>))}

                {(path.startsWith("/locker/state") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 라커관리 > 라커현황</strong></p>))}
                {(path.startsWith("/locker/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 라커관리 > 라커목록</strong></p>))}
                {(path.startsWith("/locker/empty/reserve") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 라커관리 > 비움라커목록</strong></p>))}
                {(path.startsWith("/locker/reserve") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 라커관리 > 라커예약내역</strong></p>))}
                {(path.startsWith("/locker/history") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 라커관리 > 라커히스토리</strong></p>))}
                {(path.startsWith("/locker/number") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 라커관리 > 라커번호</strong></p>))}
                {(path.startsWith("/locker/config") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 라커관리 > 라커설정</strong></p>))}

                {(path.startsWith("/branchpt/schedule") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > PT관리 > PT/OT스케쥴</strong></p>))}
                {(path.startsWith("/branchpt/trainer") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > PT관리 > PT/OT스케쥴-트레이너</strong></p>))}
                {(path.startsWith("/branchpt/open") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > PT관리 > PT/OT스케줄 오픈</strong></p>))}
                {(path.startsWith("/branchpt/pt/reserve") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > PT관리 > 월별진행일정</strong></p>))}
                {(path.startsWith("/branchpt/pt/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > PT관리 > PT대상목록</strong></p>))}
                {(path.startsWith("/branchpt/pt/take/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > PT관리 > PT진행내역</strong></p>))}
                {(path.startsWith("/branchpt/ot/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > PT관리 > OT대상목록</strong></p>))}
                {(path.startsWith("/branchpt/free/ot/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > PT관리 > 무료OT신청</strong></p>))}
                {(path.startsWith("/branchpt/ot/take/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > PT관리 > OT진행내역</strong></p>))}
                {(path.startsWith("/branchpt/pt/take/month") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > PT관리 > 트레이너 기간별 PT 진행</strong></p>))}
                {(path.startsWith("/branchpt/ot/take/month") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > PT관리 > 트레이너 기간별 OT 진행</strong></p>))}
                {(path.startsWith("/branchpt/pt/route") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > PT관리 > PT가입경로 설정</strong></p>))}

                {(path.startsWith("/branchop/notice/") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 지점운영 > 공지사항</strong></p>))}
                {(path.startsWith("/branchop/sales/target") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 지점운영 > 매출목표</strong></p>))}
                {(path.startsWith("/branchop/daily/work/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 지점운영 > 일일업무현황</strong></p>))}
                {(path.startsWith("/branchop/permit/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 지점운영 > 승인처리</strong></p>))}
                {(path.startsWith("/branchop/gx/basic") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 지점운영 > GX 기본정보</strong></p>))}
                {(path.startsWith("/branchop/gx/reserve") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 지점운영 > GX 예약</strong></p>))}
                {(path.startsWith("/branchop/gx/member") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 지점운영 > GX 출석</strong></p>))}
                {(path.endsWith("/branchop/inout/cal") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 지점운영 > 타지점정산내역</strong></p>))}
                {(path.startsWith("/branchop/inout/cal/new") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 지점운영 > 타지점정산내역</strong></p>))}
                {(path.startsWith("/branchop/board/") && (<p className="Title py-3 px-4"><strong>지점운영 > 문의게시판</strong></p>))}
                {((path.startsWith("/branchop/strange/list") || path.startsWith("/branchop/strange/write")) && (<p className="Title py-3 px-4"><strong>지점운영 > 이상이용자 신고</strong></p>))}
                {(path.startsWith("/branchop/app/install/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 지점운영 > 앱설치내역</strong></p>))}
                {(path.startsWith("/branchop/step/app/install/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 지점운영 > 앱설치내역(스텝)</strong></p>))}
                {(path.startsWith("/branchop/step/attend") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 지점운영 > 스텝입장내역</strong></p>))}
                {(path.startsWith("/branchop/not/oper/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 지점운영 > 비정상 운영내역</strong></p>))}

                {(path.endsWith("/finance/account") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 간편장부</strong></p>))}
                {(path.startsWith("/finance/report") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 자금일보</strong></p>))}
                {(path.startsWith("/finance/monthly") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 월계표</strong></p>))}
                {(path.startsWith("/finance/prepaid") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 전도금</strong></p>))}
                {(path.startsWith("/finance/bankbook") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 통장입금</strong></p>))}
                {(path.endsWith("/finance/cash") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 비용지출</strong></p>))}
                {(path.startsWith("/finance/card") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 카드매출</strong></p>))}
                {(path.startsWith("/finance/cash/new") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 현금매출</strong></p>))}
                {(path.startsWith("/finance/misu") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 미납금 관리</strong></p>))}
                {(path.startsWith("/finance/account/manage") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 계좌관리</strong></p>))}
                {(path.startsWith("/finance/spending") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 지출내역</strong></p>))}
                {(path.startsWith("/finance/fee/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 수수료관리 > 수수료 내역보고</strong></p>))}
                {(path.startsWith("/finance/fee/public") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 수수료관리 > 퍼블릭 부서</strong></p>))}
                {(path.startsWith("/finance/fee/pt") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 수수료관리 > PT 부서</strong></p>))}
                {(path.startsWith("/finance/fee/add") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 수수료관리 > 추가/기타</strong></p>))}
                {((path === "/finance/fee/incen" || path.startsWith("/finance/fee/incen?")) && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 수수료관리 > 인센티브</strong></p>))}
                {((path.startsWith("/finance/fee/incen/pt") || path.startsWith("/finance/fee/incen/pt?")) && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 수수료관리 > PT 수당</strong></p>))}
                {(path.startsWith("/finance/fee/gx") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 수수료관리 > GX 수당</strong></p>))}
                {(path.startsWith("/finance/fee/consult") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 수수료관리 > 영업컨설팅비</strong></p>))}
                {(path.startsWith("/finance/fee/step") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 수수료관리 > 직원별 수수료</strong></p>))}
                {(path.startsWith("/finance/fee/overtime/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 수수료관리 > 추가근무관리</strong></p>))}
                {(path.startsWith("/finance/fee/etc") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 수수료관리 > 기타수당</strong></p>))}
                {(path.startsWith("/finance/fee/branch/overprice") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 수수료관리 > 초과수당설정</strong></p>))}
                {(path.startsWith("/finance/fran/info") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 가맹점 수수료 내역</strong></p>))}
                {(path.endsWith("/finance/app/web/pay") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 앱/웹 결제내역</strong></p>))}
                {(path.startsWith("/finance/app/web/pay/nice") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 앱/웹 결제내역</strong></p>))}
                {(path.startsWith("/finance/service") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 결산검수</strong></p>))}

                {(path.startsWith("/analysis/state/new/old") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 통계분석 > 구분별</strong></p>))}
                {(path.startsWith("/analysis/state/period") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 통계분석 > 기간별</strong></p>))}
                {(path.startsWith("/analysis/state/visit") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 통계분석 > 경로별</strong></p>))}
                {(path.startsWith("/analysis/state/week") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 통계분석 > 주별회원현황</strong></p>))}
                {(path.startsWith("/analysis/state/month") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 통계분석 > 월별회원현황</strong></p>))}
                {(path.startsWith("/analysis/weekly/report") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 주간업무보고</strong></p>))}
                {(path.startsWith("/analysis/monthly/member") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 월간업무보고 > 월간등록현황</strong></p>))}
                {(path.startsWith("/analysis/monthly/finance/new") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 월간업무보고 > 월계표 비교분석</strong></p>))}
                {(path.startsWith("/analysis/monthly/coupon") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 월간업무보고 > 쿠폰발송내역</strong></p>))}
                {(path.startsWith("/analysis/monthly/tm") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 월간업무보고 > TM 현황보고</strong></p>))}
                {(path.startsWith("/analysis/monthly/region") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 월간업무보고 > 지역분류</strong></p>))}
                {(path.startsWith("/analysis/monthly/public") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 월간업무보고 > 경쟁업체(퍼블릭)</strong></p>))}
                {(path.startsWith("/analysis/monthly/pt") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 월간업무보고 > 경쟁업체(PT)</strong></p>))}
                {(path.startsWith("/analysis/monthly/analysis/pt") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 월간업무보고 > PT월간등록현황</strong></p>))}
                {(path.startsWith("/analysis/long/period") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 스텝별순위</strong></p>))}
                {(path.endsWith("/analysis/branch/ranking") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 지점랭킹 - 매출</strong></p>))}
                {(path.startsWith("/analysis/branch/ranking/real") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 지점랭킹 - 순익</strong></p>))}
                {(path.startsWith("/analysis/branch/ranking/pt") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 지점랭킹 - PT</strong></p>))}
                {(path.startsWith("/analysis/use/state") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 이용현황</strong></p>))}
                {(path.startsWith("/analysis/kpi") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > KPI 지표</strong></p>))}
                {(path.startsWith("/analysis/manager/report") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 매출현황 - 스텝별</strong></p>))}
                {(path.startsWith("/analysis/trainer/report") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > PT매출현황 - 트레이너별</strong></p>))}
                {(path.startsWith("/analysis/branch/real/price") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 지점별 순익</strong></p>))}
                {(path.startsWith("/analysis/finance/monthly/report") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 월계표비교분석</strong></p>))}
                {(path.endsWith("/analysis/branch/price") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > 회원권 매출</strong></p>))}
                {(path.startsWith("/analysis/branch/price/pt") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 회계관리 > PT 매출</strong></p>))}
                {(path.startsWith("/analysis/satis/") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 만족도 조사</strong></p>))}
                {(path.startsWith("/analysis/point/history") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 경영분석 > 포인트 히스토리</strong></p>))}

                {(path.startsWith("/config/ip") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > IP관리</strong></p>))}
                {(path.startsWith("/config/set/sound") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 출석소리설정</strong></p>))}
                {(path.startsWith("/config/auth/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 메뉴권한설정</strong></p>))}
                {(path.startsWith("/config/join/route") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 방문경로 관리</strong></p>))}
                {(path.startsWith("/config/option") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 옵션관리</strong></p>))}
                {(path.startsWith("/config/weekly/set") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 년단위 주 설정</strong></p>))}
                {(path.startsWith("/config/user/") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 계정관리</strong></p>))}
                {(path.startsWith("/config/research/") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 설문조사</strong></p>))}
                {(path.startsWith("/config/sub/price/setting") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 지출세부항목 설정</strong></p>))}
                {(path.startsWith("/config/branch/") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 지점정보</strong></p>))}
                {(path.startsWith("/config/item/") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 지점상품</strong></p>))}
                {(path.startsWith("/config/brand/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 브랜드 관리</strong></p>))}
                {(path.endsWith("/config/gift/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 이용권 생성</strong></p>))}
                {(path.startsWith("/config/gift/list/b2b") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 이용권 생성(B2B)</strong></p>))}
                {(path.startsWith("/config/coupon/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 쿠폰 생성</strong></p>))}
                {(path.startsWith("/config/applicant") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 응모권 생성</strong></p>))}
                {(path.startsWith("/config/smile/setting") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 스마일 평가기준 설정</strong></p>))}
                {(path.startsWith("/config/banner") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 시스템관리 > 회원관리 배너설정</strong></p>))}

                {(path.startsWith("/message/sms/send/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 메세지관리 > SMS발송내역</strong></p>))}
                {(path.startsWith("/message/sms/state") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 메세지관리 > SMS발송현황</strong></p>))}
                {(path.startsWith("/message/sms/reserve/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 메세지관리 > SMS예약내역</strong></p>))}
                {(path.startsWith("/message/tm/reg") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 메세지관리 > TM등록</strong></p>))}
                {(path.startsWith("/message/sms/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 메세지관리 > SMS 발송</strong></p>))}
                {(path.startsWith("/message/push/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 메세지관리 > PUSH발송</strong></p>))}
                {(path.startsWith("/message/push/send/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 메세지관리 > PUSH 발송내역</strong></p>))}

                {((path.startsWith("/employee/list") || path.startsWith("/employee/reg")) && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > 스텝관리</strong></p>))}
                {(path.startsWith("/employee/branch") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > 이동내역</strong></p>))}
                {(path.startsWith("/employee/new/position") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > 진급내역</strong></p>))}
                {(path.startsWith("/employee/config") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > 명칭/형태/부서 설정</strong></p>))}
                {(path.startsWith("/employee/position/fee") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > 타겟수수료</strong></p>))}
                {(path.startsWith("/employee/groupware") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > 그룹웨어 ID</strong></p>))}
                {(path.startsWith("/employee/blacklist") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > 관심스포애너</strong></p>))}
                {(path.startsWith("/employee/ace/work") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > ACE 활동 > 고객유치</strong></p>))}
                {(path.startsWith("/employee/ace/report") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > ACE 활동 > 고객유지</strong></p>))}
                {((path.startsWith("/employee/book/list") || path.startsWith("/employee/book/reg")) && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > 독서경영 > 도서관리</strong></p>))}
                {(path.startsWith("/employee/book/end/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > 독서경영 > 필독완료</strong></p>))}
                {(path.startsWith("/employee/book/meet/list") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > 독서경영 > 모임완료</strong></p>))}
                {(path.startsWith("/employee/pt/trainer/") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > 스텝 Web표시</strong></p>))}
                {((path.startsWith("/employee/inv/list") || path.startsWith("/employee/inv/reg")) && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > 투자자 리스트</strong></p>))}
                {(path.startsWith("/employee/inv/group/new") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > 투자자 그룹</strong></p>))}
                {(path.startsWith("/employee/inv/mail/send/list/new") && (<p className="Title py-3 px-4"><strong>{systemBranchName} > 인사관리 > 메일발송내역</strong></p>))}

                {(path.startsWith("/secure/menu/auth/") && (<p className="Title py-3 px-4"><strong>메뉴권한 수정이력</strong></p>))}
                {(path.startsWith("/secure/list/") && (<p className="Title py-3 px-4"><strong>계정 수정이력</strong></p>))}
                {(path.startsWith("/secure/excel/down/history") && (<p className="Title py-3 px-4"><strong>엑셀다운이력</strong></p>))}
                {(path.startsWith("/secure/member/show/history") && (<p className="Title py-3 px-4"><strong>회원정보열람이력</strong></p>))}

                {(path.startsWith("/guide/step/manual") && (<p className="Title py-3 px-4"><strong>실무 매뉴얼북</strong></p>))}
                {(path.startsWith("/guide/smms/manual") && (<p className="Title py-3 px-4"><strong>전산 매뉴얼북</strong></p>))}
                {(path.startsWith("/guide/clean/manual") && (<p className="Title py-3 px-4"><strong>청결 매뉴얼북</strong></p>))}
                {(path.startsWith("/guide/info/plan") && (<p className="Title py-3 px-4"><strong>개인정보 내부관리계획</strong></p>))}
                {(path.startsWith("/guide/info/manual") && (<p className="Title py-3 px-4"><strong>개인정보 유출 대응 매뉴얼</strong></p>))}

              </div>
            </div>
          </div>

          {/*---- 컨텐츠 내용 시작 S -----*/}
          <div id="mainContents" style={{minWidth: "1608.5px", maxWidth: "1708.5px"}}>{props.children}</div>
          {/*---- 컨텐츠 내용 시작 E -----*/}


          <Modal
            isOpen={modal1Open}
            style={{
              modal: {
                backgroundColor: 'transparent',
                margin: 0,
                padding: 0,
                alignItems: undefined,
                justifyContent: undefined,
              },
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'transparent'
              }
            }}
            onAfterOpen={() => {
              Fn.centerModal();
              loadModal1();
            }}
            onRequestClose={() => setModal1Open(false)}
            ariaHideApp={false}
          >
            <div className="pop_background" style={{zIndex: 1000000000}}>
              <div className="pop_box01 p_absolute_mid_pop" style={{width: "500px"}}>
                <div className="POP_title">
                  <h5><strong>비밀번호 변경</strong></h5>
                  <p className="closed">
                    <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                      </svg>
                    </button>
                  </p>
                </div>
                <div className="POP_cnt_box w-100">
                  <table className="view-rounded sub_view">
                    <colgroup>
                      <col width="108px"/>
                      <col width="*"/>
                    </colgroup>
                    <tr>
                      <th>ID</th>
                      <td id="user_id"></td>
                    </tr>
                    <tr>
                      <th>성명</th>
                      <td><input type="text" className="form-control mx-1" placeholder="성명" id="user_name"
                                 tabIndex={1}
                      /></td>
                    </tr>
                    <tr>
                      <th>기존비밀번호</th>
                      <td><input type="password" className="form-control mx-1" id="old_pwd"
                                 tabIndex={2}
                      /></td>
                    </tr>
                    <tr>
                      <th>비밀번호</th>
                      <td><input type="password" className="form-control mx-1" id="user_pwd"
                                 tabIndex={3}
                      /></td>
                    </tr>
                    <tr>
                      <th>비밀번호 확인</th>
                      <td><input type="password" className="form-control mx-1" id="re_pwd"
                                 tabIndex={4}
                      /></td>
                    </tr>
                  </table>
                </div>
                <div className="text-center mb-3">
                  <button type="button" className="btn btn-sm btn-point px-2 mx-1" onClick={() => input_insert()}>수정</button>
                  <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal1Open(false)}>닫기</button>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={modal2Open}
            style={{
              modal: {
                backgroundColor: 'transparent',
                margin: 0,
                padding: 0,
                alignItems: undefined,
                justifyContent: undefined,
              },
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'transparent'
              }
            }}
            onRequestClose={() => setModal2Open(false)}
            ariaHideApp={false}
          >
            <div className="pop_background" style={{zIndex: 1000000000}}>
              <div className="pop_box01 p_absolute_mid_pop" style={{width: "500px"}}>
                <div className="POP_title">
                  <h5><strong>인증코드 변경</strong></h5>
                  <p className="closed">
                    <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                      </svg>
                    </button>
                  </p>
                </div>
                <div className="POP_cnt_box w-100">
                  <table className="view-rounded sub_view">
                    <colgroup>
                      <col width="108px"/>
                      <col width="*"/>
                    </colgroup>
                    <tr>
                      <th>기존 인증코드</th>
                      <td>
                        <div className="form-group" style={{width: "auto"}}>
                          <input type="password" className="form-control3 mx-1" id="at_auth_code"/>
                          <span id="at_manager_name"></span>
                          <button type="button" className="btn btn-ssm btn-point" onClick={() => chk_auth_code()}>확인</button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>변경인증코드</th>
                      <td><input type="password" className="form-control mx-1" id="at_new_auth_code"/></td>
                    </tr>
                    <tr>
                      <th>인증코드확인</th>
                      <td><input type="password" className="form-control mx-1" id="at_new_auth_code_re" onBlur={() => chk_auth()}/></td>
                    </tr>
                  </table>
                </div>
                <div className="text-center mb-3">
                  <button type="button" className="btn btn-sm btn-point px-2 mx-1" onClick={() => auth_go_submit('in')}>수정</button>
                  <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal2Open(false)}>닫기</button>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={modal4Open}
            style={{
              modal: {
                backgroundColor: 'transparent',
                margin: 0,
                padding: 0,
                alignItems: undefined,
                justifyContent: undefined,
              },
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'transparent'
              }
            }}
            onAfterOpen={() => {
              Fn.centerModal();

              $('.fn_search_box_pop').each(function () {
                $(this).find("input[type=text]").each(function () {
                  $(this).onEnter(() => setModal4SrchRenew(Fn.getRenewTime));
                });
              });

              loadModal4();
            }}
            onRequestClose={() => setModal4Open(false)}
            ariaHideApp={false}
          >
            <div className="pop_background" style={{zIndex: 1000000000}}>
              <div className="pop_box01 p_absolute_mid_pop" style={{minWidth: 1200, maxWidth: 1200}}>
                <div className="POP_title">
                  <h5><strong>질문게시판</strong></h5>
                  <p className="closed">
                    <button type="button" className="btn" onClick={() => setModal4Open(false)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                      </svg>
                    </button>
                  </p>
                </div>
                <div className="POP_cnt_box w-100">
                  <div className="mb-3 d-flex flex-nowrap fn_search_box_pop">
                    <div className="pr-1 d-flex" id="qna">
                      <ChkAuthNum type={"locker_info_change"} prefix="qna_"/>
                    </div>
                    <div className="pr-1">
                      <select className="form-select" id="modal4_keyname">
                        <option value="tc">제목+내용</option>
                        <option value="title">제목</option>
                        <option value="content">내용</option>
                      </select>
                    </div>
                    <div className="pr-1">
                      <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" id="modal4_key"/>
                    </div>
                    <div className="pr-1">
                      <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => setModal4SrchRenew(Fn.getRenewTime())}>검색</button>
                    </div>
                    <div className="pr-1">
                      <button type="button" className="btn btn-sm btn-point px-3" onClick={() => {
                        if (!$('#qna_auth_code').val()) {
                          alert('인증번호를 입력해 주세요.');
                          return;
                        }
                        $('#modal5_qna_auth_code').val($('#qna_auth_code').val());
                        $('#modal5_base_seq').val('');
                        setQnaContent("");
                        setModal4Open(false);
                        setModal5Open(true);
                      }}>등록
                      </button>
                    </div>
                  </div>
                  <table className="view-rounded sub_table_border">
                    <colgroup>
                      <col width="40px"/>
                      <col width="60px"/>
                      <col width="80px"/>
                      <col width="*"/>
                      <col width="85px"/>
                      <col width="70px"/>
                      <col width="60px"/>
                    </colgroup>
                    <tr>
                      <th>
                        <input type="checkbox" onClick={(e) => {
                          if ($(e.target).prop("checked")) $('[id^=cchk]').prop("checked", true);
                          else $('[id^=cchk]').prop("checked", false);
                        }}/>
                      </th>
                      <th>No</th>
                      <th>지점명</th>
                      <th>제목</th>
                      <th>작성일</th>
                      <th>작성자</th>
                      <th>수정</th>
                    </tr>
                    {modal4Result?.result?.map((ret, i) =>
                      <tr>
                        <td className="text-center" style={{verticalAlign: "top"}}>
                          <input
                            type="checkbox"
                            name="chk"
                            value={btoa(ret?.seq)}
                            className="checkbox"
                            id={`cchk${i + 1}`}
                          />
                        </td>
                        <td className="text-center" style={{verticalAlign: "top"}}>{modal4PageInfo.totalArticles - modal4PageInfo.articlesPerPage * (modal4PageInfo.currentPage - 1) - i}</td>
                        <td className="text-center" style={{verticalAlign: "top"}}>{ret?.branch_name}</td>
                        <td>
                          <a style={{cursor: "pointer"}}
                             onClick={() => {
                               const obj = $(`#modal4Content_${ret?.seq}`);
                               if (obj.is(':hidden')) obj.slideDown(300);
                               else obj.slideUp(300);
                             }}
                          >
                            <div dangerouslySetInnerHTML={{__html: ret?.title}}/>
                            {ret?.rep_num > 0 &&
                              <span style={{color: "red"}}>{` `}({ret?.rep_num})</span>
                            }
                          </a>
                          <div style={{display: "none"}} id={`modal4Content_${ret?.seq}`}>
                            <div className="m-1 pb-2">
                              <table className="sub_view">
                                <tr>
                                  <td className="p-2 text-left" dangerouslySetInnerHTML={{__html: ret?.content.replace(/indent:[ ]*\-[0-9]+px/gi, '')}}/>
                                </tr>
                              </table>
                            </div>
                            <div className="m-0 pb-2">
                              <table className="w-100 sub_view">
                                <tr>
                                  <td className="p-0 m-0 text-left">
                                    <div className="d-flex">
                                      <textarea className="form-control" style={{width: 'calc(100% - 90px)'}} id={`rep_comment${ret?.seq}`}></textarea>
                                      <span id={`rep_input${ret?.seq}`}><button type="button" className="btn btn-point pb-3 pt-3" onClick={() => window.go_rep(ret?.seq)}>댓글입력</button></span>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            {ret?.repList?.map((row, index) =>
                              <div key={index} className="reply" style={{padding: 10}}>
                                <p className="USER_thum_sm2">
                                </p>
                                <div className="text-left px-3" style={{flex: 2}}>
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex" style={{justifyContent: "flex-end", alignItems: "flex-end", alignContent: "flex-end"}}>
                                      <p className="bold700 h7 pr-2">{row?.manager_name}</p>
                                      <p className="text-left text-gray h9 bold700">{row?.reg_date?.substring(2, 16)?.replace(/-/g, ".")}</p>
                                    </div>
                                    <p className="float-right">
                                      <button type="button" className="btn btn-ssm btn-outline-point mx-1" onClick={() => edit_rep(ret?.seq, row?.seq)}>수정</button>
                                      <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => rep_del(ret?.seq, row?.seq)}>삭제</button>
                                    </p>
                                  </div>
                                  <div className="re_comment_txt pt-2">
                                    <span id={`rep_content${row?.seq}`} dangerouslySetInnerHTML={{__html: row?.content?.replaceAll(/\n/g, "\n<br/>")}}></span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="text-center" style={{verticalAlign: "top"}}>{ret?.input_date?.replaceAll('-', '.')?.substring(2)}</td>
                        <td className="text-center" style={{verticalAlign: "top"}}>{ret?.manager_name}</td>
                        <td className="text-center" style={{verticalAlign: "top"}}>
                          <button type="button" className="btn btn-ssm btn-outline-dark"
                                  onClick={() => {
                                    if (!$('#qna_auth_code').val()) {
                                      alert('인증번호를 입력해 주세요.');
                                      return;
                                    }
                                    $('#modal5_qna_auth_code').val($('#qna_auth_code').val());
                                    $('#modal5_base_seq').val(btoa(ret?.seq));
                                    setModal4Open(false);
                                    setModal5Open(true);
                                  }}
                          >수정
                          </button>
                        </td>
                      </tr>
                    )}
                    {(!modal4Result?.result || modal4Result?.result?.length === 0) &&
                      <tr>
                        <td colSpan={7} className="text-center">※ 등록된 data가 없습니다.</td>
                      </tr>
                    }
                  </table>
                </div>

                <nav className="p-3 mb-3 d-flex justify-content-between border-top flex-wrap">
                  <Paging pageInfo={modal4PageInfo} setCurrPage={page => setModal4CurrPage(page)}/>
                  <div>
                    <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => qna_all_del()}>삭제</button>
                    <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModal4Open(false)}>닫기</button>
                  </div>
                </nav>
              </div>
            </div>
          </Modal>


          <Modal
            isOpen={modal5Open}
            style={{
              modal: {
                backgroundColor: 'transparent',
                margin: 0,
                padding: 0,
                alignItems: undefined,
                justifyContent: undefined,
              },
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'transparent'
              }
            }}
            onAfterOpen={() => {
              Fn.centerModal();
              loadModal5();
            }}
            onRequestClose={() => setModal5Open(false)}
            ariaHideApp={false}
          >
            <div className="pop_background" style={{zIndex: 1000000000}}>
              <div className="pop_box01 p_absolute_mid_pop" style={{minWidth: 1200, maxWidth: 1200}}>
                <div className="POP_title">
                  <h5><strong>질문게시판</strong></h5>
                  <p className="closed">
                    <button type="button" className="btn" onClick={() => setModal5Open(false)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                      </svg>
                    </button>
                  </p>
                </div>
                <div className="POP_cnt_box w-100">
                  <div className="mb-3 d-flex flex-nowrap">
                    <table className="view-rounded sub_view text-left">
                      <colgroup>
                        <col width="120px"/>
                        <col width="*"/>
                      </colgroup>
                      <tr>
                        <th>제목</th>
                        <td>
                          <input type="text" id="modal5_title"/>
                        </td>
                      </tr>
                      <tr>
                        <th>내용</th>
                        <td>
                          <CKEditor editor={DecoupledEditor} data={qnaContent}
                                    config={{
                                      extraPlugins: [uploadPlugin]
                                    }}
                                    onReady={(editor) => {
                                      editor.ui.getEditableElement().parentElement.insertBefore(
                                        editor.ui.view.toolbar.element,
                                        editor.ui.getEditableElement()
                                      );
                                      editor.editing.view.change((writer) => {
                                        writer.setStyle(
                                          "height",
                                          "350px",
                                          editor.editing.view.document.getRoot()
                                        );
                                      });
                                    }}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      setQnaContent(data);
                                    }}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="text-center mb-3">
                  <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => qna_go_submit()}>저장</button>
                  <button type="button" className="btn btn-sm btn-ligray px-3"
                          onClick={() => {
                            setModal5Open(false);
                            setModal4Open(true);
                          }}
                  >목록
                  </button>
                </div>
              </div>
            </div>

            <input type='hidden' id='modal5_manager_seq'/>
            <input type='hidden' id='modal5_manager_name'/>

          </Modal>

          <input type="hidden" id="at_chk_YN" value="N"/>
          <input type="hidden" id="at_base_new_seq"/>
          <input type="hidden" id="modal5_base_seq"/>
          <input type="hidden" id="modal5_qna_auth_code"/>

        </main>
      </div>
    </div>
  );
};

export default CmnCard;
