import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import searchIcon from "../../images/search.svg";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import {useHistory, useLocation} from "react-router-dom";
import JDatePicker from "../common/JDatePicker";
import MvChangeCenter from "./MvChangeCenter";
import MvChangeStartDate from "./MvChangeStartDate";
import MvAppInsert from "./MvAppInsert";
import MvRepuCard from "./MvRepuCard";
import MvInputInsert from "./MvInputInsert";
import MvDelayInsert from "./MvDelayInsert";
import MvRefundCal from "./MvRefundCal";
import MvBlackListReg from "./MvBlackListReg";
import MvPointList from "./MvPointList";
import MvChangePoint from "./MvChangePoint";
import MvGiftUse from "./MvGiftUse";
import MvAttend from "./MvAttend";
import MvChangeBrand from "./MvChangeBrand";
import MvChangePeriod from "./MvChangePeriod";
import MvChangeLockerPeriod from "./MvChangeLockerPeriod";
import MvChangeSuitPeriod from "./MvChangeSuitPeriod";
import MvService from "./MvService";
import MvAllIpkum from "./MvAllIpkum";
import MvAllServiceIpkum from "./MvAllServiceIpkum";
import ChkAuthNum from "../common/ChkAuthNum";
import MvIntroList from "./MvIntroList";
import MvHistory from "./MvHistory";
import Constants from "../../constants/constants";
import MvMembership from "./MvMembership";
import PostCode from "../common/PostCode";
import SelectLocker from "../common/SelectLocker";
import MvEditIpkum from "./MvEditIpkum";
import MvServicePeriod from "./MvServicePeriod";
import CashRequest from "./CashRequest";
import CardCancel from "./CardCancel";
import MvCashCancel from "./MvCashCancel";
import MvServiceDateChange from "./MvServiceDateChange";
import MvIpkumDateChange from "./MvIpkumDateChange";
import MvRegStepEdit from "./MvRegStepEdit";

const MemberViewDetail = (props) => {
  const newVer = !(localStorage.getItem("USER.systemID") == "futureinfo" || localStorage.getItem("USER.systemID") == "tpwls8905" || Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 9);

  window._load_mv = () => {
    try {
      history.replace({state: {}});
      /*
      $('#search_txt').val('');
      $('#s_mb_no').val('');
      $('#auth_code').val('');
      $('#service_state_form')[0].reset();
      setMbNo('');
      setTimeout(() => {
        load();
        search('__NONE__', "__NONE__");
        $('#search_txt').focus();
        $('#info_area').hide();
      }, 100);
      */
    } catch (e) {
      console.log(e);
    }
  }

  const location = useLocation();
  const history = useHistory();
  const sMbNo = location?.state?.mbNo || '';

  const [mbNo, setMbNo] = useState(location?.state?.mbNo || '');

  const [sResult, setSResult] = useState({});
  const [lResult, setLResult] = useState({});
  const [addRows, setAddRows] = React.useState([]);

  const [renew, setRenew] = useState(0);

  const [isMvMembershipOpen, setIsMvMembershipOpen] = useState(false);
  const [isMvChangeCenterOpen, setIsMvChangeCenterOpen] = useState(false);
  const [isMvChangeStartDateOpen, setIsMvChangeStartDateOpen] = useState(false);
  const [isMvAppInsert, setIsMvAppInsert] = useState(false);
  const [isMvRepuCard, setIsMvRepuCard] = useState(false);
  const [isMvInputInsert, setIsMvInputInsert] = useState(false);
  const [isMvDelayInsert, setIsMvDelayInsert] = useState(false);
  const [isMvRefundCal, setIsMvRefundCal] = useState(false);
  const [isMvBlackListReg, setIsMvBlackListReg] = useState(false);
  const [isMvPointList, setIsMvPointList] = useState(false);
  const [isMvChangePoint, setIsMvChangePoint] = useState(false);
  const [isMvGiftUse, setIsMvGiftUse] = useState(false);
  const [isMvAttend, setIsMvAttend] = useState(false);
  const [isMvChangeBrand, setIsMvChangeBrand] = useState(false);
  const [isMvChangePeriod, setIsMvChangePeriod] = useState(false);
  const [isMvChangeLockerPeriod, setIsMvChangeLockerPeriod] = useState(false);
  const [isMvChangeSuitPeriod, setIsMvChangeSuitPeriod] = useState(false);
  const [isMvService, setIsMvService] = useState(false);
  const [isMvAllIpkum, setIsMvAllIpkum] = useState(false);
  const [isMvAllServiceIpkum, setIsMvAllServiceIpkum] = useState(false);
  const [isMvIntroList, setIsMvIntroList] = useState(false);
  const [isMvHistory, setIsMvHistory] = useState(false);
  const [isPostCodeOpen, setPostCodeOpen] = useState(false);
  const [isCashRequestOpen, setCashRequestOpen] = useState(false);
  const [isCardCancelOpen, setCardCancelOpen] = useState(false);
  const [isCashCancelOpen, setCashCancelOpen] = useState(false);
  const [isLockerOpen, setIsLockerOpen] = useState(false);
  const [isMvEditIpkum, setIsMvEditIpkum] = useState(false);
  const [isMvServicePeriod, setIsMvServicePeriod] = useState(false);
  const [isServiceDateChange, setServiceDateChange] = useState(false);
  const [isRegStepEdit, setRegStepEdit] = useState(false);
  const [isIpkumDateChange, setIpkumDateChange] = useState(false);

  const [periodType, setPeriodType] = useState('');
  const [srchResult, setSrchResult] = useState([]);
  const [insertMode, setInsertMode] = useState(false);
  const [lockerFormType, setLockerFormType] = useState('service_state_form');

  let l_ap_date = [];
  let l_ap_amt = [];
  let T_amt = 0;
  let _T_amt = 0;
  let prevDate = "";

  useEffect(() => {
    $('#s_mb_no').val(sMbNo);
    $('#mb_no').val(sMbNo);

    $("body").click(function () {
      $("#search_result").fadeOut().hide();
    });
  }, [])

  const dispatch = () => {
    const _mbNo = $('#s_mb_no').val();
    setMbNo(_mbNo);
    load(_mbNo);
  }

  useEffect(() => {
    $('#search_auth_code, #auth_code, #reg_step').val('');
    $('#auth_code_name').html('');
    $('#_reg_step').val('');
  }, [mbNo]);

  useEffect(() => {
    dispatch();
  }, [renew]);

  const load = (_mbNo) => {
    axios2
      .post(`/member/member/view/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setLResult(result);

          if (_mbNo) {
            search(_mbNo);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const search = (mbNo = '', key = '') => {
    try {
      window.extendLogout();
    } catch (err) {
    }

    axios2
      .post(`/member/member/view?mb_no=${mbNo}&key=${encodeURIComponent(key)}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          l_ap_date = [];
          l_ap_amt = [];
          T_amt = 0;
          _T_amt = 0;
          prevDate = "";

          if (mbNo && mbNo != "__NONE__")
            $('#search_auth_code').focus();

          if (result?.memberInfos?.length > 1) {
            $('#search_result').show();
            setSrchResult(result.memberInfos || []);
            return;
          } else {
            if (result?.memberInfo?.mb_no > 0)
              setSResult(result);
            else
              setSResult({});
            $('#search_result').hide();
          }
          if (result?.memberInfos?.length == 1) {
            $('#search_auth_code').focus();
          }

          $('#btn_member_insert, #btn_member_update').hide();

          if (result?.memberInfo?.mb_no)
            $('#info_area').show();

          $('#s_mb_memo').html('');
          $('#s_member_id').html('');
          $('#s_member_point').html('');

          $('#s_mb_no').val(result?.memberInfo?.mb_no);
          $('#mb_no').val(result?.memberInfo?.mb_no);
          $('#s_mb_id').val(result?.memberInfo?.mb_id);
          $('#s_mb_name').val(result?.memberInfo?.mb_name);
          $('#s_mb_hp').val('');
          if (result?.memberInfo?.mb_hp?.replace(/-/gi, "") != "01000000000" && result?.memberInfo?.mb_hp?.replace(/-/gi, "")) {
            $('#s_mb_hp').attr('readOnly', true);
            $('#btn_show_phone').show();
          } else {
            $('#s_mb_hp').attr('readOnly', false);
            $('#btn_show_phone').hide();
          }

          $('#marketing_YN_1, #marketing_YN_2').prop("checked", false);
          if (result?.memberInfo?.marketing_YN === 'Y') $('#marketing_YN_1').prop("checked", true);
          else if (result?.memberInfo?.marketing_YN === 'N') $('#marketing_YN_2').prop("checked", true);

          $('#marketing_shop_YN_1, #marketing_shop_YN_2').prop("checked", false);
          if (result?.memberInfo?.marketing_shop_YN === 'Y') $('#marketing_shop_YN_1').prop("checked", true);
          else if (result?.memberInfo?.marketing_shop_YN === 'N') $('#marketing_shop_YN_2').prop("checked", true);

          if (result?.memberInfos?.length >= 1 || mbNo) {
            setInsertMode(false);
            $('#btn_member_update').show();
            $('#s_mb_id').removeAttr('readonly');
            if (newVer) {
              $('#info_area').show();
            }
          } else {
            if (!newVer) {
              if ($('#search_txt').val()) {
                $('#s_mb_id').val("Auto Make").attr('readonly', 'readonly');

                if ($('#search_txt').val().startsWith('01')) {
                  $('#s_mb_hp').val($('#search_txt').val());
                  $('#s_mb_hp').focus();
                } else {
                  $('#s_mb_name').val($('#search_txt').val());
                  $('#s_mb_name').focus();
                }
              }

              $('#marketing_YN_1').prop("checked", true);
              $('#marketing_shop_YN_1').prop("checked", true);

              setInsertMode(true);
              $('#btn_member_insert').show();
            } else {
              $('#info_area').hide();
              // if ($('#search_txt').val()) {
              //   $('#s_mb_id').val("Auto Make").attr('readonly', 'readonly');
              //
              //   if ($('#search_txt').val().startsWith('01')) {
              //     $('#s_mb_hp').val($('#search_txt').val());
              //     $('#s_mb_hp').focus();
              //   } else {
              //     $('#s_mb_name').val($('#search_txt').val());
              //     $('#s_mb_name').focus();
              //   }
              // }
              //
              // $('#marketing_YN_1').prop("checked", true);
              // $('#marketing_shop_YN_1').prop("checked", true);
              //
              // setInsertMode(true);
              // $('#btn_member_insert').show();
            }
          }

          const _mbNo = $('#s_mb_no').val();
          setMbNo(_mbNo);

          $('#s_mb_birth').val(result?.memberInfo?.mb_birth);

          $('#s_mb_sex_1, #s_mb_sex_2').prop("checked", false);
          if (result?.memberInfo?.mb_sex === 'M') $('#s_mb_sex_1').prop("checked", true);
          else if (result?.memberInfo?.mb_sex === 'F') $('#s_mb_sex_2').prop("checked", true);

          $('#s_addr_code').val(result?.memberInfo?.addr_code);
          $('#s_alim').val(result?.memberInfo?.alim);
          $('#s_mb_memo').html(result?.memberInfo?.mb_memo.replaceAll("\r", "<br/>"));
          $('#s_member_id').html(result?.memberInfo?.member_id);

          if (!$('#s_member_id').html()) $('#s_member_point').html("Point " + Fn.numberWithCommas(result?.memberInfo?.mb_point));
          else $('#s_member_point').html("<br/>Point " + Fn.numberWithCommas(result?.memberInfo?.mb_point));

          if (result?.memberInfo?.mb_sex === "M") $('#s_mb_sex_1').prop("checked", true);
          else if (result?.memberInfo?.mb_sex === "F") $('#s_mb_sex_2').prop("checked", true);

          $('#s_visit_course').val(result?.memberInfo?.visit_course);

          if (result?.bChkRowCnt) {
            $('#member_pic_view').css({'background-image': "url('http://cdc-smms.spoany.co.kr/image/member_thumb_b.png')", "background-size": "contain"});
          } else {
            if (result?.memberInfo?.mb_pic_smms)
              $('#member_pic_view').css({
                'background-image': "url('" + Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=customer_img&filename=" + encodeURIComponent(result?.memberInfo?.mb_pic_smms)) + "')",
                "background-size": "cover"
              });
            else
              $('#member_pic_view').css({'background-image': "url('http://cdc-smms.spoany.co.kr/image/member_thumb.png')", "background-size": "contain"});
          }

          if (result?.memberInfo?.suit == 'Y' || result?.memberInfo?.suit == '1') $('#suit1').prop("checked", true);
          else $('#suit2').prop("checked", true);


          let memberAbleTime = "";
          if (result?.memberInfo?.able_start_time && result?.memberInfo?.able_end_time) {
            memberAbleTime = `${result?.memberInfo?.able_start_time}~${result?.memberInfo?.able_end_time}`;
          } else {
            memberAbleTime = "-";
          }

          let memberAbleDay = "";

          // 가입경로와 주소 미입력시 하단 메뉴 하이드
          if (newVer) {
            if (!$('#s_visit_course option:selected')?.val()?.trim() || !$('#s_addr_code')?.val()?.trim()) {
              $('#info_area').hide();
            }
          }

          if (result?.memberInfo?.able_day) {
            const ableSplit = result?.memberInfo?.able_day?.split("|");

            if (ableSplit?.[0] === "Y") memberAbleDay += "월,";
            if (ableSplit?.[1] === "Y") memberAbleDay += "화,";
            if (ableSplit?.[2] === "Y") memberAbleDay += "수,";
            if (ableSplit?.[3] === "Y") memberAbleDay += "목,";
            if (ableSplit?.[4] === "Y") memberAbleDay += "금,";
            if (ableSplit?.[5] === "Y") memberAbleDay += "토,";
            if (ableSplit?.[6] === "Y") memberAbleDay += "일,";

            memberAbleDay += "*";
            memberAbleDay = memberAbleDay?.replace(",*", "");
          } else {
            memberAbleDay = "-";
          }

          $('#member_able_day').html(memberAbleDay);
          $('#member_able_time').html(memberAbleTime);


          if (result?.memberInfo?.locker == 0) $('#locker0').prop("checked", true);
          else if (result?.memberInfo?.locker == 1) $('#locker1').prop("checked", true);
          else if (result?.memberInfo?.locker == 2) $('#locker2').prop("checked", true);
          else if (result?.memberInfo?.locker == 3) $('#locker3').prop("checked", true);

          $('#locker_no').val(result?.memberInfo?.locker_no);
          $('#locker_name').val(result?.memberInfo?.locker_name);
          $('#locker_pass').val(result?.memberInfo?.locker_pass);
          $('#locker_start_date').val(result?.memberInfo?.locker_start_date);
          $('#locker_end_date').val(result?.memberInfo?.locker_end_date);

          $('#suit_start_date').val(result?.memberInfo?.suit_start_date);
          $('#suit_end_date').val(result?.memberInfo?.suit_end_date);

          $('#start_date').val(result?.memberInfo?.start_date);
          $('#end_date').val(result?.memberInfo?.end_date);

          if (result?.memberInfo?.dub_able === "Y") $('#Ydub_able').prop("checked", true);
          else $('#Ndub_able').prop("checked", true);

          if (result.notUse && result?.notUse.length > 0) {
            if (result?.notUse?.[0]?.de_sDate && result?.notUse?.[0]?.de_eDate) {
              $('#not_use_date').show();
              const formattedStartDate = result?.notUse?.[0]?.de_sDate.slice(2, 10);
              const formattedEndDate = result?.notUse?.[0]?.de_eDate.slice(2, 10);

              if (result?.notUse?.[0]?.delay_type === "A") {
                $('#not_use_date').html(`휴회기간: ${formattedStartDate} ~ ${formattedEndDate} <button type="button" class="btn btn-ssm btn-dark" onclick="window.delay_del('${result?.notUse?.[0]?.de_no}', '${result?.memberInfo?.mb_no}')">삭제</button><br>`);
              } else {
                $('#not_use_date').html(`휴회기간: ${formattedStartDate} ~ ${formattedEndDate} <br>`);
              }
            } else {
              $('#not_use_date').hide();
            }
          } else {
            $('#not_use_date').hide();
          }

          show_locker();
          show_suit();


          const _addRows = [];

          if (result?.bOptRow?.opt1 == "Y") {
            _addRows.push(<th>골프</th>);
            _addRows.push(<td className="text-left">
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="opt1" id="golf1" value="1" onClick={() => show_golf()}/>
                <label className="form-check-label" htmlFor="golf1">
                  유
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="opt1" id="golf2" value="0" onClick={() => show_golf()}/>
                <label className="form-check-label" htmlFor="golf2">
                  무
                </label>
              </div>

              <div className="input-group" id="golf_date_info" style={{marginTop: "8px"}}>
                <JDatePicker className="form-control" aria-describedby="button-addon2" name="etc3_start_date" id="etc3_start_date" value={sResult?.memberInfo?.etc3_start_date} readOnly/>
                <p className="mx-2">~</p>
                <JDatePicker className="form-control" aria-describedby="button-addon2" name="etc3_end_date" id="etc3_end_date" value={sResult?.memberInfo?.etc3_end_date} readOnly/>
                <button type="button" className="btn btn-ssm btn-dark mx-1" onClick={() => {
                  if ($('#mb_no').val() == "") {
                    alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                    return;
                  }
                  if ($('#auth_code').val() == "") {
                    alert('인증코드를 입력해주세요');
                    $('#search_auth_code').focus();
                    return;
                  }

                  setPeriodType('golf');
                  setIsMvChangePeriod(true);
                }}>
                  변경
                </button>
              </div>
            </td>);
          }

          if (result?.bOptRow?.opt2 == "Y") {
            _addRows.push(<th>골프라커</th>);
            _addRows.push(
              <td className="text-left">
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="g_locker" id="g_locker1" value="1" onClick={() => show_g_locker()}/>
                  <label className="form-check-label" htmlFor="g_locker1">
                    유
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="g_locker" id="g_locker0" value="0" onClick={() => show_g_locker()}/>
                  <label className="form-check-label" htmlFor="g_locker0">
                    무
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="g_locker" id="g_locker2" value="2" onClick={() => show_g_locker()}/>
                  <label className="form-check-label" htmlFor="g_locker2">
                    예약
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="g_locker" id="g_locker3" value="3" onClick={() => show_g_locker()}/>
                  <label className="form-check-label" htmlFor="g_locker3">
                    미지급
                  </label>
                </div>

                <div className="d-flex g_locker_info_area" style={{marginTop: "8px"}}>
                  <div className="input-group mr-2" style={{width: "auto"}} id="g_locker_no_info">
                    <input type="hidden" name="g_locker_no" id="g_locker_no"/>
                    {/*<input type="text" className="form-control2 table-inline" aria-describedby="button-addon2" style={{width: "150px"}} id="g_locker_name" name="g_locker_name" onClick={() => setIsGLockerOpen(true)}/>*/}
                    <input type="text" className="form-control2 table-inline" aria-describedby="button-addon2" style={{width: "150px"}} id="g_locker_name" name="g_locker_name" readOnly={true} onClick={() => {
                      setLockerFormType("g_service_state_form");
                      setIsLockerOpen(true);
                    }}/>
                    <input type="text" className="form-white table-inline" aria-describedby="button-addon2" style={{width: "70px"}} id="g_locker_pass" name="g_locker_pass"/>
                  </div>
                  <div className="input-group" style={{width: "auto"}} id="g_locker_date_info">
                    <JDatePicker className="form-control" aria-describedby="button-addon2" id="g_locker_start_date" name="g_locker_start_date" readOnly/>
                    <p className="mx-2">~</p>
                    <JDatePicker className="form-control" aria-describedby="button-addon2" id="g_locker_end_date" name="g_locker_end_date" readOnly/>
                    <button type="button" className="btn btn-ssm btn-dark mx-1" onClick={() => {
                      if ($('#mb_no').val() == "") {
                        alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                        return;
                      }
                      if ($('#auth_code').val() == "") {
                        alert('인증코드를 입력해주세요');
                        $('#search_auth_code').focus();
                        return;
                      }

                      setPeriodType('g_locker');
                      setIsMvChangePeriod(true);
                    }}>
                      변경
                    </button>
                  </div>
                </div>
              </td>
            );
          }

          if (result?.bOptRow?.opt3 == "Y") {
            _addRows.push(<th>스쿼시</th>);
            _addRows.push(<td className="text-left">
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="opt3" id="opt31" value="1" onClick={() => show_sque()}/>
                <label className="form-check-label" htmlFor="opt31">
                  유
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="opt3" id="opt32" value="0" onClick={() => show_sque()}/>
                <label className="form-check-label" htmlFor="opt32">
                  무
                </label>
              </div>

              <div className="input-group" id="opt3_date_info" style={{marginTop: "8px"}}>
                <JDatePicker className="form-control" aria-describedby="button-addon2" name="etc1_start_date" id="etc1_start_date" value={sResult?.memberInfo?.etc1_start_date} readOnly/>
                <p className="mx-2">~</p>
                <JDatePicker className="form-control" aria-describedby="button-addon2" name="etc1_end_date" id="etc1_end_date" value={sResult?.memberInfo?.etc1_end_date} readOnly/>
                <button type="button" className="btn btn-ssm btn-dark mx-1" onClick={() => {
                  if ($('#mb_no').val() == "") {
                    alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                    return;
                  }
                  if ($('#auth_code').val() == "") {
                    alert('인증코드를 입력해주세요');
                    $('#search_auth_code').focus();
                    return;
                  }

                  setPeriodType('sque');
                  setIsMvChangePeriod(true);
                }}>
                  변경
                </button>
              </div>
            </td>);
          }

          if (result?.bOptRow?.opt4 == "Y") {
            _addRows.push(<th>스피닝</th>);
            _addRows.push(<td className="text-left">
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="opt4" id="opt41" value="1" onClick={() => show_spin()}/>
                <label className="form-check-label" htmlFor="opt41">
                  유
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="opt4" id="opt42" value="0" onClick={() => show_spin()}/>
                <label className="form-check-label" htmlFor="opt42">
                  무
                </label>
              </div>

              <div className="input-group" id="opt4_date_info" style={{marginTop: "8px"}}>
                <JDatePicker className="form-control" aria-describedby="button-addon2" name="etc2_start_date" id="etc2_start_date" value={sResult?.memberInfo?.etc2_start_date} readOnly/>
                <p className="mx-2">~</p>
                <JDatePicker className="form-control" aria-describedby="button-addon2" name="etc2_end_date" id="etc2_end_date" value={sResult?.memberInfo?.etc2_end_date} readOnly/>
                <button type="button" className="btn btn-ssm btn-dark mx-1" onClick={() => {
                  if ($('#mb_no').val() == "") {
                    alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                    return;
                  }
                  if ($('#auth_code').val() == "") {
                    alert('인증코드를 입력해주세요');
                    $('#search_auth_code').focus();
                    return;
                  }

                  setPeriodType('spin');
                  setIsMvChangePeriod(true);
                }}>
                  변경
                </button>
              </div>
            </td>);
          }

          if (result?.bOptRow?.opt5 == "Y") {
            _addRows.push(<th>태닝</th>);
            _addRows.push(<td className="text-left">
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="opt5" id="opt51" value="1" onClick={() => show_tae()}/>
                <label className="form-check-label" htmlFor="opt51">
                  유
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="opt5" id="opt52" value="0" onClick={() => show_tae()}/>
                <label className="form-check-label" htmlFor="opt52">
                  무
                </label>
              </div>

              <div className="input-group" id="opt5_date_info" style={{marginTop: "8px"}}>
                <JDatePicker className="form-control" aria-describedby="button-addon2" name="etc4_start_date" id="etc4_start_date" value={sResult?.memberInfo?.etc4_start_date} readOnly/>
                <p className="mx-2">~</p>
                <JDatePicker className="form-control" aria-describedby="button-addon2" name="etc4_end_date" id="etc4_end_date" value={sResult?.memberInfo?.etc4_end_date} readOnly/>
                <button type="button" className="btn btn-ssm btn-dark mx-1" onClick={() => {
                  if ($('#mb_no').val() == "") {
                    alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                    return;
                  }
                  if ($('#auth_code').val() == "") {
                    alert('인증코드를 입력해주세요');
                    $('#search_auth_code').focus();
                    return;
                  }

                  setPeriodType('tae');
                  setIsMvChangePeriod(true);
                }}>
                  변경
                </button>
              </div>
            </td>);
          }

          if (result?.bOptRow?.opt7 == "Y") {
            _addRows.push(<th>필라테스</th>);
            _addRows.push(<td className="text-left">
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="opt7" id="opt71" value="1" onClick={() => show_pila()}/>
                <label className="form-check-label" htmlFor="opt71">
                  유
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="opt7" id="opt72" value="0" onClick={() => show_pila()}/>
                <label className="form-check-label" htmlFor="opt72">
                  무
                </label>
              </div>

              <div className="input-group" id="opt7_date_info" style={{marginTop: "8px"}}>
                <JDatePicker className="form-control" aria-describedby="button-addon2" name="etc5_start_date" id="etc5_start_date" value={sResult?.memberInfo?.etc5_start_date} readOnly/>
                <p className="mx-2">~</p>
                <JDatePicker className="form-control" aria-describedby="button-addon2" name="etc5_end_date" id="etc5_end_date" value={sResult?.memberInfo?.etc5_end_date} readOnly/>
                <button type="button" className="btn btn-ssm btn-dark mx-1" onClick={() => {
                  if ($('#mb_no').val() == "") {
                    alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                    return;
                  }
                  if ($('#auth_code').val() == "") {
                    alert('인증코드를 입력해주세요');
                    $('#search_auth_code').focus();
                    return;
                  }

                  setPeriodType('pila');
                  setIsMvChangePeriod(true);
                }}>
                  변경
                </button>
              </div>
            </td>);
          }

          setAddRows(_addRows);

          setTimeout(() => {
            try {
              if (result?.memberInfo?.opt1 == "Y" || result?.memberInfo?.opt1 == 1) $('#golf1').prop('checked', true);
              else $('#golf2').prop('checked', true);
              show_golf();
            } catch (err) {
            }

            try {
              if (result?.memberInfo?.g_locker == 0) $('#g_locker0').prop('checked', true);
              else if (result?.memberInfo?.g_locker == 1) $('#g_locker1').prop('checked', true);
              else if (result?.memberInfo?.g_locker == 2) $('#g_locker2').prop('checked', true);
              else if (result?.memberInfo?.g_locker == 3) $('#g_locker3').prop('checked', true);
              show_g_locker();
            } catch (err) {
            }

            try {
              if (result?.memberInfo?.opt3 == "Y" || result?.memberInfo?.opt3 == 1) $('#opt31').prop('checked', true);
              else $('#opt32').prop('checked', true);
              show_sque();
            } catch (err) {
            }

            try {
              if (result?.memberInfo?.opt4 == "Y" || result?.memberInfo?.opt4 == 1) $('#opt41').prop('checked', true);
              else $('#opt42').prop('checked', true);
              show_spin();
            } catch (err) {
            }

            try {
              if (result?.memberInfo?.opt5 == "Y" || result?.memberInfo?.opt5 == 1) $('#opt51').prop('checked', true);
              else $('#opt52').prop('checked', true);
              show_tae();
            } catch (err) {
            }

            try {
              if (result?.memberInfo?.opt7 == "Y" || result?.memberInfo?.opt7 == 1) $('#opt71').prop('checked', true);
              else $('#opt72').prop('checked', true);
              show_pila();
            } catch (err) {
            }

            try {
              $('#g_locker_no').val(result?.memberInfo?.g_locker_no);
              $('#g_locker_name').val(result?.memberInfo?.g_locker_name);
              $('#g_locker_pass').val(result?.memberInfo?.g_locker_pass);
              $('#g_locker_start_date').val(result?.memberInfo?.g_locker_start_date);
              $('#g_locker_end_date').val(result?.memberInfo?.g_locker_end_date);
            } catch (err) {
            }

            try {
              $('#etc1_start_date').val(result?.memberInfo?.etc1_start_date);
              $('#etc1_end_date').val(result?.memberInfo?.etc1_end_date);
            } catch (err) {
            }

            try {
              $('#etc2_start_date').val(result?.memberInfo?.etc2_start_date);
              $('#etc2_end_date').val(result?.memberInfo?.etc2_end_date);
            } catch (err) {
            }

            try {
              $('#etc3_start_date').val(result?.memberInfo?.etc3_start_date);
              $('#etc3_end_date').val(result?.memberInfo?.etc3_end_date);
            } catch (err) {
            }

            try {
              $('#etc4_start_date').val(result?.memberInfo?.etc4_start_date);
              $('#etc4_end_date').val(result?.memberInfo?.etc4_end_date);
            } catch (err) {
            }

            try {
              $('#etc5_start_date').val(result?.memberInfo?.etc5_start_date);
              $('#etc5_end_date').val(result?.memberInfo?.etc5_end_date);
            } catch (err) {
            }
          }, 150);
        }
      })
      .catch((error) => {
        console.log(error);
        if (localStorage?.getItem("USER.systemID") == "mickeykkang") {
          if (!window.disableErrorMsg) alert('데이터오류! 본 화면을 캡쳐하여 관리자에게 전송해 주세요.\n\n세부사항 : ' + error.toString());
        } else {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        }
      });
  }

  function calculateSum(array, property) {
    const total = array.reduce((accumulator, object) => {
      return accumulator + object[property];
    }, 0);
  }

  //등록 서비스 삭제
  function sevice_del(ap_no, mb_no, it_name) {
    if ($('#mb_no').val() == "") {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) < 9) {
      if (it_name?.startsWith("[CU행사]") || it_name?.startsWith("오케어") || it_name?.includes("이용권") || it_name?.toLowerCase()?.includes("b2b")) {
        alert("이용권 서비스 신청 내역은 관리자만 삭제할 수 있습니다.");
        return;
      }
    }

    if ($('#auth_code').val()) {
      if (window.confirm('등록된 서비스를 삭제하시겠습니까?')) {
        axios2
          .post("/member/service/del?ap_no=" + ap_no + "&mb_no=" + mb_no + "&auth_code=" + $('#auth_code').val() + "&receive_name=" + ($('#_reg_step option:selected').val() || ''))
          .then((response) => {
            alert("삭제되었습니다");
            setRenew(Fn.getRenewTime());
          })
          .catch((error) => {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          });
      }
    } else {
      alert('인증코드를 입력해주세요');
      $('#search_auth_code').focus();
    }
  }

  //등록 서비스 기간 설정
  function service_period_setting(ap_date, mb_no) {
    if ($('#mb_no').val() == "") {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if ($('#auth_code').val()) {
      $('#edit_ap_date').val(ap_date);
      setIsMvServicePeriod(true);
    } else {
      alert('인증코드를 입력해주세요');
      $('#search_auth_code').focus();
    }
  }

  //입금내역 삭제
  window.ipkum_del = (base_ip_no, mb_no) => {
    if ($('#mb_no').val() == "") {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if ($('#auth_code').val()) {
      if (window.confirm('등록된 입금내역을 삭제하시겠습니까?')) {
        axios2
          .post("/member/ipkum/del?base_ip_no=" + base_ip_no + "&mb_no=" + mb_no + "&auth_code=" + $('#auth_code').val() + "&receive_name=" + ($('#_reg_step option:selected').val() || ''))
          .then((response) => {
            alert("삭제되었습니다");
            setRenew(Fn.getRenewTime());
          })
          .catch((error) => {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          });
      }
    } else {
      alert('인증코드를 입력해주세요');
      $('#search_auth_code').focus();
    }
  }

  //입금내역 수정
  window.ipkum_edit = (base_ip_no, mb_no) => {
    if ($('#mb_no').val() == "") {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if ($('#auth_code').val()) {
      $('#edit_ipkum_ip_no').val(atob(base_ip_no));
      setIsMvEditIpkum(true);
    } else {
      alert('인증코드를 입력해주세요');
      $('#search_auth_code').focus();
    }
  }

  window.show_phone = (mb_no) => {
    var auth_code = $('#auth_code').val();

    if (!auth_code) {
      alert("인증코드를 입력해주세요");
      return;
    }

    $.ajax({
      type: "post",
      url: "/member/show/hp",
      data: "mb_no=" + mb_no + "&auth_code=" + auth_code,
      success: function (data) {
        data = data?.message?.trim() || '';

        $("#s_mb_hp").val(data);
        $("#s_mb_hp").attr('readOnly', false);
      },
      error: function (e) {
        alert('데이터오류! 시스템 관리자에게 문의하세요');
      }
    });
  }

  //연기내역 삭제
  window.delay_del = (de_no, mb_no) => {
    if ($('#mb_no').val() == "" || !mb_no) {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if ($('#auth_code').val()) {
      if (window.confirm('등록된 연기내역을 삭제하시겠습니까?')) {
        axios2
          .post("/member/delay/save?type=del&de_no=" + de_no + "&mb_no=" + mb_no + "&auth_code=" + $('#auth_code').val() + "&receive_name=" + ($('#_reg_step option:selected').val() || ''))
          .then((response) => {
            alert("연기내역이 삭제되었습니다");
            setRenew(Fn.getRenewTime());
          })
          .catch((error) => {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          });
      }
    } else {
      alert('인증코드를 입력해주세요');
      $('#search_auth_code').focus();
    }
  }

  function edit_member() {
    if ($('#mb_no').val() == "") {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if ($('#auth_code').val() == '') {
      alert('인증코드를 입력해주세요');
      return;
    }
    if ($('#reg_step').value == '') {
      alert('접수자를 선택해주세요');
      $('#reg_step').focus();
      return;
    }
    if ($('#mb_id').val() == '') {
      alert('회원번호를 입력해주세요');
      $('#mb_id').focus();
      return;
    }
    if ($('#s_mb_name').val() == '') {
      alert('이름을 입력해주세요');
      $('#s_mb_name').focus();
      return;
    }
    if ($('#marketing_YN_1')[0].checked == false && $('#marketing_YN_2')[0].checked == false) {
      alert("마케팅 활용동의를 선택해주세요");
      return;
    }

    if ($('#marketing_shop_YN_1')[0].checked == false && $('#marketing_shop_YN_2')[0].checked == false) {
      alert("스포애니몰 동의를 선택해주세요");
      return;
    }

    if (!$('#edit_memo').val()) {
      alert('수정메모를 입력해주세요');
      $('#edit_memo').focus();
      return;
    }

    const formData = new FormData();

    formData.append("mb_id", $('#s_mb_id').val());
    formData.append("mb_name", $('#s_mb_name').val());
    formData.append("mb_hp", $('#s_mb_hp').val());
    formData.append("mb_birth", $('#s_mb_birth').val());
    if ($('#s_mb_sex_1').is(':checked'))
      formData.append("mb_sex", "M");
    else if ($('#s_mb_sex_2').is(':checked'))
      formData.append("mb_sex", "F");
    else
      formData.append("mb_sex", "");
    formData.append("visit_course", $('#s_visit_course option:selected').val());
    formData.append("addr_code", $('#s_addr_code').val());
    formData.append("alim", $('#s_alim').val());
    formData.append("insert_memo", $('#insert_memo').val() || '');
    formData.append("edit_memo", $('#edit_memo').val() || '');

    formData.append("mb_no", $('#s_mb_no').val() || '');
    formData.append("edit_yn", "Y");
    formData.append("del_pic", "N");
    formData.append("marketing_YN", $('#marketing_YN_1')[0].checked ? 'Y' : 'N');
    formData.append("marketing_shop_YN", $('#marketing_shop_YN_1')[0].checked ? 'Y' : 'N');
    formData.append("vacc_YN", $('#vacc_YN_1')?.[0]?.checked ? 'Y' : 'N'); // TODO
    formData.append("country_code", $('#country_code').val() || ''); // TODO
    formData.append("mb_memo", $('#mb_memo').val() || ''); // TODO
    formData.append("finger", $('#finger').val() || ''); // TODO
    formData.append("auth_code", $('#auth_code').val() || '');
    formData.append("beforeMarketingYN", sResult?.memberInfo?.marketing_YN || '');
    formData.append("beforeMarketingShopYN", sResult?.memberInfo?.marketing_shop_YN_step || '');
    // formData.append("hi_mb_hp", sResult?.memberInfo?.marketing_shop_YN_step || '');
    if ($('#mb_pic_file').val())
      formData.append('userfile', $('#mb_pic_file')[0].files[0]);

    axios2.postFormEx('/member/member/save', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        data = data.trim();
        let split = data.split("||");
        let re_data = split[0];
        let mb_no = split[1];

        if (re_data == "wrong_birth") {
          alert('생일이 잘못입력되었습니다');
          return;
        } else if (re_data == "dup_member") {
          alert('기존 데이터가 존재합니다');
          return;
        } else if (re_data == "dup_hp") {
          alert('동일한 연락처가 존재합니다');
          return;
        } else if (re_data == "no_pic") {
          alert('카드번호 변경시 사진은 필수로 입력해야 합니다.');
          return;
        } else if (re_data == "insert_err") {
          alert('수정오류! 다시 등록해주세요');
          // setRenew(Fn.getRenewTime());
        } else if (re_data == "insert_ok") {
          alert('수정되었습니다');
          // location.href = 'member_view.html?temp_mb_no3=' + mb_no
          $('#mb_pic_form')[0].reset();
          $('#insert_memo, #edit_memo').val('');
          setRenew(Fn.getRenewTime());
        } else if (re_data == "err") {
          alert('수정오류!! 다시 등록해주세요');
          // setRenew(Fn.getRenewTime());
          // parent.location.href = "member_view.html";
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
          // parent.location.href = "member_view.html";
          // setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  let is_processing = false;

  function insert_member() {
    if (is_processing) return;
    is_processing = false;

    $('#btn_member_insert').css("display", "none").attr("disabled", true);

    setTimeout(() => {
      if ($('#auth_code').val() == '') {
        alert('인증코드를 입력해주세요');
        setTimeout(() => $('#btn_member_insert').show().attr("disabled", false), 250);
        return;
      }
      if ($('#reg_step').value == '') {
        alert('접수자를 선택해주세요');
        $('#reg_step').focus();
        setTimeout(() => $('#btn_member_insert').show().attr("disabled", false), 250);
        return;
      }
      if ($('#mb_id').val() == '') {
        alert('회원번호를 입력해주세요');
        $('#mb_id').focus();
        return;
      }
      if ($('#s_mb_name').val() == '') {
        alert('이름을 입력해주세요');
        $('#s_mb_name').focus();
        return;
      }
      if ($('#s_mb_hp').val() == '') {
        alert('연락처를 입력해주세요');
        $('#s_mb_hp').focus();
        return;
      }
      if ($('#s_mb_birth').val() == '') {
        alert('생일을 입력해주세요');
        $('#s_mb_birth').focus();
        return;
      }
      if ($('#s_visit_course option:selected').val() == '') {
        alert('가입경로를 입력해주세요');
        $('#s_visit_course').focus();
        return;
      }
      if (!$("input[name='s_mb_sex']:checked").val()) {
        alert('성별을 입력해주세요');
        return;
      }
      if ($('#s_addr_code').val() == '') {
        alert('주소를 입력해주세요');
        $('#s_addr_code').focus();
        return;
      }
      if ($('#marketing_YN_1')[0].checked == false && $('#marketing_YN_2')[0].checked == false) {
        alert("마케팅 활용동의를 선택해주세요");
        return;
      }

      if ($('#marketing_shop_YN_1')[0].checked == false && $('#marketing_shop_YN_2')[0].checked == false) {
        alert("스포애니몰 동의를 선택해주세요");
        return;
      }

      // if (!$('#edit_memo').val()) {
      //   alert('등록메모를 입력해주세요');
      //   $('#edit_memo').focus();
      //   return;
      // }

      const formData = new FormData();

      formData.append("mb_id", $('#s_mb_id').val());
      formData.append("mb_name", $('#s_mb_name').val());
      formData.append("mb_hp", $('#s_mb_hp').val());
      formData.append("mb_birth", $('#s_mb_birth').val());
      if ($('#s_mb_sex_1').is(':checked'))
        formData.append("mb_sex", "M");
      else if ($('#s_mb_sex_2').is(':checked'))
        formData.append("mb_sex", "F");
      else
        formData.append("mb_sex", "");
      formData.append("visit_course", $('#s_visit_course option:selected').val());
      formData.append("addr_code", $('#s_addr_code').val());
      formData.append("alim", $('#s_alim').val());
      formData.append("insert_memo", $('#insert_memo').val() || '');
      formData.append("edit_memo", $('#edit_memo').val() || '');

      formData.append("mb_no", $('#s_mb_no').val() || '');
      formData.append("edit_yn", "N");
      formData.append("del_pic", "N");
      formData.append("marketing_YN", $('#marketing_YN_1')[0].checked ? 'Y' : 'N');
      formData.append("marketing_shop_YN", $('#marketing_shop_YN_1')[0].checked ? 'Y' : 'N');
      formData.append("vacc_YN", $('#vacc_YN_1')?.[0]?.checked ? 'Y' : 'N'); // TODO
      formData.append("country_code", $('#country_code').val() || ''); // TODO
      formData.append("mb_memo", $('#mb_memo').val() || ''); // TODO
      formData.append("finger", $('#finger').val() || ''); // TODO
      formData.append("auth_code", $('#auth_code').val() || '');
      formData.append("beforeMarketingYN", sResult?.memberInfo?.marketing_YN || '');
      formData.append("beforeMarketingShopYN", sResult?.memberInfo?.marketing_shop_YN_step || '');
      // formData.append("hi_mb_hp", sResult?.memberInfo?.marketing_shop_YN_step || '');
      if ($('#mb_pic_file').val())
        formData.append('userfile', $('#mb_pic_file')[0].files[0]);

      is_processing = true;
      axios2.postFormEx('/member/member/save', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          let split = data.split("||");
          let re_data = split[0];
          let mb_no = split[1];

          if (re_data == "wrong_birth") {
            alert('생일이 잘못입력되었습니다');
            is_processing = false;
            setTimeout(() => $('#btn_member_insert').show().attr("disabled", false), 250);
          } else if (re_data == "dup_member") {
            alert('기존 데이터가 존재합니다');
            is_processing = false;
            setTimeout(() => $('#btn_member_insert').show().attr("disabled", false), 250);
          } else if (re_data == "dup_hp") {
            alert('동일한 연락처가 존재합니다');
            is_processing = false;
            setTimeout(() => $('#btn_member_insert').show().attr("disabled", false), 250);
          } else if (re_data == "no_pic") {
            alert('카드번호 변경시 사진은 필수로 입력해야 합니다.');
            is_processing = false;
            setTimeout(() => $('#btn_member_insert').show().attr("disabled", false), 250);
          } else if (re_data == "insert_err") {
            alert('등록오류! 다시 등록해주세요');
            is_processing = false;
            setTimeout(() => $('#btn_member_insert').show().attr("disabled", false), 250);
            // setRenew(Fn.getRenewTime());
          } else if (re_data == "insert_ok") {
            alert('등록되었습니다');
            is_processing = false;
            setTimeout(() => $('#btn_member_insert').show().attr("disabled", false), 250);

            $('#s_mb_no').val(mb_no);
            $('#mb_no').val(mb_no);
            $('#mb_pic_form')[0].reset();
            $('#insert_memo, #edit_memo').val('');
            dispatch();
          } else if (re_data == "err") {
            alert('등록오류!! 다시 등록해주세요');
            is_processing = false;
            setTimeout(() => $('#btn_member_insert').show().attr("disabled", false), 250);
          } else {
            alert('데이터오류! 시스템 관리자에게 문의하세요');
            is_processing = false;
            setTimeout(() => $('#btn_member_insert').show().attr("disabled", false), 250);
            // setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          is_processing = false;
          setTimeout(() => $('#btn_member_insert').show().attr("disabled", false), 250);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }, 500);
  }

  function change_service_state() {
    if ($('#mb_no').val() == "") {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if ($('#auth_code')[0].value == "") {
      alert('인증코드를 입력해주세요');
      $('#search_auth_code')[0].focus();
      return;
    }

    if ($('#insert_memo')[0].value == "") {
      alert('수정메모를 입력해주세요');
      $('#insert_memo')[0].focus();
      return;
    }

    const service_state_form = $('#service_state_form')[0];
    service_state_form.locker_clear_YN.value = "Y";
    service_state_form.mb_no.value = $('#mb_no').val();
    service_state_form.auth_code.value = $('#auth_code').val();
    service_state_form.receive_name.value = $('#reg_step').val();

    var formData = new FormData(service_state_form);
    $.ajax({
      url: '/member/service/state/change',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        data = data?.message?.trim();
        if (data == "ok") {
          alert('수정되었습니다');
          service_state_form.insert_memo.value = '';
          setRenew(Fn.getRenewTime());
        } else if (data == "member_not_exists") {
          alert('대상 회원 정보를 찾을 수 없습니다.');
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
        }
      }
    });
  }


  function show_g_locker() {
    try {
      if ($('#g_locker1')[0].checked == true) {
        $('#g_locker_no_info')[0].style.display = "flex";
        $('#g_locker_date_info')[0].style.display = "flex";
      } else {
        $('#g_locker_no_info')[0].style.display = "none";
        $('#g_locker_date_info')[0].style.display = "none";
      }

      if ($('#g_locker1')[0].checked == true || $('#golf1')[0].checked == true)
        $('.g_locker_info_area').show();
      else
        $('.g_locker_info_area').hide();
    } catch (err) {
    }
  }

  function show_golf() {
    try {
      if ($('#golf1')[0].checked == true) {
        $('#golf_date_info')[0].style.display = "flex";
      } else {
        $('#golf_date_info')[0].style.display = "none";
      }

      // if ($('#locker1')[0].checked == true || $('#suit1')[0].checked == true)
      //   $('.golf_info_area').show();
      // else
      //   $('.golf_info_area').hide();
    } catch (err) {
    }
  }

  function show_sque() {
    try {
      if ($('#opt31')[0].checked == true) {
        $('#opt3_date_info')[0].style.display = "flex";
      } else {
        $('#opt3_date_info')[0].style.display = "none";
      }
    } catch (err) {
    }
  }

  function show_spin() {
    try {
      if ($('#opt41')[0].checked == true) {
        $('#opt4_date_info')[0].style.display = "flex";
      } else {
        $('#opt4_date_info')[0].style.display = "none";
      }
    } catch (err) {
    }
  }

  function show_tae() {
    try {
      if ($('#opt51')[0].checked == true) {
        $('#opt5_date_info')[0].style.display = "flex";
      } else {
        $('#opt5_date_info')[0].style.display = "none";
      }
    } catch (err) {
    }
  }

  function show_pila() {
    try {
      if ($('#opt71')[0].checked == true) {
        $('#opt7_date_info')[0].style.display = "flex";
      } else {
        $('#opt7_date_info')[0].style.display = "none";
      }
    } catch (err) {
    }
  }


  function show_locker() {
    if ($('#locker1')[0].checked == true) {
      $('#locker_no_info')[0].style.display = "flex";
      $('#locker_date_info')[0].style.display = "flex";
    } else {
      $('#locker_no_info')[0].style.display = "none";
      $('#locker_date_info')[0].style.display = "none";
    }

    if ($('#locker1')[0].checked == true || $('#suit1')[0].checked == true)
      $('.ls_info_area').show();
    else
      $('.ls_info_area').hide();
  }

  function show_suit() {
    if ($('#suit1')[0].checked == true) {
      $('#suit_date_info')[0].style.display = "flex";
    } else {
      $('#suit_date_info')[0].style.display = "none";
    }

    if ($('#locker1')[0].checked == true || $('#suit1')[0].checked == true)
      $('.ls_info_area').show();
    else
      $('.ls_info_area').hide();
  }

  function del_id() {
    if ($('#mb_no').val() == "") {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }
    if ($('#auth_code').val() == "") {
      alert('인증코드를 입력해주세요');
      $('#search_auth_code').focus();
      return;
    }

    if (window.confirm('해당 회원의 아이디/비밀번호를 삭제하시겠습니까?')) {
      $.ajax({
        type: "post",
        url: "/member/id/del",
        data: "mb_no=" + $('#mb_no').val() + "&auth_code=" + $('#auth_code').val(),
        success: function (data) {
          $('#s_mb_no').val($('#mb_no').val());
          $('#mb_no').val($('#mb_no').val());
          dispatch();
        }
      });
    }
  }

  window.cash_request = (ip_no, ip_cardName) => {
    $('#cr_ip_no').val(ip_no);
    $('#cr_ip_cardName').val(ip_cardName);
    setCashRequestOpen(true);
  }

  window.card_cancel = (ip_no) => {
    $('#cr_ip_no').val(ip_no);
    setCardCancelOpen(true);
  }

  window.cash_cancel = (ip_no) => {
    $('#cr_ip_no').val(ip_no);
    setCashCancelOpen(true);
  }

  window.print_pay_re = (make_key) => {
    window.open('https://spoany.co.kr/receipt/receipt_print.html?make_card_key=' + make_key, '', 'width=600,height=700')
  }

  window.pop_up = (file, names, width, height, sc) => {
    const w = width;
    const h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;
    if (file.startsWith("print_pay_sales_re.html")) {
      if (true) {
        alert('Internet Explorer에서만 지원하는 기능입니다');
      }
      window.open("http://smms.spoany.minesolution.com/sales/" + file, names, `width=${w}, height=${h}, scrollbars=${sc}, top=${wint}, left=${winl}`);
    } else
      window.open(file, names, `width=${w}, height=${h}, scrollbars=${sc}, top=${wint}, left=${winl}`);
  }

  window.ipkum_date_change = (ip_no, mb_no) => {
    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 9 || localStorage.getItem("USER.systemAccountYN") == "Y") {
      if ($('#mb_no').val() == "") {
        alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
        return;
      }

      if ($('#auth_code')[0].value == "") {
        alert('인증코드를 입력해주세요');
        $('#search_auth_code')[0].focus();
        return;
      }

      $('#cr_ip_no').val(ip_no);
      setIpkumDateChange(true);
    } else {
      alert('본사관리자/경리팀만 가능합니다.');
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3">
        <div className="pr-3">
          <div className="form_search">
            <button type="button" className="btn" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if ($('#search_txt').val().trim().length === 0) {
                alert('검색어를 입력해주세요');
                return;
              }
              search('', $('#search_txt').val());
            }}><img src={searchIcon}/></button>
            <input type="text" id="search_txt" className="form-control" aria-describedby="button-addon2" placeholder="카드/이름/연락처 검색" style={{imeMode: "active"}} onKeyDown={(e) => {
              if (e.keyCode === 13 || e.keyCode === 9) {
                e.preventDefault();
                e.stopPropagation();

                if (e.target.value.trim().length === 0) {
                  alert('검색어를 입력해주세요');
                  return;
                }
                search('', e.target.value);
              }
            }}/>
          </div>

          <div className="p_absolute" style={{top: "174px", left: "215px", zIndex: 999999, display: "none"}} id="search_result">
            <div className="lay_myinfo" style={{borderRadius: 0, minWidth: "620px", height: "fit-content", minHeight: "unset", maxHeight: "500px", overflowY: "auto"}}>
              <table style={{width: "100%"}}>
                <tr className="p-1 border-bottom" style={{backgroundColor: "#f1f1f1"}} onClick={(e) => {
                  e.stopPropagation();
                }}>
                  <th><span style={{fontSize: "12px"}}>회원번호</span></th>
                  <th><span style={{fontSize: "12px"}}>이름</span></th>
                  <th><span style={{fontSize: "12px"}}>핸드폰</span></th>
                  <th><span style={{fontSize: "12px"}}>시작일</span></th>
                  <th><span style={{fontSize: "12px"}}>종료일</span></th>
                </tr>
                {srchResult && srchResult?.map((item, index) => {
                    return (
                      <tr className="p-1 border-bottom" style={{cursor: "pointer"}} onClick={(e) => {
                        e.stopPropagation();
                        $('#s_mb_no').val(item?.mb_no);
                        $('#mb_no').val(item?.mb_no);
                        dispatch();
                        $('#search_result').hide();
                      }}>
                        <td><span style={{fontSize: "12px"}}>{item?.mb_id}</span></td>
                        <td><span style={{fontSize: "12px"}}>{item?.mb_name}</span></td>
                        <td><span style={{fontSize: "12px"}}>***-****-{item?.mb_hp?.substring(Math.max(0, item?.mb_hp?.length - 4))}</span></td>
                        <td><span style={{fontSize: "12px"}}>{item?.start_date}</span></td>
                        <td><span style={{fontSize: "12px"}}>{item?.end_date}</span></td>
                      </tr>
                    )
                  }
                )}
              </table>
            </div>
          </div>

        </div>
        <div className="pr-3 d-flex" style={{flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
          <p htmlFor="인증코드" className="p-1 px-2" style={{wordBreak: "keep-all"}}>인증코드</p>
          <ChkAuthNum type={"locker_info_change"} successCallback={(data) => {
            const split = data.split("||");
            const re_cont1 = split[1];
            const re_cont2 = split[2];

            $("#auth_code_name").text(re_cont1);
            $("#auth_code").val(re_cont2);
            $("#_reg_step option").each(function () {
              this.selected = (this.text == re_cont1);
            });
          }}/>
        </div>
        <div className="pr-3 d-flex" style={{flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
          <p htmlFor="접수자" className="p-1 px-2">접수자</p>
          <select className="form-select" aria-label="Default select example" style={{width: "32%"}} id="_reg_step">
            <option value="">선택</option>
            {lResult?.regStepList?.map((item, index) =>
              <option key={index} value={`${item.seq}/${item.user_name}`}>{item.user_name}</option>
            )}
          </select>
        </div>
      </div>


      <div className="m-3 mb-4" style={{border: "1px solid #eee", borderRadius: "12px", background: "#F8F8FA"}}>
        <div className="border-bottom text-left">
          <p className="px-3 py-2">
            <span className="h6 bold700">회원정보
              {localStorage.getItem("USER.systemID") === 'futureinfo' && sResult?.memberInfo?.mb_no &&
                <span>({sResult?.memberInfo?.mb_no})</span>
              }
            </span>
          </p>
        </div>
        {/* 컨텐츠 내용 시작 */}
        <div className="p-3">
          <div className="d-flex justify-content-between">
            <div>
              <div className="USER_thum_regi" id="member_pic_view" style={{width: "170px", height: "170px"}}>
                <p className="absmiddle">
                  {/*<button type="button" className="btn btn-ssm btn-dark space_nowrap">+ 사진등록</button>*/}
                </p>
              </div>
              {/*<p className="mt-1">500*500 이상, 1Mbyte 이내</p>*/}
            </div>
            <div style={{width: "calc(100% - 185px)"}}>
              <table className="view-rounded sub_view">
                <colgroup>
                  <col width="108px"/>
                  <col width="*"/>
                  <col width="108px"/>
                  <col width="*"/>
                  <col width="108px"/>
                  <col width="*"/>
                  <col width="108px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>회원번호</th>
                  <td>
                    <input type="text" className="form-control" aria-describedby="button-addon2" id="s_mb_id" style={{backgroundColor: "#fcfad6"}}/>
                    <input type="hidden" id="s_mb_no"/>
                    <input type="hidden" id="mb_no"/>
                  </td>
                  <th>이름</th>
                  <td><input type="text" className="form-control" aria-describedby="button-addon2" id="s_mb_name" style={{backgroundColor: "#fcfad6"}}/></td>
                  <th>연락처</th>
                  <td>
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="" id="s_mb_hp" style={{height: "32px", backgroundColor: "#fcfad6"}}/>
                      <button className="btn btn-ssm btn-dark" type="button" id="btn_show_phone" onClick={() => window.show_phone(mbNo)}>연락처 확인</button>
                    </div>
                  </td>
                  <th>생일</th>
                  <td><input type="text" className="form-control" aria-describedby="button-addon2" id="s_mb_birth" placeholder="ex.750305" style={{backgroundColor: "#fcfad6"}}/></td>
                </tr>
                <tr>
                  <th>성별</th>
                  <td>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="s_mb_sex" id="s_mb_sex_1" value="M"/>
                      <label className="form-check-label" htmlFor="inlineRadio1">남</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="s_mb_sex" id="s_mb_sex_2" value="F"/>
                      <label className="form-check-label" htmlFor="inlineRadio2">여</label>
                    </div>
                  </td>
                  <th>가입경로</th>
                  <td>
                    <select className="form-select" aria-label="Default select example" style={{width: "32%", backgroundColor: "#fcfad6"}} id="s_visit_course">
                      <option value=''>:선택</option>
                      {lResult?.visitCourseQry?.map((item, index) => <option value={item.sub_code_name}>{item.sub_code_name}</option>)}
                    </select>
                  </td>
                  <th>주소</th>
                  <td colSpan="3">
                    <div className="input-group">
                      <button className="btn btn-ssm btn-dark" type="button" id="button-addon1" onClick={() => setPostCodeOpen(true)}>검색</button>
                      <input type="text" className="form-control" placeholder="" style={{height: "32px", backgroundColor: "#fcfad6"}} id="s_addr_code"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>사진</th>
                  <td colSpan="1">
                    <form name="mb_pic_form" id="mb_pic_form"><input type="file" className="form-control" aria-describedby="button-addon2" style={{maxWidth: "100%"}} name="mb_pic_file" id="mb_pic_file"/></form>
                  </td>
                  <th>알림</th>
                  <td colSpan="3"><input type="text" className="form-control" aria-describedby="button-addon2" id="s_alim"/></td>
                  <th>아이디</th>
                  <td>
                    <span id="s_member_id"></span>
                    {sResult?.memberInfo?.member_id?.length > 0 &&
                      <p className="float-right">
                        <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => del_id()}>삭제</button>
                      </p>
                    }
                    <span id="s_member_point"></span>
                  </td>
                </tr>
                <tr>
                  <th>메모</th>
                  <td colSpan="3" className="text-left">
                    <div id="s_mb_memo" style={{overflowY: "auto", maxHeight: "100px"}}></div>
                  </td>
                  <th>마케팅 동의</th>
                  <td colSpan="3">
                    <div className="d-flex justify-content-center flex-wrap">
                      <div>
                        <span className="px-2 bold700 vertical-middle">마케팅동의:</span>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="s_marketing_YN" id="marketing_YN_1" value="Y"/>
                          <label className="form-check-label" htmlFor="marketing_YN_1">동의</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="s_marketing_YN" id="marketing_YN_2" value="N"/>
                          <label className="form-check-label" htmlFor="marketing_YN_2">미동의</label>
                        </div>
                      </div>
                      <p className="px-2 bold700 vertical-middle">/</p>
                      <div>
                        <span className="px-2 bold700 vertical-middle">스포애니몰 동의:</span>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="s_marketing_shop_YN" id="marketing_shop_YN_1" value="Y"/>
                          <label className="form-check-label" htmlFor="marketing_shop_YN_1">동의</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="s_marketing_shop_YN" id="marketing_shop_YN_2" value="N"/>
                          <label className="form-check-label" htmlFor="marketing_shop_YN_2">미동의</label>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
              <div className="text-right mt-2">
                <div className="d-flex justify-content-end">
                  <input type="text" name="edit_memo" id="edit_memo" className="form-control" placeholder="메모" style={{height: "37px", minWidth: "300px", backgroundColor: "#ffffff"}}/>
                  <button type="button" className="btn btn-sm btn-point" id="btn_member_update" style={{display: "none"}} onClick={() => edit_member()}>회원정보 수정</button>
                  <button type="button" className="btn btn-sm btn-point" id="btn_member_insert" style={{display: "none"}} onClick={() => insert_member()}>회원 등록</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="info_area" style={{display: "none"}}>
        <div className="m-3 mb-4 d-flex flex-wrap">
          <p className="hash n_hash" style={{cursor: "pointer"}}
             onClick={() => {
               if ($('#mb_no').val() == "") {
                 alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                 return;
               }
               if ($('#auth_code').val() == "") {
                 alert('인증코드를 입력해주세요');
                 $('#search_auth_code').focus();
                 return;
               }
               if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
                 alert('가입경로와 주소가 입력되어 있지 않습니다.');
                 return;
               }

               setIsMvMembershipOpen(true)
             }}
          >회원권판매</p>
          {/*{(lResult?.bOptRow?.fran_YN != "Y" || Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7 || (sResult?.memberInfo?.start_date || '') == '') &&*/}
          {(lResult?.bOptRow?.fran_YN != "Y" || Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7) &&
            <p className="hash n_hash" style={{cursor: "pointer"}}
               onClick={() => {
                 if ($('#mb_no').val() == "") {
                   alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                   return;
                 }
                 if ($('#auth_code').val() == "") {
                   alert('인증코드를 입력해주세요');
                   $('#search_auth_code').focus();
                   return;
                 }
                 if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
                   alert('가입경로와 주소가 입력되어 있지 않습니다.');
                   return;
                 }

                 setIsMvChangeCenterOpen(true)
               }}
            >주센터변경</p>
          }
          <p className="hash n_hash" style={{cursor: "pointer"}}
             onClick={() => {
               if ($('#mb_no').val() == "") {
                 alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                 return;
               }
               if ($('#auth_code').val() == "") {
                 alert('인증코드를 입력해주세요');
                 $('#search_auth_code').focus();
                 return;
               }
               if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
                 alert('가입경로와 주소가 입력되어 있지 않습니다.');
                 return;
               }

               setIsMvChangeStartDateOpen(true)
             }}
          >시작일변경</p>
          <p className="hash n_hash" style={{cursor: "pointer"}}
             onClick={() => {
               if ($('#mb_no').val() == "") {
                 alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                 return;
               }
               if ($('#auth_code').val() == "") {
                 alert('인증코드를 입력해주세요');
                 $('#search_auth_code').focus();
                 return;
               }
               if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
                 alert('가입경로와 주소가 입력되어 있지 않습니다.');
                 return;
               }

               setIsMvAppInsert(true)
             }}
          >서비스신청</p>
          <p className="hash n_hash" style={{cursor: "pointer"}}
             onClick={() => {
               if ($('#mb_no').val() == "") {
                 alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                 return;
               }
               if ($('#auth_code').val() == "") {
                 alert('인증코드를 입력해주세요');
                 $('#search_auth_code').focus();
                 return;
               }
               if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
                 alert('가입경로와 주소가 입력되어 있지 않습니다.');
                 return;
               }

               setIsMvRepuCard(true);
             }}
          >카드재발급</p>
          <p className="hash n_hash" style={{cursor: "pointer"}}
             onClick={() => {
               if ($('#mb_no').val() == "") {
                 alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                 return;
               }
               if ($('#auth_code').val() == "") {
                 alert('인증코드를 입력해주세요');
                 $('#search_auth_code').focus();
                 return;
               }
               if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
                 alert('가입경로와 주소가 입력되어 있지 않습니다.');
                 return;
               }

               setIsMvInputInsert(true);
             }}
          >입금등록</p>
          <p className="hash n_hash" style={{cursor: "pointer"}}
             onClick={() => {
               if ($('#mb_no').val() == "") {
                 alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                 return;
               }
               if ($('#auth_code').val() == "") {
                 alert('인증코드를 입력해주세요');
                 $('#search_auth_code').focus();
                 return;
               }
               if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
                 alert('가입경로와 주소가 입력되어 있지 않습니다.');
                 return;
               }

               setIsMvDelayInsert(true);
             }}
          >연기신청/해제</p>
          <p className="hash n_hash" style={{cursor: "pointer"}} onClick={() => {
            if ($('#mb_no').val() == "") {
              alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
              return;
            }
            if ($('#auth_code').val() == "") {
              alert('인증코드를 입력해주세요');
              $('#search_auth_code').focus();
              return;
            }
            if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
              alert('가입경로와 주소가 입력되어 있지 않습니다.');
              return;
            }

            setIsMvRefundCal(true);
          }}>환불등록</p>
          <p className="hash n_hash" style={{cursor: "pointer"}} onClick={() => {
            if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
              alert('가입경로와 주소가 입력되어 있지 않습니다.');
              return;
            }
            setIsMvHistory(true)
          }}>히스토리</p>
          {!sResult?.bChkRowCnt ?
            <p className="hash n_hash" style={{cursor: "pointer"}} onClick={() => {
              if ($('#mb_no').val() == "") {
                alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                return;
              }
              if ($('#auth_code').val() == "") {
                alert('인증코드를 입력해주세요');
                $('#search_auth_code').focus();
                return;
              }
              if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
                alert('가입경로와 주소가 입력되어 있지 않습니다.');
                return;
              }

              $('#bl_reg_type').val('register');
              setIsMvBlackListReg(true);
            }}>관심회원등록</p>
            :
            <p className="hash n_hash" style={{cursor: "pointer"}}
               onClick={() => {
                 if ($('#mb_no').val() == "") {
                   alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                   return;
                 }
                 if ($('#auth_code').val() == "") {
                   alert('인증코드를 입력해주세요');
                   $('#search_auth_code').focus();
                   return;
                 }
                 if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
                   alert('가입경로와 주소가 입력되어 있지 않습니다.');
                   return;
                 }

                 $('#bl_reg_type').val('release');
                 setIsMvBlackListReg(true);
               }}
            >관심회원해제</p>
          }
          <p className="hash n_hash" style={{cursor: "pointer", display: "none"}}
             onClick={() => {
               if ($('#mb_no').val() == "") {
                 alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                 return;
               }
               if ($('#auth_code').val() == "") {
                 alert('인증코드를 입력해주세요');
                 $('#search_auth_code').focus();
                 return;
               }
               if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
                 alert('가입경로와 주소가 입력되어 있지 않습니다.');
                 return;
               }

               setIsMvIntroList(true);
             }}
          >추천소개</p>
          <p className="hash n_hash" style={{cursor: "pointer"}}
             onClick={() => {
               if ($('#mb_no').val() == "") {
                 alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                 return;
               }
               if ($('#auth_code').val() == "") {
                 alert('인증코드를 입력해주세요');
                 $('#search_auth_code').focus();
                 return;
               }
               if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
                 alert('가입경로와 주소가 입력되어 있지 않습니다.');
                 return;
               }

               setIsMvPointList(true)
             }}
          >포인트내역</p>
          {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 8 &&
            <p className="hash n_hash" style={{cursor: "pointer"}}
               onClick={() => {
                 if ($('#mb_no').val() == "") {
                   alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                   return;
                 }
                 if ($('#auth_code').val() == "") {
                   alert('인증코드를 입력해주세요');
                   $('#search_auth_code').focus();
                   return;
                 }
                 if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
                   alert('가입경로와 주소가 입력되어 있지 않습니다.');
                   return;
                 }

                 setIsMvChangePoint(true);
               }}
            >포인트조정</p>
          }
          <p className="hash n_hash" style={{cursor: "pointer"}}
             onClick={() => {
               if ($('#mb_no').val() == "") {
                 alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                 return;
               }
               if ($('#auth_code').val() == "") {
                 alert('인증코드를 입력해주세요');
                 $('#search_auth_code').focus();
                 return;
               }
               if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
                 alert('가입경로와 주소가 입력되어 있지 않습니다.');
                 return;
               }

               setIsMvGiftUse(true);
             }}
          >이용권등록</p>
          <p className="hash n_hash" style={{cursor: "pointer"}} onClick={() => {
            if (newVer && ($('#s_visit_course option:selected')?.val()?.trim() == '' || $('#s_addr_code')?.val()?.trim() == '')) {
              alert('가입경로와 주소가 입력되어 있지 않습니다.');
              return;
            }

            setIsMvAttend(true)
          }}>입장내역</p>
        </div>

        <form name="service_state_form" id="service_state_form" method="post">
          <input type="hidden" name="mb_no"/>
          <input type="hidden" name="auth_code"/>
          <input type="hidden" name="receive_name"/>
          <input type="hidden" name="remain_pt"/>
          <input type="hidden" name="locker_clear_YN"/>
          <input type="hidden" name="last_ap_date"/>
          <input type="hidden" name="black_state" value="N"/>
          <div className="m-3 mb-4" style={{border: "1px solid #eee", borderRadius: "12px"}}>
            <div className="border-bottom text-left px-3 p-2">
              <span className="h6 bold700">서비스 상태</span>
            </div>
            {/* 컨텐츠 내용 시작 */}
            <div className="p-3">
              <p className="text-left pb-2 bold600">
                {/*TODO*/}
                이용가능요일: <span id="member_able_day"></span> / 이용가능시간: <span id="member_able_time"></span>
              </p>
              <table className="view-rounded sub_view">
                <colgroup>
                  <col width="108px"/>
                  <col width="*"/>
                  <col width="108px"/>
                  <col width="*"/>
                  <col width="108px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>이용브랜드</th>
                  <td>
                    <span>{sResult?.ggRow?.brand_name || '이용가능 브랜드 없음'}</span>
                    <p className="float-right">
                      <button type="button" className="btn btn-ssm btn-dark mx-1" onClick={() => {
                        if (!$('#auth_code').val()) {
                          alert("인증코드를 입력해주세요");
                          return;
                        }
                        setIsMvChangeBrand(true);
                      }}>
                        변경
                      </button>
                    </p>
                  </td>
                  <th>이용기간</th>
                  <td>
                    <div className="input-group">
                      <JDatePicker className="form-control" aria-describedby="button-addon2" name="start_date" id="start_date" readOnly/>
                      <p className="mx-2">~</p>
                      <JDatePicker className="form-control" aria-describedby="button-addon2" name="end_date" id="end_date" readOnly/>
                      <button type="button" className="btn btn-ssm btn-dark mx-1" onClick={() => {
                        if ($('#mb_no').val() == "") {
                          alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                          return;
                        }
                        if ($('#auth_code').val() == "") {
                          alert('인증코드를 입력해주세요');
                          $('#search_auth_code').focus();
                          return;
                        }

                        setPeriodType('health');
                        setIsMvChangePeriod(true);
                      }}>
                        변경
                      </button>
                    </div>
                    <div id="not_use_date" className="text-left mt-2"></div>
                  </td>
                  <th>1일2회</th>
                  <td className="text-left">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="dub_able" id="Ydub_able" value="Y"/>
                      <label className="form-check-label" htmlFor="Ydub_able">
                        가능
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="dub_able" id="Ndub_able" value="N"/>
                      <label className="form-check-label" htmlFor="Ndub_able">
                        불가
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th rowSpan="2">라커</th>
                  <td colSpan="3" className="text-left">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="locker" id="locker1" value="1" onClick={() => show_locker()}/>
                      <label className="form-check-label" htmlFor="locker1">
                        유
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="locker" id="locker0" value="0" onClick={() => show_locker()}/>
                      <label className="form-check-label" htmlFor="locker0">
                        무
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="locker" id="locker2" value="2" onClick={() => show_locker()}/>
                      <label className="form-check-label" htmlFor="locker2">
                        예약
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="locker" id="locker3" value="3" onClick={() => show_locker()}/>
                      <label className="form-check-label" htmlFor="locker3">
                        미지급
                      </label>
                    </div>
                  </td>
                  <th rowSpan="2">운동복</th>
                  <td className="text-left">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="suit" id="suit1" value="1" onClick={() => show_suit()}/>
                      <label className="form-check-label" htmlFor="suit1">
                        유
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="suit" id="suit2" value="0" onClick={() => show_suit()}/>
                      <label className="form-check-label" htmlFor="suit2">
                        무
                      </label>
                    </div>
                  </td>
                </tr>
                <tr className="ls_info_area">
                  <td colSpan="3" className="text-left">
                    <div className="d-flex">
                      <div className="input-group mr-2" style={{width: "auto"}} id="locker_no_info">
                        <input type="hidden" name="locker_no" id="locker_no"/>
                        <input type="text" className="form-control2 table-inline" aria-describedby="button-addon2" style={{width: "150px"}} id="locker_name" name="locker_name" readOnly={true} onClick={() => {
                          setLockerFormType("service_state_form");
                          setIsLockerOpen(true);
                        }}/>
                        <input type="text" className="form-white table-inline" aria-describedby="button-addon2" style={{width: "70px"}} id="locker_pass" name="locker_pass"/>
                      </div>
                      <div className="input-group" style={{width: "auto"}} id="locker_date_info">
                        <JDatePicker className="form-control" aria-describedby="button-addon2" id="locker_start_date" name="locker_start_date" readOnly/>
                        <p className="mx-2">~</p>
                        <JDatePicker className="form-control" aria-describedby="button-addon2" id="locker_end_date" name="locker_end_date" readOnly/>
                        <button type="button" className="btn btn-ssm btn-dark mx-1" onClick={() => {
                          if ($('#mb_no').val() == "") {
                            alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                            return;
                          }
                          if ($('#auth_code').val() == "") {
                            alert('인증코드를 입력해주세요');
                            $('#search_auth_code').focus();
                            return;
                          }

                          setPeriodType('locker');
                          setIsMvChangePeriod(true);
                        }}>
                          변경
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="text-left">
                    <div className="input-group" id="suit_date_info">
                      <JDatePicker className="form-control" aria-describedby="button-addon2" id="suit_start_date" name="suit_start_date" value={sResult?.memberInfo?.suit_start_date} readOnly/>
                      <p className="mx-2">~</p>
                      <JDatePicker className="form-control" aria-describedby="button-addon2" id="suit_end_date" name="suit_end_date" value={sResult?.memberInfo?.suit_end_date} readOnly/>
                      <button type="button" className="btn btn-ssm btn-dark mx-1" onClick={() => {
                        if ($('#mb_no').val() == "") {
                          alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                          return;
                        }
                        if ($('#auth_code').val() == "") {
                          alert('인증코드를 입력해주세요');
                          $('#search_auth_code').focus();
                          return;
                        }

                        setPeriodType('suit');
                        setIsMvChangePeriod(true);
                      }}>
                        변경
                      </button>
                    </div>
                  </td>
                </tr>
              </table>


              <table className="view-rounded sub_view" style={{marginTop: "8px"}}>
                <colgroup>
                  <col width="108px"/>
                  <col width="*"/>
                  <col width="108px"/>
                  <col width="*"/>
                </colgroup>

                {
                  addRows?.length > 0 &&
                  addRows.reduce((acc, cur) => {
                    if (acc.length && acc[acc.length - 1].length < 4) {
                      // lastRow = acc[acc.length - 1];
                      acc[acc.length - 1].push(cur);
                    } else {
                      acc.push([cur]); // add new row
                    }
                    return acc;
                  }, []).map((row) => {
                    return <tr>{row.map(item => item)}</tr>;
                  })
                }
              </table>


              <div className="text-right mt-2">
                <div className="d-flex justify-content-end">
                  <input type="text" name="insert_memo" id="insert_memo" className="form-control" placeholder="메모" style={{height: "37px", minWidth: "300px", backgroundColor: "#ffffff"}}/>
                  <button type="button" className="btn btn-sm btn-point" onClick={() => change_service_state()}>서비스상태 변경</button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="m-3 mb-4">
          <table className="view-rounded sub_table_border" style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}>
            <colgroup>
              <col width="85px"/>
              <col width="85px"/>
              <col width="90px"/>
              <col width="250px"/>
              <col width="110px"/>
              <col width="60px"/>
              <col width="75px"/>
              <col width="*"/>
              <col width="70px"/>
              <col width="70px"/>
              <col width="80px"/>
              <col width="67.5px"/>
            </colgroup>
            <thead>
            <tr>
              <td colSpan="12">
                <div className="d-flex justify-content-between">
                  <p><span className="h6 bold700 px-2">서비스 신청</span></p>
                  <p>
                    <button type="button" className="btn btn-ssm btn-dark" onClick={() => setIsMvService(true)}>전체내역</button>
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <th>매출날짜</th>
              <th>시작날짜</th>
              <th>브랜드</th>
              <th>서비스명</th>
              <th>가격</th>
              <th>수량</th>
              <th>기간</th>
              <th>서비스비고</th>
              <th>접수자</th>
              <th>등록자</th>
              <th>기간설정</th>
              <th>삭제</th>
            </tr>
            </thead>
          </table>
          <div className="scrollable">
            <table className="view-rounded sub_table_border scrollable">
              <colgroup>
                <col width="85px"/>
                <col width="85px"/>
                <col width="90px"/>
                <col width="250px"/>
                <col width="110px"/>
                <col width="60px"/>
                <col width="75px"/>
                <col width="*"/>
                <col width="70px"/>
                <col width="70px"/>
                <col width="80px"/>
                <col width="60px"/>
              </colgroup>
              <tbody>
              {
                sResult?.appListQry && sResult?.appListQry.map((ret, i) => {

                  let l_cnt = i + 1;
                  let a_cnt = l_cnt - 1;
                  l_ap_date[l_cnt] = ret?.ap_date;
                  l_ap_amt[l_cnt] = ret?.ap_amt;

                  T_amt = T_amt + (l_ap_amt?.[a_cnt] || 0);

                  let setBtn = false;
                  if (ret?.period_YN === "Y") {
                    setBtn = false;
                  } else if (ret?.period_YN === "N" && ret.period) {
                    setBtn = true;
                  }

                  _T_amt = T_amt;
                  if (prevDate != ret?.ap_date)
                    T_amt = 0;
                  prevDate = ret?.ap_date;


                  let delBtn = 1;
                  if (ret?.ap_date >= "2018-01-01") {
                    //지점장 이상 이거나 당일건에 한해서 삭제버튼
                    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7 || ret?.ap_date == Fn.getCurrentDate()) {
                      delBtn = 0;
                    } else {
                      delBtn = 2;
                    }
                  }

                  if (l_ap_date?.[l_cnt] != l_ap_date?.[a_cnt] && l_cnt >= 2) {
                    return (
                      <>
                        <tr style={{background: "#ccc"}}>
                          <td colSpan='4'><strong>합 계</strong></td>
                          <td className='text-right'><strong>{Fn.numberWithCommas(_T_amt)}</strong></td>
                          <td colSpan='6'></td>
                        </tr>
                        <tr>
                          <td style={{cursor: "pointer"}} onClick={() => {
                            if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 9 || localStorage.getItem("USER.systemAccountYN") == "Y") {
                              if ($('#mb_no').val() == "") {
                                alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                                return;
                              }

                              if ($('#auth_code')[0].value == "") {
                                alert('인증코드를 입력해주세요');
                                $('#search_auth_code')[0].focus();
                                return;
                              }

                              $('#cr_ap_no').val(ret?.ap_no);
                              setServiceDateChange(true);
                            } else {
                              alert('본사관리자/경리팀만 가능합니다.');
                            }
                          }}>{ret?.ap_date}</td>
                          <td>{ret?.start_date}</td>
                          <td>{ret?.brNameRow?.brand_name || ''}</td>
                          <td>{ret?.ap_pdtName}</td>
                          <td className="text-right">{Fn.numberWithCommas(ret?.ap_amt)}</td>
                          <td>{ret?.ap_qty}</td>
                          <td>{ret?.period > 0 && `${ret?.period}${ret?.period_type == 'day' ? '일' : '개월'}`}</td>
                          <td className="text-left">{ret?.ap_etc}</td>
                          <td>{ret?.reg_step == "undefined" ? "" : ret?.reg_step}</td>
                          <td>{ret?.ap_step == "undefined" ? "" : ret?.ap_step}</td>
                          <td>
                            {setBtn && <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => service_period_setting(ret?.ap_date, mbNo)}>설정</button>}
                          </td>
                          <td>
                            {delBtn === 0 ? <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => sevice_del(ret?.ap_no, mbNo, ret?.ap_pdtName)}>삭제</button>
                              :
                              delBtn === 2 ? <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => alert('지점장/스텝계정은 당일 등록건만 삭제가능합니다.')}>삭제</button>
                                :
                                null
                            }
                          </td>
                        </tr>
                      </>
                    );
                  } else {
                    return (
                      <tr>
                        <td style={{cursor: "pointer"}} onClick={() => {
                          if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 9 || localStorage.getItem("USER.systemAccountYN") == "Y") {
                            if ($('#mb_no').val() == "") {
                              alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                              return;
                            }

                            if ($('#auth_code')[0].value == "") {
                              alert('인증코드를 입력해주세요');
                              $('#search_auth_code')[0].focus();
                              return;
                            }

                            $('#cr_ap_no').val(ret?.ap_no);
                            setServiceDateChange(true);
                          }
                        }}>{ret?.ap_date}</td>
                        <td>{ret?.start_date}</td>
                        <td>{ret?.brNameRow?.brand_name || ''}</td>
                        <td>{ret?.ap_pdtName}</td>
                        <td className="text-right">{Fn.numberWithCommas(ret?.ap_amt)}</td>
                        <td>{ret?.ap_qty}</td>
                        <td>{ret?.period > 0 && `${ret?.period}${ret?.period_type == 'day' ? '일' : '개월'}`}</td>
                        <td className="text-left">{ret?.ap_etc}</td>
                        <td style={{cursor: "pointer"}} onClick={() => {
                          if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 7) {
                            if ($('#mb_no').val() == "") {
                              alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
                              return;
                            }

                            if ($('#auth_code')[0].value == "") {
                              alert('인증코드를 입력해주세요');
                              $('#search_auth_code')[0].focus();
                              return;
                            }

                            // if(!ret?.reg_step_seq)
                            // {
                            //   alert('SMMS개발전 데이터는 접수자 변경이 불가능합니다');
                            //   return;
                            // }

                            $('#cr_ap_no').val(ret?.ap_no);
                            $('#cr_reg_step_seq').val(ret?.reg_step_seq);
                            setRegStepEdit(true);
                          }
                        }}>{ret?.reg_step == "undefined" ? "" : ret?.reg_step}</td>
                        <td>{ret?.ap_step == "undefined" ? "" : ret?.ap_step}</td>
                        <td>
                          {setBtn && <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => service_period_setting(ret?.ap_date, mbNo)}>설정</button>}
                        </td>
                        <td>
                          {delBtn === 0 ? <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => sevice_del(ret?.ap_no, mbNo, ret?.ap_pdtName)}>삭제</button>
                            :
                            delBtn === 2 ? <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => alert('지점장/스텝계정은 당일 등록건만 삭제가능합니다.')}>삭제</button>
                              :
                              null
                          }
                        </td>
                      </tr>
                    );
                  }
                })
              }
              {(!sResult?.appListQry || sResult?.appListQry?.length === 0) &&
                <tr>
                  <td colSpan={12}>※ 등록된 data가 없습니다.</td>
                </tr>
              }
              </tbody>
            </table>
          </div>
        </div>

        <div className="m-3 mb-4">
          <table className="view-rounded sub_table_border" style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}>
            <colgroup>
              <col width="85px"/>
              <col width="175px"/>
              <col width="250px"/>
              <col width="110px"/>
              <col width="*"/>
              <col width="70px"/>
              <col width="60px"/>
              <col width="67.5px"/>
            </colgroup>
            <tr>
              <td colSpan="8" className="text-left">
                <div className="d-flex justify-content-between">
                  <p>
                    <span className="h6 bold700 px-2">입금</span>
                    {sResult &&
                      <>
                        <span className="h8 bold600">- 누적결제: <span id="all_member_price">{Fn.numberWithCommas(sResult?.allMemberPrice)}원</span> / 올해결제 : <span id="now_member_price">{Fn.numberWithCommas(sResult?.nowMemberPrice)}원</span></span>
                        {sResult?.misuPrice != 0 &&
                          <span className="h8 bold600"> / <span style={{color: "red"}}>미납금 : <span id="misu_price">{Fn.numberWithCommas(sResult?.misuPrice)}원</span></span></span>
                        }
                      </>
                    }
                  </p>
                  <p>
                    <button type="button" className="btn btn-ssm btn-dark mx-1" onClick={() => setIsMvAllIpkum(true)}>전체내역</button>
                    <button type="button" className="btn btn-ssm btn-dark" onClick={() => setIsMvAllServiceIpkum(true)}>일자별 매출/입금</button>
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <th>입금날짜</th>
              <th>형태</th>
              <th>매입사</th>
              <th>금액</th>
              <th>금액비고</th>
              <th>등록자</th>
              <th>수정</th>
              <th>삭제</th>
            </tr>
          </table>
          <div className="scrollable">
            <table className="view-rounded sub_table_border scrollable">
              <colgroup>
                <col width="85px"/>
                <col width="175px"/>
                <col width="250px"/>
                <col width="110px"/>
                <col width="*"/>
                <col width="70px"/>
                <col width="60px"/>
                <col width="60px"/>
              </colgroup>
              {sResult?.result && sResult?.result.map((ret, i) => {
                let backColor = "#fff";
                if (ret.edit_YN !== "Y") backColor = "#fafed8";


                // TODO : PTODO -> 아직 미처리된 부분이 있음 / 반드시 처리할 것!!!
                var input_txt = "", ip_amt = "", c_open = "", c_close = "", base_ip_no = "", del_btn = "", edit_btn = "", edit_date = "", re_print = "";
                var nnd = "", bc_date = "", card_cancel = "", re_print = "", showDelBtn = "", delBtnMsg = "";

                if (ret?.ip_cardName) {
                  input_txt = ret?.ip_method + "(" + ret?.ip_cardName + ")";
                } else
                  input_txt = ret?.ip_method;

                if (ret?.cash_p === "Y") {
                  if (localStorage.getItem("USER.systemID") === "futureinfo" || localStorage.getItem("USER.systemID") === "tpwls8905") {
                    // 2023.10.29
                    // input_txt += "&nbsp;<a href='#1' onclick=\"window.open('https://spoany.co.kr/receipt/receipt_print_cash.html?base_ip_no=" + btoa(ret?.ip_no) + "','','width=600,height=700');\"><span class=\"label label-primary\">영수증</span></a>";
                  }
                } else {
                  if ((ret?.ip_method === "현금" || ret?.ip_method === "계좌이체") && ret?.ip_amt > 0) {
                    input_txt += "&nbsp;<font color='red'><a onclick=\"window.cash_request('" + ret?.ip_no + "');\" style='cursor: pointer;'><span class=\"label label-primary\">영수증신청</span></a></font>";
                  }
                }

                // ip_amt = ret?.ip_amt.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                ip_amt = Fn.numberWithCommas(ret?.ip_amt);

                if (ret?.ip_amt < 0) {
                  c_open = "<span class=\"text-danger\">";
                  c_close = "</span>";
                } else {
                  c_open = "";
                  c_close = "";
                }

                if (ret?.ip_date >= "2018-01-01") {
                  if (ret?.ip_date === Fn.getCurrentDate()) {
                    if (ret?.edit_YN === "Y") {
                      if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 7) {
                        base_ip_no = btoa(ret?.ip_no);
                        del_btn = "<button type=\"button\" class=\"btn btn-ssm btn-outline-point\" onclick=\"window.ipkum_del('" + base_ip_no + "','" + mbNo + "')\">삭제</button>";
                      } else {
                        del_btn = "<button type=\"button\" class=\"btn btn-ssm btn-outline-point\" onclick=\"alert('스텝계정은 삭제하실수 없습니다.')\">삭제</button>";
                      }
                    } else {
                      del_btn = "";
                    }
                  } else if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 9 || localStorage.getItem("USER.systemAccountYN") === "Y") {
                    if (ret?.edit_YN === "Y") {
                      base_ip_no = btoa(ret?.ip_no);
                      del_btn = "<button type=\"button\" class=\"btn btn-ssm btn-outline-point\" onclick=\"window.ipkum_del('" + base_ip_no + "','" + mbNo + "')\">삭제</button>";
                    } else {
                      del_btn = "";
                    }
                  } else {
                    del_btn = "<button type=\"button\" class=\"btn btn-ssm btn-outline-point\" onclick=\"alert('본사관리자/경리팀만 가능합니다.')\">삭제</button>";
                  }
                } else {
                  del_btn = "";
                }

                if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 9 || localStorage.getItem("USER.systemAccountYN") === "Y" || localStorage.getItem("USER.systemID") === "futureinfo") {
                  base_ip_no = btoa(ret?.ip_no);
                  if (localStorage.getItem("USER.systemID") === "futureinfo")
                    edit_btn = "<button type=\"button\" class=\"btn btn-ssm btn-outline-point\" onclick=\"window.ipkum_edit('" + btoa(ret?.ip_no) + "', '" + mbNo + "')\">수정</button>";
                  else
                    edit_btn = "<button type=\"button\" class=\"btn btn-ssm btn-outline-point cls-hide\" onclick=\"window.ipkum_edit('" + btoa(ret?.ip_no) + "', '" + mbNo + "')\">수정</button>";
                } else {
                  edit_btn = "<button type=\"button\" class=\"btn btn-ssm btn-outline-point\" onclick=\"alert('본사관리자/경리팀만 가능합니다')\">수정</button>";
                }

                if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 9 || localStorage.getItem("USER.systemAccountYN") === "Y") {
                  edit_date = "<a style='cursor:pointer;' onclick=\"window.ipkum_date_change('" + ret?.ip_no + "','" + mbNo + "')\">";
                } else {
                  edit_date = "<a style='cursor:pointer;' onclick=\"alert('본사관리자/경리팀만 가능합니다')\">";
                }

                if (ret?.cardpay === "Y") {
                  if (ret?.cardCancelAble === "Y") {
                    // 2023.10.29
                    // input_txt += "&nbsp;<button type=\"button\" class=\"btn btn-sssm btn-outline-point\" onclick=\"window.open('https://spoany.co.kr/receipt/receipt_print_cash.html?base_ip_no=" + btoa(ret?.ip_no) + "','','width=600,height=700');\">영수증</button>";

                    // TODO : PTODO -> make_key 처리
                    //window.open('https://spoany.co.kr/receipt/receipt_print.html?make_card_key=<?=$make_key?>','','width=600,height=700')
                    re_print = "<button type=\"button\" class=\"btn btn-sssm btn-outline-point\" onclick=\"window.print_pay_re('" + ret?.make_key + "')\"><!--i class='fas fa-print'></i-->인쇄</button>";

                    nnd = new Date();
                    bc_date = new Date(nnd);
                    bc_date.setMonth(bc_date.getMonth() - 3);
                    bc_date = Fn.getDate(bc_date);

                    if (ret?.ip_date >= bc_date) {
                      card_cancel = "<button type=\"button\" class=\"btn btn-sssm btn-outline-point\" onclick=\"window.card_cancel('" + ret?.ip_no + "');\">취</button>";
                    } else {
                      card_cancel = "<button type=\"button\" class=\"btn btn-sssm btn-outline-point\" onclick=\"alert('3개월 이내 카드입금건만 취소가능합니다');\">취</button>";
                    }
                  } else {
                    if (ret?.ip_amt < 0) {
                      // TODO : PTODO -> make_key 처리
                      //window.open('https://spoany.co.kr/receipt/receipt_print.html?make_card_key=<?=$make_key?>','','width=600,height=700')
                      re_print = "<button type=\"button\" class=\"btn btn-sssm btn-outline-point\" onclick=\"window.print_pay_re('" + ret?.make_key + "')\"><!--i class='fas fa-print'></i-->인쇄</button>";
                    } else {
                      re_print = "";
                    }
                    card_cancel = "";
                  }
                } else {
                  re_print = "";
                  card_cancel = "";
                }

                if (ret?.ip_method === "현금") {
                  if (ret?.cashCancelAble === "Y") {
                    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7) {
                      var cash_cancel = "<button type=\"button\" class=\"btn btn-sssm btn-outline-point\" onclick=\"window.cash_cancel('" + ret?.ip_no + "');\">취</button>";
                    } else if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && (ret?.ip_date === Fn.getCurrentDate())) {
                      var cash_cancel = "<button type=\"button\" class=\"btn btn-sssm btn-outline-point\" onclick=\"window.cash_cancel('" + ret?.ip_no + "');\">취</button>";
                    } else {
                      var cash_cancel = "<button type=\"button\" class=\"btn btn-sssm btn-outline-point\" onclick=\"alert('당일이전건은 본사만 삭제가능합니다');\">취</button>";
                    }
                  } else {
                    var cash_cancel = "";
                  }
                } else if (ret?.ip_method === "계좌이체") {
                  if (ret?.cashCancelAble === "Y") {
                    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7) {
                      var cash_cancel = "<button type=\"button\" class=\"btn btn-sssm btn-outline-point\" onclick=\"window.cash_cancel('" + ret?.ip_no + "');\">취</button>";
                    } else if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && (ret?.ip_date === Fn.getCurrentDate())) {
                      var cash_cancel = "<button type=\"button\" class=\"btn btn-sssm btn-outline-point\" onclick=\"window.cash_cancel('" + ret?.ip_no + "');\">취</button>";
                    } else {
                      var cash_cancel = "<button type=\"button\" class=\"btn btn-sssm btn-outline-point\" onclick=\"alert('당일이전건은 본사만 삭제가능합니다');\">취</button>";
                    }
                  } else {
                    var cash_cancel = "";
                  }
                } else {
                  var cash_cancel = "";
                }


                //-------------------------------------------------------

                /*
                let showDelBtn = "N", delBtnMsg = "";
                if (ret.ip_date >= "2018-01-01") {
                  if (ret.ip_date === Fn.getCurrentDate()) {
                    if (ret?.edit_YN === "Y") {
                      if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 7) {
                        showDelBtn = 'Y';
                        delBtnMsg = "";
                      } else {
                        showDelBtn = 'Y';
                        delBtnMsg = "스텝계정은 삭제하실수 없습니다.";
                      }
                    }
                  } else if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 || localStorage.getItem("USER.systemAccountYN") === "Y") {
                    if (ret.edit_YN === "Y") {
                      showDelBtn = 'Y';
                      delBtnMsg = "";
                    }
                  } else {
                    showDelBtn = 'Y';
                    delBtnMsg = "본사관리자/경리팀만 가능합니다.";
                  }
                }

                // TODO : PTODO -> member_view 481 line부터를 참조해서 조건식 넣을 것

                let input_txt = "";
                if (ret?.cash_p == "Y") {
                  if (localStorage.getItem("USER.systemID") == "futureinfo" || localStorage.getItem("USER.systemID") == "tpwls8905") {
                    // input_txt = `<a href='#1' onclick="pop_up('https://spoany.co.kr/receipt/receipt_print.html?make_card_key=${ret?.make_card_key}','cash_receipt','600','700','no');" xmlns="http://www.w3.org/1999/html"><span class="label label-primary">[영수증]</label></a>`;
                    input_txt = `<a style="cursor: pointer;" onclick="pop_up('https://spoany.co.kr/receipt/receipt_print_cash.html?base_ip_no=${btoa(ret?.ip_no)}','cash_receipt','600','700','no');" xmlns="http://www.w3.org/1999/html"><span class="label label-primary">[영수증]</label></a>`;
                  }
                } else {
                  //현금일 경우에만 신청
                  if ((ret?.ip_method == "현금" || ret?.ip_method == "계좌이체") && ret?.ip_amt > 0) {
                    input_txt = `<a style="cursor: pointer;" onclick="window.cash_request('${ret?.ip_no}');" xmlns="http://www.w3.org/1999/html"><span class="label label-primary">영수증신청</label></a>`;
                  }
                }
                // input_txt = ""; // TODO : 보이지 않게 처리
                */

                // <td className="text-left" dangerouslySetInnerHTML={{__html: ret?.ip_method + ((ret?.ip_method === '카드' && ret?.ip_cardName) ? `(${ret?.ip_cardName})` : "") + "&nbsp;" + input_txt}}></td>

                /*
                  return (
                    <tr style={{backgroundColor: "#fafed8"}}>
                      <td>{ret?.ip_date}</td>
                      <td className="text-left" dangerouslySetInnerHTML={{__html: ret?.ip_method + ((ret?.ip_method === '카드' && ret?.ip_cardName) ? `(${ret?.ip_cardName})` : "") + "&nbsp;" + input_txt}}></td>
                      <td>{ret?.maip}</td>
                      <td className="text-right">{Fn.numberWithCommas(ret?.ip_amt)}</td>
                      <td className="text-left">{ret?.ip_etc}</td>
                      <td>{ret?.ip_step}</td>
                      <td>
                        {
                          (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 || localStorage.getItem("USER.systemAccountYN") === "Y") ?
                            ret.edit_YN === "Y" ?
                              <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => window.ipkum_edit(btoa(ret?.ip_no), mbNo)}>수정</button>
                              :
                              null
                            :
                            <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => alert("본사관리자/경리팀만 가능합니다")}>수정</button>
                        }
                      </td>
                      <td>
                        {showDelBtn === "Y" ?
                          delBtnMsg?.length === 0 ?
                            <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => window.ipkum_del(btoa(ret?.ip_no), mbNo)}>삭제</button>
                            :
                            <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => alert(delBtnMsg)}>삭제</button>
                          :
                          null
                        }
                      </td>
                    </tr>);
                 */

                if (ret?.edit_YN == "Y")
                  return (
                    <tr>
                      <td>
                        <div dangerouslySetInnerHTML={{__html: edit_date + " " + ret?.ip_date}}/>
                      </td>
                      <td className="text-left">
                        <div dangerouslySetInnerHTML={{__html: input_txt + " " + cash_cancel}}/>
                      </td>
                      <td>
                        <div dangerouslySetInnerHTML={{__html: ret?.maip + " " + re_print + " " + card_cancel}}/>
                      </td>
                      <td className="text-right">
                        <div dangerouslySetInnerHTML={{__html: c_open + "" + ip_amt + "" + c_close}}/>
                      </td>
                      <td className="text-left">{ret?.ip_etc}</td>
                      <td>{ret?.ip_step}</td>
                      <td>
                        <div dangerouslySetInnerHTML={{__html: edit_btn}}/>
                      </td>
                      <td>
                        <div dangerouslySetInnerHTML={{__html: del_btn}}/>
                      </td>
                    </tr>);
                else
                  return (
                    <tr style={{backgroundColor: "#fafed8"}}>
                      <td>{ret?.ip_date}</td>
                      <td className="text-left">
                        <div dangerouslySetInnerHTML={{__html: input_txt + " " + cash_cancel}}/>
                      </td>
                      <td>
                        <div dangerouslySetInnerHTML={{__html: ret?.maip + " " + re_print + " " + card_cancel}}/>
                      </td>
                      <td className="text-right">
                        <div dangerouslySetInnerHTML={{__html: c_open + "" + ip_amt + "" + c_close}}/>
                      </td>
                      <td className="text-left">{ret?.ip_etc}</td>
                      <td>{ret?.ip_step}</td>
                      <td>
                        <div dangerouslySetInnerHTML={{__html: edit_btn}}/>
                      </td>
                      <td>
                        <div dangerouslySetInnerHTML={{__html: del_btn}}/>
                      </td>
                    </tr>);
              })}
              {(!sResult?.result || sResult?.result?.length === 0) &&
                <tr>
                  <td colSpan={8}>※ 등록된 data가 없습니다.</td>
                </tr>
              }
            </table>
          </div>
        </div>

        <div className="row m-1 mb-4">
          <div className="col">
            <table className="view-rounded sub_table_border">
              <colgroup>
                <col width="85px"/>
                <col width="85px"/>
                <col width="85px"/>
                <col width="85px"/>
                <col width="70px"/>
                <col width="*"/>
                <col width="75px"/>
                <col width="60px"/>
              </colgroup>
              <tr>
                <td colSpan="8" className="text-left">
                  <span className="h6 bold700 px-2">연기 신청</span>
                </td>
              </tr>
              <tr>
                <th>신청일</th>
                <th>연기시작</th>
                <th>연기종료</th>
                <th>전기간종료</th>
                <th>기간</th>
                <th>비고</th>
                <th>접수자</th>
                <th>삭제</th>
              </tr>
              {sResult?.dListQry && sResult?.dListQry.map((ret, i) => {
                return (
                  <tr>
                    <td>{ret?.de_regDate}</td>
                    <td>{ret?.de_sDate}</td>
                    <td>{ret?.de_eDate}</td>
                    <td>{ret?.de_eDateOld}</td>
                    <td>{ret?.de_period}</td>
                    <td className="text-left">{ret?.de_etc}</td>
                    <td>{ret?.de_step}</td>
                    <td>
                      <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => window.delay_del(ret?.de_no, mbNo)}>삭제</button>
                    </td>
                  </tr>
                );
              })}
              {(!sResult?.dListQry || sResult?.dListQry?.length === 0) &&
                <tr>
                  <td colSpan={8}>※ 등록된 data가 없습니다.</td>
                </tr>
              }
            </table>
          </div>
          <div className="col">
            <table className="view-rounded sub_table_border">
              <colgroup>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <td colSpan="7" className="text-left">
                  <span className="h6 bold700 px-2">PT 진행 내역</span>
                </td>
              </tr>
              <tr>
                <th>진행일</th>
                <th>시간</th>
                <th>구분</th>
                <th>트레이너</th>
                <th>횟수</th>
                <th>단가</th>
                <th>비고</th>
              </tr>
              {sResult?.ptList && sResult?.ptList.map((pt_list_row, i) => {
                let gubun = pt_list_row.gubun;
                let gubun_show = "";
                if (gubun === "K") {
                  gubun_show = "정상";
                } else if (gubun === "F") {
                  gubun_show = "무료";
                } else if (gubun === "C") {
                  gubun_show = "무단취소";
                }

                let pt_danga = new Intl.NumberFormat().format(pt_list_row.pt_danga);

                return (
                  <tr>
                    <td>{pt_list_row.take_date}</td>
                    <td>{pt_list_row.take_time}</td>
                    <td>{gubun_show}</td>
                    <td>{pt_list_row.trainer}</td>
                    <td>{pt_list_row.pt_cnt}</td>
                    <td>{pt_danga}</td>
                    <td>{pt_list_row.bigo}</td>
                  </tr>
                );
              })}
              {(!sResult?.ptList || sResult?.ptList?.length === 0) &&
                <tr>
                  <td colSpan={7}>※ 등록된 data가 없습니다.</td>
                </tr>
              }
            </table>
          </div>
        </div>
      </div>

      <input type="hidden" id="bl_reg_type"/>
      <input type="hidden" id="edit_ipkum_ip_no"/>
      <input type="hidden" id="edit_ap_date"/>
      <input type="hidden" id="cr_ip_no"/>
      <input type="hidden" id="cr_ap_no"/>
      <input type="hidden" id="cr_reg_step_seq"/>
      <input type="hidden" id="cr_ip_cardName"/>

      <MvChangeCenter isOpen={isMvChangeCenterOpen} onClose={() => setIsMvChangeCenterOpen(false)} onCallback={() => {
        history.push("/member/history");
      }} mbNo={mbNo}/>
      <MvMembership isOpen={isMvMembershipOpen} onClose={() => setIsMvMembershipOpen(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo} mbInfo={sResult?.memberInfo} coupons={sResult?.c}/>
      <MvChangeStartDate isOpen={isMvChangeStartDateOpen} onClose={() => setIsMvChangeStartDateOpen(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvAppInsert isOpen={isMvAppInsert} onClose={() => setIsMvAppInsert(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvRepuCard isOpen={isMvRepuCard} onClose={() => setIsMvRepuCard(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvInputInsert isOpen={isMvInputInsert} onClose={() => setIsMvInputInsert(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvDelayInsert isOpen={isMvDelayInsert} onClose={() => setIsMvDelayInsert(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvRefundCal isOpen={isMvRefundCal} onClose={() => setIsMvRefundCal(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvBlackListReg isOpen={isMvBlackListReg} onClose={() => setIsMvBlackListReg(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvPointList isOpen={isMvPointList} onClose={() => setIsMvPointList(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvChangePoint isOpen={isMvChangePoint} onClose={() => setIsMvChangePoint(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvGiftUse isOpen={isMvGiftUse} onClose={() => setIsMvGiftUse(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvAttend isOpen={isMvAttend} onClose={() => setIsMvAttend(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvChangeBrand isOpen={isMvChangeBrand} onClose={() => setIsMvChangeBrand(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvChangePeriod isOpen={isMvChangePeriod} onClose={() => setIsMvChangePeriod(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo} periodType={periodType}/>
      <MvChangeLockerPeriod isOpen={isMvChangeLockerPeriod} onClose={() => setIsMvChangeLockerPeriod(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvChangeSuitPeriod isOpen={isMvChangeSuitPeriod} onClose={() => setIsMvChangeSuitPeriod(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvService isOpen={isMvService} onClose={() => setIsMvService(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvAllIpkum isOpen={isMvAllIpkum} onClose={() => setIsMvAllIpkum(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvAllServiceIpkum isOpen={isMvAllServiceIpkum} onClose={() => setIsMvAllServiceIpkum(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvIntroList isOpen={isMvIntroList} onClose={() => setIsMvIntroList(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvHistory isOpen={isMvHistory} onClose={() => setIsMvHistory(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvEditIpkum isOpen={isMvEditIpkum} onClose={() => setIsMvEditIpkum(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <MvServicePeriod isOpen={isMvServicePeriod} onClose={() => setIsMvServicePeriod(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo}/>
      <SelectLocker isOpen={isLockerOpen} onClose={() => setIsLockerOpen(false)} onCallback={() => setRenew(Fn.getRenewTime())} mbNo={mbNo} formType={lockerFormType}/>
      <PostCode isOpen={isPostCodeOpen} onCallback={(addr) => $('#s_addr_code').val(addr)} onClose={() => setPostCodeOpen(false)} mbNo={mbNo}/>
      <CashRequest isOpen={isCashRequestOpen} onCallback={() => setRenew(Fn.getRenewTime())} onClose={() => setCashRequestOpen(false)} mbNo={mbNo}/>
      <CardCancel isOpen={isCardCancelOpen} onCallback={() => setRenew(Fn.getRenewTime())} onClose={() => setCardCancelOpen(false)} mbNo={mbNo}/>
      <MvCashCancel isOpen={isCashCancelOpen} onCallback={() => setRenew(Fn.getRenewTime())} onClose={() => setCashCancelOpen(false)} mbNo={mbNo}/>
      <MvServiceDateChange isOpen={isServiceDateChange} onCallback={() => setRenew(Fn.getRenewTime())} onClose={() => setServiceDateChange(false)} mbNo={mbNo}/>
      <MvRegStepEdit isOpen={isRegStepEdit} onCallback={() => setRenew(Fn.getRenewTime())} onClose={() => setRegStepEdit(false)} mbNo={mbNo}/>
      <MvIpkumDateChange isOpen={isIpkumDateChange} onCallback={() => setRenew(Fn.getRenewTime())} onClose={() => setIpkumDateChange(false)} mbNo={mbNo}/>

    </CmnCard>
  );
};

export default MemberViewDetail;
