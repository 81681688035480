export default class Constants {
  static DEV_MODE = true;

  static API_HOST = "https://smms-api.spoany.co.kr:8994"; // REAL
  // static API_HOST = "http://13.124.5.104:8994"; // DEPLOYMENT
  // static API_HOST = "http://localhost:18994"; // LOCAL

  static API_URL = `${Constants.API_HOST}`;
  static APP_NAME = `SMMS`;
  static DISPLAY_NAME = `SMMS`;

  static BUILD = `20241115-005`;

  static BranchOpt1 = "골프";        //고정
  static BranchOpt2 = "골프라커";    //고정
  static BranchOpt3 = "스쿼시";
  static BranchOpt4 = "스피닝";
  static BranchOpt5 = "태닝";
  static BranchOpt6 = "토탈";
  static BranchOpt7 = "필라테스";
  static BranchOpt8 = "Opt1";
  static BranchOpt9 = "Opt2";
  static BranchOpt10 = "Opt3";
};
