import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios from "axios";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import _ from "lodash";

const BranchopSalesTarget = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [ptPrice, setPtPrice] = useState({});
  const [publicPrice, setPublicPrice] = useState({});
  const [servicePrice, setServicePrice] = useState({});
  const [sunPrice, setSunPrice] = useState({});
  const [newMan, setNewMan] = useState({});
  const [oldMan, setOldMan] = useState({});
  const [year, setYear] = useState();
  const [month, setMonth] = useState();

  const [totPtPrice, setTotPtPrice] = useState(0);
  const [totPublicPrice, setTotPublicPrice] = useState(0);
  const [totServicePrice, setTotServicePrice] = useState(0);
  const [totSunPrice, setTotSunPrice] = useState(0);
  const [totNewMan, setTotNewMan] = useState(0);
  const [totOldMan, setTotOldMan] = useState(0);

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('#year').val(Fn.getCurrentDate().split('-')[0]);
    $('#month').val(Fn.getCurrentDate().split('-')[1]);
  }, []);

  useEffect(() => {
    load();
  }, [currPage, srchRenew, renew]);

  const load = () => {
    setYear($('#year').val());
    setMonth($('#month').val());

    axios2
      .post(`/branchop/sales/target/ready?year=${$('#year').val()}&month=${$('#month').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {

          const pt_price = {};
          const public_price = {};
          const service_price = {};
          const sun_price = {};
          const new_man = {};
          const old_man = {};

          let tot_pt_price = 0;
          let tot_public_price = 0;
          let tot_service_price = 0;
          let tot_sun_price = 0;
          let tot_new_man = 0;
          let tot_old_man = 0;

          console.log(result);

          result?.data.forEach(data_row => {
            const {week, pt_price: ptPrice, public_price: publicPrice, service_price: servicePrice, sun_price: sunPrice, new_man: newMan, old_man: oldMan} = data_row;

            pt_price[week] = ptPrice;
            public_price[week] = publicPrice;
            service_price[week] = servicePrice;
            sun_price[week] = sunPrice;
            new_man[week] = newMan;
            old_man[week] = oldMan;

            tot_pt_price += ptPrice;
            tot_public_price += publicPrice;
            tot_service_price += servicePrice;
            tot_sun_price += sunPrice;
            tot_new_man += newMan;
            tot_old_man += oldMan;
          });

          setPtPrice(pt_price);
          setPublicPrice(public_price);
          setServicePrice(service_price);
          setSunPrice(sun_price);
          setNewMan(new_man);
          setOldMan(old_man);

          setTotPtPrice(tot_pt_price);
          setTotPublicPrice(tot_public_price);
          setTotServicePrice(tot_service_price);
          setTotSunPrice(tot_sun_price);
          setTotNewMan(tot_new_man);
          setTotOldMan(tot_old_man);

          setRets(result?.result);
          setPageInfo(pageInfo);

          for (let week = 1; week <= 7; week++) {
            $(`#pt_price_${week}`).val('');
            $(`#public_price_${week}`).val('');
            $(`#service_price_${week}`).val('');
            $(`#sun_price_${week}`).val('');
            $(`#new_man_${week}`).val('');
            $(`#old_man_${week}`).val('');
          }

          result?.data.forEach(data_row => {
            const {week, pt_price: ptPrice, public_price: publicPrice, service_price: servicePrice, sun_price: sunPrice, new_man: newMan, old_man: oldMan} = data_row;

            $(`#pt_price_${week}`).val(Fn.numberWithCommas(ptPrice));
            $(`#public_price_${week}`).val(Fn.numberWithCommas(publicPrice));
            $(`#service_price_${week}`).val(Fn.numberWithCommas(servicePrice));
            $(`#sun_price_${week}`).val(Fn.numberWithCommas(sunPrice));
            $(`#new_man_${week}`).val(Fn.numberWithCommas(newMan));
            $(`#old_man_${week}`).val(Fn.numberWithCommas(oldMan));

            $(`#pt_price_${week}, #public_price_${week}`).unbind('keyup').bind('keyup', function () {
              // 현재 입력값에서 콤마 제거 후 숫자 포맷팅
              const value = removeCommas($(this).val());

              // 숫자가 입력된 경우에만 콤마 추가
              if ($.isNumeric(value)) {
                $(this).val(addCommas(value));
              } else {
                $(this).val("");
              }

              $(`#service_price_${week}`).val(Fn.numberWithCommas(Fn.parseInt($(`#pt_price_${week}`).val().replaceAll(",", "")) + Fn.parseInt($(`#public_price_${week}`).val().replaceAll(",", ""))));
              $('#sum_pt_price').text(Fn.numberWithCommas(_.range(1, result?.data?.length + 1).map(week => Fn.parseInt($(`#pt_price_${week}`).val().replaceAll(",", "")))?.reduce((n, o) => n + o, 0)));
              $('#sum_public_price').text(Fn.numberWithCommas(_.range(1, result?.data?.length + 1).map(week => Fn.parseInt($(`#public_price_${week}`).val().replaceAll(",", "")))?.reduce((n, o) => n + o, 0)));
            });
            $(`#sun_price_${week}, #new_man_${week}, #old_man_${week}`).unbind('keyup').bind('keyup', function () {
              // 현재 입력값에서 콤마 제거 후 숫자 포맷팅
              const value = removeCommas($(this).val());

              // 숫자가 입력된 경우에만 콤마 추가
              if ($.isNumeric(value)) {
                $(this).val(addCommas(value));
              } else {
                $(this).val("");
              }

              $('#sum_sun_price').text(Fn.numberWithCommas(_.range(1, result?.data?.length + 1).map(week => Fn.parseInt($(`#sun_price_${week}`).val().replaceAll(",", "")))?.reduce((n, o) => n + o, 0)));
              $('#sum_new_man').text(Fn.numberWithCommas(_.range(1, result?.data?.length + 1).map(week => Fn.parseInt($(`#new_man_${week}`).val().replaceAll(",", "")))?.reduce((n, o) => n + o, 0)));
              $('#sum_old_man').text(Fn.numberWithCommas(_.range(1, result?.data?.length + 1).map(week => Fn.parseInt($(`#old_man_${week}`).val().replaceAll(",", "")))?.reduce((n, o) => n + o, 0)));
            });
          });
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  // 숫자 포맷팅 함수
  function addCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // 콤마를 제거하여 숫자만 남기는 함수
  function removeCommas(num) {
    return num.replace(/,/g, "");
  }

  // 포커스를 잃을 때 숫자 포맷팅 적용 (보여줄 때도 콤마 표시 유지)
  $('input[type="text"]').on('blur', function () {
    const value = removeCommas($(this).val());
    if ($.isNumeric(value)) {
      $(this).val(addCommas(value));
    }
  });

  const go_submit = () => {
    const formData = new FormData();

    {
      let values = $('[id^=pt_price]').map(function () {
        return Fn.parseInt($(this).val().replace(/,/g, "")); // 쉼표 제거 후 숫자로 변환
      }).get().join(",");
      formData.append("pt_prices[]", values);
    }
    {
      let values = $('[id^=public_price]').map(function () {
        return Fn.parseInt($(this).val().replace(/,/g, "")); // 쉼표 제거 후 숫자로 변환
      }).get().join(",");
      formData.append("public_prices[]", values);
    }
    {
      let values = $('[id^=service_price]').map(function () {
        return Fn.parseInt($(this).val().replace(/,/g, "")); // 쉼표 제거 후 숫자로 변환
      }).get().join(",");
      formData.append("service_prices[]", values);
    }
    {
      let values = $('[id^=sun_price]').map(function () {
        return Fn.parseInt($(this).val().replace(/,/g, "")); // 쉼표 제거 후 숫자로 변환
      }).get().join(",");
      formData.append("sun_prices[]", values);
    }
    {
      let values = $('[id^=new_man]').map(function () {
        return Fn.parseInt($(this).val().replace(/,/g, "")); // 쉼표 제거 후 숫자로 변환
      }).get().join(",");
      formData.append("new_mans[]", values);
    }
    {
      let values = $('[id^=old_man]').map(function () {
        return Fn.parseInt($(this).val().replace(/,/g, "")); // 쉼표 제거 후 숫자로 변환
      }).get().join(",");
      formData.append("old_mans[]", values);
    }

    formData.append("year", $('#year').val());
    formData.append("month", $('#month').val());

    axios2.postFormEx('/branchop/sales/target', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        data = data.trim();
        if (data === 'err') {
          alert('오류가 발생하였습니다');
        } else if (data === 'in_ok') {
          alert('등록되었습니다.');
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" id="month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>
            검색
          </button>
        </div>
      </div>

      <div className="m-3 pb-2">
        <table className="view-rounded sub_table_border">
          <tr>
            <th>주차</th>
            <th>날짜</th>
            <th>PT</th>
            <th>퍼블릭</th>
            <th>매출(PT+퍼블릭)</th>
            <th>순이익</th>
            <th>신규</th>
            <th>재등록</th>
          </tr>
          {rets && rets.map((ret, i) => {
            return (
              <tr>
                <td>{i + 1}</td>
                <td>{`${ret.start_date.substring(2, 10).replace('-', '.')}`} ~ {ret.end_date.substring(2, 10).replace('-', '.')}</td>
                <td><input type="text" className="form-control" placeholder="0" id={`pt_price_${ret?.week}`}/></td>
                <td><input type="text" className="form-control" placeholder="0" id={`public_price_${ret?.week}`}/></td>
                <td><input type="text" className="form-control" placeholder="0" id={`service_price_${ret?.week}`} readOnly={true}/></td>
                <td><input type="text" className="form-control" placeholder="0" id={`sun_price_${ret?.week}`}/></td>
                <td><input type="text" className="form-control" placeholder="0" id={`new_man_${ret?.week}`}/></td>
                <td><input type="text" className="form-control" placeholder="0" id={`old_man_${ret?.week}`}/></td>
              </tr>
            );
          })}
          <tr className="bgcolor bold700">
            <td colSpan="2">{year}년 {month}월 합계</td>
            <td className="text-right" id="sum_pt_price">{Fn.numberWithCommas(totPtPrice)}</td>
            <td className="text-right" id="sum_public_price">{Fn.numberWithCommas(totPublicPrice)}</td>
            <td className="text-right" id="sum_service_price">{Fn.numberWithCommas(totServicePrice)}</td>
            <td className="text-right" id="sum_sun_price">{Fn.numberWithCommas(totSunPrice)}</td>
            <td className="text-right" id="sum_new_man">{Fn.numberWithCommas(totNewMan)}</td>
            <td className="text-right" id="sum_old_man">{Fn.numberWithCommas(totOldMan)}</td>
          </tr>
        </table>
      </div>
      <div className="mt-2 mb-4">
        <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
      </div>

    </CmnCard>
  );
};

export default BranchopSalesTarget;
