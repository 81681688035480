import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {useHistory} from "react-router-dom";
import Fn from "../../libraries/Fn";
import axios2 from "../../helpers/APIHelper";
import JDatePicker from "../common/JDatePicker";

// 스크립트 없음
const AnalysisStateNewOld = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [result, setResult] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [moreInfo, setMoreInfo] = useState('N');

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const calcPercentage = (current, previous) => {
    if (previous) {
      return (((current - previous) / previous) * 100).toFixed(1) + "%";
    }
    return null;
  };

  const load = () => {
    axios2
      .post(`/analysis/state/new/old/ready?s_brand=${$('#s_brand option:selected').val() || ''}&s_jibu=${$('#s_jibu option:selected').val() || ''}&ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val() || ''}&s_date2=${$('#s_date2').val() || ''}&more_info=${$('#more_info option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_brand').html() === '') {
            let optionsHTML = '<option value="">전체 브랜드</option>';
            result?.brand_list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
            });
            $('#s_brand').html(optionsHTML);
          }
          if ($('#s_jibu').html() === '') {
            let optionsHTML = '<option value="">지부선택</option>';
            result?.jibu_rs?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.jibu}">${list_row.jibu}</option>`;
            });
            $('#s_jibu').html(optionsHTML);

            $('#ddate').val('1');
            Fn.period2($('#ddate').val());
          }

          setMoreInfo($('#more_info option:selected').val());

          if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7 && localStorage.getItem("USER.systemFranYN") === "Y") {
            result.list = result?.list?.filter((row) => row.branch_code == localStorage.getItem("USER.systemBranchCode"));
          }

          setResult(result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item" checked onClick={() => history.push("/analysis/state/new/old")}/>
          <label className="tab_item" htmlFor="aa">구분별</label>

          <input id="bb" type="radio" name="tab_item" onClick={() => history.push("/analysis/state/period")}/>
          <label className="tab_item" htmlFor="bb">기간별</label>

          <input id="cc" type="radio" name="tab_item" onClick={() => history.push("/analysis/state/visit")}/>
          <label className="tab_item" htmlFor="cc">경로별</label>

          <input id="dd" type="radio" name="tab_item" onClick={() => history.push("/analysis/state/week")}/>
          <label className="tab_item" htmlFor="dd">주별회원현황</label>

          <input id="ee" type="radio" name="tab_item" onClick={() => history.push("/analysis/state/month")}/>
          <label className="tab_item" htmlFor="ee">월별회원현황</label>
        </div>
      </div>

      <div className="overflowX ">
        <div className="d-flex m-3 table_wide_sh2">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_brand">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_jibu">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="ddate" onChange={(e) => Fn.period2(e.target.value)}>
              <option value="13">어제</option>
              <option value="1">오늘</option>
              <option value="2">이번달</option>
              <option value="3">전월</option>
              <option value="4">1분기</option>
              <option value="5">2분기</option>
              <option value="6">1,2분기</option>
              <option value="7">3분기</option>
              <option value="8">4분기</option>
              <option value="9">3,4분기</option>
              <option value="10">올해전체</option>
              <option value="11">전1년</option>
            </select>
          </div>
          <div className="input-group" style={{width: 'auto'}}>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
          </div>
          <div className="pr-1 mx-1">
            <select className="form-select" aria-label="Default select example" style={{width: '160px'}} id="more_info">
              <option value="N">현재 데이터만</option>
              <option value="Y">비교 데이터 포함</option>
            </select>
          </div>

          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list table_wide_sh2">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border">
            {moreInfo === "Y" &&
              <>
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="95px"/>
                  <col width="95px"/>
                  <col width="95px"/>
                  <col width="95px"/>
                  <col width="95px"/>
                  <col width="95px"/>
                  <col width="95px"/>
                  <col width="95px"/>
                  <col width="95px"/>
                </colgroup>
                <tr>
                  <th>지점명</th>
                  <th>기간구분</th>
                  <th>총매출</th>
                  <th>퍼블릭매출</th>
                  <th>PT매출</th>
                  <th>총인원</th>
                  <th>신규</th>
                  <th>재등록</th>
                  <th>골프</th>
                  <th>스쿼시</th>
                  <th>스피닝</th>
                  <th>태닝</th>
                  <th>필라테스</th>
                  <th>PT(명)</th>
                </tr>
              </>
            }
            {moreInfo !== "Y" &&
              <>
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>지점명</th>
                  <th>총매출</th>
                  <th>퍼블릭매출</th>
                  <th>PT 매출</th>
                  <th>총인원</th>
                  <th>신규</th>
                  <th>재등록</th>
                  <th>골프</th>
                  <th>스쿼시</th>
                  <th>스피닝</th>
                  <th>태닝</th>
                  <th>필라테스</th>
                  <th>PT(명)</th>
                </tr>
              </>
            }
            {moreInfo === "Y" && result?.list?.map((row, index) => {
              const {branch_name, branch_code, suip_price, public_price, pt_price, B_suip_price, B_public_price, B_pt_price, O_suip_price, O_public_price, O_pt_price, value1, value2, value3, value4, value5, value6, value7, value8} = row;

              return (
                <>
                  <tr>
                    <td rowSpan={5} style={{padding: 0}}>{branch_name}</td>
                  </tr>
                  <tr>
                    <td style={{borderLeft: "1px solid #dfdfdf"}}>당월기간</td>
                    <td style={{textAlign: 'right', padding: 0, paddingRight: '10px'}}>
                      {Fn.numberWithCommas(suip_price)}
                    </td>
                    <td style={{textAlign: 'right', padding: 0, paddingRight: '10px'}}>
                      {Fn.numberWithCommas(public_price)}
                    </td>
                    <td style={{textAlign: 'right', padding: 0, paddingRight: '10px'}}>
                      {Fn.numberWithCommas(pt_price)}
                    </td>
                    <td rowSpan={5} style={{padding: 0, borderLeft: "1px solid #dfdfdf"}}>
                      {Fn.numberWithCommas(value1 + value2)}
                    </td>
                    <td rowSpan={5} style={{textAlign: 'center', padding: 0}}>
                      {Fn.numberWithCommas(value1)}
                    </td>
                    <td rowSpan={5} style={{textAlign: 'center', padding: 0}}>
                      {Fn.numberWithCommas(value2)}
                    </td>
                    <td rowSpan={5} style={{textAlign: 'center', padding: 0}}>
                      {Fn.numberWithCommas(value3)}
                    </td>
                    <td rowSpan={5} style={{textAlign: 'center', padding: 0}}>
                      {Fn.numberWithCommas(value4)}
                    </td>
                    <td rowSpan={5} style={{textAlign: 'center', padding: 0}}>
                      {Fn.numberWithCommas(value5)}
                    </td>
                    <td rowSpan={5} style={{textAlign: 'center', padding: 0}}>
                      {Fn.numberWithCommas(value6)}
                    </td>
                    <td rowSpan={5} style={{textAlign: 'center', padding: 0}}>
                      {Fn.numberWithCommas(value7)}
                    </td>
                    <td rowSpan={5} style={{textAlign: 'center', padding: 0}}>
                      {Fn.numberWithCommas(value8)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{borderLeft: "1px solid #dfdfdf"}}>전월 기간</td>
                    <td style={{textAlign: 'right', paddingRight: '10px', borderRight: "1px solid #dfdfdf"}}>{Fn.numberWithCommas(B_suip_price)}</td>
                    <td style={{textAlign: 'right', paddingRight: '10px', borderRight: "1px solid #dfdfdf"}}>{Fn.numberWithCommas(B_public_price)}</td>
                    <td style={{textAlign: 'right', paddingRight: '10px', borderRight: "1px solid #dfdfdf"}}>{Fn.numberWithCommas(B_pt_price)}</td>
                  </tr>
                  <tr>
                    <td style={{borderLeft: "1px solid #dfdfdf"}}>증감율(%)</td>
                    <td style={{textAlign: 'right', paddingRight: '10px', borderRight: "1px solid #dfdfdf"}}><strong>{B_suip_price > 0 && calcPercentage(suip_price, B_suip_price)}</strong></td>
                    <td style={{textAlign: 'right', paddingRight: '10px', borderRight: "1px solid #dfdfdf"}}><strong>{B_public_price > 0 && calcPercentage(public_price, B_public_price)}</strong></td>
                    <td style={{textAlign: 'right', paddingRight: '10px', borderRight: "1px solid #dfdfdf"}}><strong>{B_pt_price > 0 && calcPercentage(pt_price, B_pt_price)}</strong></td>
                  </tr>
                  <tr>
                    <td style={{borderLeft: "1px solid #dfdfdf"}}>작년 동월</td>
                    <td style={{textAlign: 'right', paddingRight: '10px', borderRight: "1px solid #dfdfdf"}}>{Fn.numberWithCommas(O_suip_price)}</td>
                    <td style={{textAlign: 'right', paddingRight: '10px', borderRight: "1px solid #dfdfdf"}}>{Fn.numberWithCommas(O_public_price)}</td>
                    <td style={{textAlign: 'right', paddingRight: '10px', borderRight: "1px solid #dfdfdf"}}>{Fn.numberWithCommas(O_pt_price)}</td>
                  </tr>
                </>
              );
            })}
            {moreInfo != "Y" && result?.list?.map((row, index) => {
              const {
                branch_name,
                value11,
                value10,
                value9,
                value1,
                value2,
                value3,
                value4,
                value5,
                value6,
                value7,
                value8,
                branch_code,
              } = row;

              return (
                <tr>
                  <td>{branch_name}</td>
                  <td>
                    <div style={{textAlign: 'right', paddingRight: '10px'}}>
                      {Fn.numberWithCommas(value11)}
                    </div>
                  </td>
                  <td>
                    <div style={{textAlign: 'right', paddingRight: '10px'}}>
                      {Fn.numberWithCommas(value10)}
                    </div>
                  </td>
                  <td>
                    <div style={{textAlign: 'right', paddingRight: '10px'}}>
                      {Fn.numberWithCommas(value9)}
                    </div>
                  </td>
                  <td>
                    {Fn.numberWithCommas(value1 + value2)}
                  </td>
                  <td>{Fn.numberWithCommas(value1)}</td>
                  <td>{Fn.numberWithCommas(value2)}</td>
                  <td>{Fn.numberWithCommas(value3)}</td>
                  <td>{Fn.numberWithCommas(value4)}</td>
                  <td>{Fn.numberWithCommas(value5)}</td>
                  <td>{Fn.numberWithCommas(value6)}</td>
                  <td>{Fn.numberWithCommas(value7)}</td>
                  <td>{Fn.numberWithCommas(value8)}</td>
                </tr>
              );
            })}

            {moreInfo == "Y" &&
              <>
                <tr>
                  <td rowSpan={5} style={{background: '#ffffdd'}}><strong>합 계</strong></td>
                </tr>
                <tr style={{background: '#ffffdd'}}>
                  <td style={{borderLeft: "1px solid #dfdfdf"}}><strong>당월 기간</strong></td>
                  <td style={{textAlign: 'right', paddingRight: '10px'}}><strong>{Fn.numberWithCommas(result?.tot_suip_price)}</strong></td>
                  <td style={{textAlign: 'right', paddingRight: '10px'}}><strong>{Fn.numberWithCommas(result?.tot_pu_price)}</strong></td>
                  <td style={{textAlign: 'right', paddingRight: '10px'}}><strong>{Fn.numberWithCommas(result?.tot_pt_price)}</strong></td>
                  <td rowSpan={5} style={{background: '#ffffdd', borderLeft: "1px solid #dfdfdf"}} className="text-center"><strong>{Fn.numberWithCommas(result?.t1 + result?.t2)}</strong></td>
                  <td rowSpan={5} style={{background: '#ffffdd'}} className="text-center"><strong>{Fn.numberWithCommas(result?.t1)}</strong></td>
                  <td rowSpan={5} style={{background: '#ffffdd'}} className="text-center"><strong>{Fn.numberWithCommas(result?.t2)}</strong></td>
                  <td rowSpan={5} style={{background: '#ffffdd'}} className="text-center"><strong>{Fn.numberWithCommas(result?.t3)}</strong></td>
                  <td rowSpan={5} style={{background: '#ffffdd'}} className="text-center"><strong>{Fn.numberWithCommas(result?.t4)}</strong></td>
                  <td rowSpan={5} style={{background: '#ffffdd'}} className="text-center"><strong>{Fn.numberWithCommas(result?.t5)}</strong></td>
                  <td rowSpan={5} style={{background: '#ffffdd'}} className="text-center"><strong>{Fn.numberWithCommas(result?.t6)}</strong></td>
                  <td rowSpan={5} style={{background: '#ffffdd'}} className="text-center"><strong>{Fn.numberWithCommas(result?.t7)}</strong></td>
                  <td rowSpan={5} style={{background: '#ffffdd'}} className="text-center"><strong>{Fn.numberWithCommas(result?.t8)}</strong></td>
                </tr>
                <tr style={{background: '#ffffdd'}}>
                  <td style={{borderLeft: "1px solid #dfdfdf"}}><strong>전월 기간</strong></td>
                  <td style={{textAlign: 'right', paddingRight: '10px'}}><strong>{Fn.numberWithCommas(result?.B_tot_suip_price)}</strong></td>
                  <td style={{textAlign: 'right', paddingRight: '10px'}}><strong>{Fn.numberWithCommas(result?.B_tot_pu_price)}</strong></td>
                  <td style={{textAlign: 'right', paddingRight: '10px'}}><strong>{Fn.numberWithCommas(result?.B_tot_pt_price)}</strong></td>
                </tr>
                <tr style={{background: '#ffffdd'}}>
                  <td style={{borderLeft: "1px solid #dfdfdf"}}><strong>증감율(%)</strong></td>
                  <td style={{textAlign: 'right', paddingRight: '10px'}}><strong>{calcPercentage(result?.tot_suip_price, result?.B_tot_suip_price)}</strong></td>
                  <td style={{textAlign: 'right', paddingRight: '10px'}}><strong>{calcPercentage(result?.tot_pu_price, result?.B_tot_pu_price)}</strong></td>
                  <td style={{textAlign: 'right', paddingRight: '10px'}}><strong>{calcPercentage(result?.tot_pt_price, result?.B_tot_pt_price)}</strong></td>
                </tr>
                <tr style={{background: '#ffffdd'}}>
                  <td style={{borderLeft: "1px solid #dfdfdf"}}><strong>작년 동월</strong></td>
                  <td style={{textAlign: 'right', paddingRight: '10px'}}><strong>{Fn.numberWithCommas(result?.O_tot_suip_price)}</strong></td>
                  <td style={{textAlign: 'right', paddingRight: '10px'}}><strong>{Fn.numberWithCommas(result?.O_tot_pu_price)}</strong></td>
                  <td style={{textAlign: 'right', paddingRight: '10px'}}><strong>{Fn.numberWithCommas(result?.O_tot_pt_price)}</strong></td>
                </tr>
              </>
            }

            {moreInfo !== "Y" &&
              <>
                {!(Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7 && localStorage.getItem("USER.systemFranYN") === "Y") &&
                  <>
                    <tr>
                      <td style={{background: '#ffffdd'}}>
                        <strong>평 균</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <div style={{textAlign: 'right', paddingRight: '10px'}}>
                          <strong>{Fn.numberWithCommas(Math.round(result?.t11 / result?.list?.filter(r => r?.value11 !== undefined)?.length))}</strong>
                        </div>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <div style={{textAlign: 'right', paddingRight: '10px'}}>
                          <strong>{Fn.numberWithCommas(Math.round(result?.t10 / result?.list?.filter(r => r?.value10 !== undefined)?.length))}</strong>
                        </div>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <div style={{textAlign: 'right', paddingRight: '10px'}}>
                          <strong>{Fn.numberWithCommas(Math.round(result?.t9 / result?.list?.filter(r => r?.value9 !== undefined)?.length))}</strong>
                        </div>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(Math.round((result?.t1 + result?.t2) / result?.list?.filter(r => r?.value1 !== undefined)?.length))}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(Math.round(result?.t1 / result?.list?.filter(r => r?.value1 !== undefined)?.length))}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(Math.round(result?.t2 / result?.list?.filter(r => r?.value2 !== undefined)?.length))}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(Math.round(result?.t3 / result?.list?.filter(r => r?.value3 !== undefined)?.length))}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(Math.round(result?.t4 / result?.list?.filter(r => r?.value4 !== undefined)?.length))}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(Math.round(result?.t5 / result?.list?.filter(r => r?.value5 !== undefined)?.length))}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(Math.round(result?.t6 / result?.list?.filter(r => r?.value6 !== undefined)?.length))}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(Math.round(result?.t7 / result?.list?.filter(r => r?.value7 !== undefined)?.length))}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(Math.round(result?.t8 / result?.list?.filter(r => r?.value8 !== undefined)?.length))}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td style={{background: '#ffffdd'}}>
                        <strong>합 계</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <div style={{textAlign: 'right', paddingRight: '10px'}}>
                          <strong>{Fn.numberWithCommas(result?.t11)}</strong>
                        </div>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <div style={{textAlign: 'right', paddingRight: '10px'}}>
                          <strong>{Fn.numberWithCommas(result?.t10)}</strong>
                        </div>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <div style={{textAlign: 'right', paddingRight: '10px'}}>
                          <strong>{Fn.numberWithCommas(result?.t9)}</strong>
                        </div>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(result?.t1 + result?.t2)}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(result?.t1)}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(result?.t2)}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(result?.t3)}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(result?.t4)}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(result?.t5)}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(result?.t6)}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(result?.t7)}</strong>
                      </td>
                      <td style={{background: '#ffffdd'}}>
                        <strong>{Fn.numberWithCommas(result?.t8)}</strong>
                      </td>
                    </tr>
                  </>
                }
              </>
            }
          </table>
        </div>
      </div>
    </CmnCard>
  );
};

export default AnalysisStateNewOld;
